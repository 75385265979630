import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'dateAgo'
})
export class DateAgoPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService
  ) {}

  transform(timeRaw: number, currentTime = 0): string {
    let time: number
    if (currentTime === 0) {
      time = Math.floor(Date.now() / 1000) - timeRaw
    } else {
      time = currentTime - timeRaw
    }
    let timeString = ''

    if (timeRaw > 0) {
      const d = Math.floor(time / 86400)
      const h = Math.floor((time % 86400) / 3600)
      const m = Math.floor(((time % 86400) % 3600) / 60)
      const s = ((time % 86400) % 3600) % 60

      this.translateService.get(['time.d', 'time.h', 'time.m', 'time.s']).subscribe(translation => {

          if (d > 0) {
            timeString = `${d}${translation['time.d']} ${h}${translation['time.h']} ${m}${translation['time.m']}`
          } else {
            if (h > 0) {
              timeString = `${h}${translation['time.h']} ${m}${translation['time.m']} ${s}${translation['time.s']}`
            } else {
              if (m > 0) {
                timeString = `${m}${translation['time.m']} ${s}${translation['time.s']}`
              } else {
                timeString = `${s}${translation['time.s']}`
              }
            }
          }
        }
      )
    } else {
      timeString = '--'
    }
    return timeString
  }
}
