<form [formGroup]="form" (ngSubmit)="submit()" autocomplete="off">
  <div class="form-control">
    <label for="login">{{ 'signUp.login' | translate }}</label>
    <app-input-validation [component]="form.get('login')"></app-input-validation>
    <input id="login" formControlName="login" type="text" autocomplete="new-password">
  </div>
  <div class="form-control">
    <label for="password">{{ 'signUp.password' | translate }}</label>
    <app-input-validation [component]="form.get('password')"></app-input-validation>
    <input id="password" formControlName="password" type="password" autocomplete="new-password">
  </div>
  <div class="form-control">
    <label for="passwordConfirmation">{{ 'signUp.passwordConfirmation' | translate }}</label>
    <app-input-validation [component]="form.get('passwordConfirmation')"></app-input-validation>
    <input id="passwordConfirmation" formControlName="passwordConfirmation" type="password" autocomplete="new-password">
  </div>
  <div class="form-control">
    <label for="mail">{{ 'signUp.mail' | translate }}</label>
    <app-input-validation [component]="form.get('mail')"></app-input-validation>
    <input id="mail" formControlName="mail" type="email">
  </div>
  <div class="form-control">
    <label for="name">{{ 'signUp.name' | translate }}</label>
    <app-input-validation [component]="form.get('name')"></app-input-validation>
    <input id="name" formControlName="name" type="text">
  </div>
  <div class="form-control">
    <label for="telegram">{{ 'signUp.telegram' | translate }}</label>
    <app-input-validation [component]="form.get('telegram')"></app-input-validation>
    <input id="telegram" formControlName="telegram" type="text">
  </div>
  <div class="flex-container pl pr">
    <button class="btn btn-default" mat-dialog-close>{{ 'cancel' | translate }}</button>
    <button [disabled]="!form.valid" type="submit" class="btn btn-success pinned-right mr-0">{{'signUp.signUp' | translate}}</button>
  </div>
</form>
