import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ThemeOption} from '../../interfaces';
import {SharedModule} from '../../shared.module';

@Component({
  selector: 'app-theme-menu',
  templateUrl: './theme-menu.component.html'
})
export class ThemeMenuComponent {
  @Input() options: Array<ThemeOption>;
  @Output() themeChange: EventEmitter<string> = new EventEmitter<string>()
  constructor(
    public sharedModule: SharedModule
  ) {}

  changeTheme(themeToSet): void {
    this.themeChange.emit(themeToSet);
  }
}
