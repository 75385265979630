<div class="container-wide mt-0">
  <div class="flex-container align-start no-wrap">
    <div style="margin-left: -0.1rem">
      <div *ngFor="let hiddenFarm of this.hiddenFarms" class="note note-gray">
        <fa-icon [icon]="this.sharedModule.faEye" (click)="showFarm(hiddenFarm.id)"
          title="{{ 'monitor.showFarm' | translate }}" class="active-element"
        ></fa-icon>
        <strong class="ml-02">{{ this.hiddenFarm.name}}</strong>
      </div>
    </div>
  </div>

  <div *ngIf="!loading; else appLoading">
    <div *ngFor="let group of groups; let index = index" class="card ml-0 mr-0">
      <ng-template [ngIf]="group.rigs.length > 0">
        <ng-container *ngIf="getCheckForm(group.id) as checkForm">
          <div class="card-label">
            <div class="group-label flex-container align-center">
              <div>
                <input type="checkbox" class="custom-checkbox" id="checkAll_{{group.id}}" value="1"
                  [checked]="checkForm.checkAll === true" (change)="onCheckAll(group.id, group.name)">
                <label for="checkAll_{{group.id}}"></label>
              </div>
              <div>
                <fa-icon [icon]="this.sharedModule.faEyeSlash" (click)="hideGroup(group.id)"
                  title="{{ 'monitor.hideFarm' | translate }}" class="active-element mr-02"
                ></fa-icon>
                <strong>{{group.name}}</strong>
              </div>
            </div>
            <div class="stats-container">

              <div class="stats">
                <fa-icon [icon]="this.sharedModule.faCubes" class="info-element"
                  title="{{ 'monitor.rigs' | translate }}"></fa-icon>
                {{group.onlineRigs}}
                <ng-container *ngIf="group.offlineRigs">
                  /&nbsp;<span class="offline-color">{{group.offlineRigs}}</span>
                </ng-container>
              </div>

              <div class="stats ml-02">
                <fa-icon [icon]="this.sharedModule.faCube" class="info-element"
                  title="{{ 'monitor.gpus' | translate }}"></fa-icon>
                {{group.onlineGpu}}
                <ng-container *ngIf="group.offlineGpu">
                  /&nbsp;<span class="offline-color">{{group.offlineGpu}}</span>
                </ng-container>
              </div>
              <div class="stats ml-02" title="{{ 'monitor.consumption' | translate }}">
                <fa-icon [icon]="this.sharedModule.faBolt" class="info-element"></fa-icon>
                {{group.totalPower}} {{ 'watt' | translate }}
              </div>

            </div>
            <div class="hr-container">
              <div class="note note-default hr-sum active-element" *ngFor="let hrData of group.totalHr"
                [title]="hrData.coin? hrData.coin + ' (' + hrData.algo + ')' : hrData.algo"
              >
                <ng-container *ngIf="hrData.coin && sharedModule.coinsLogo[hrData.coin]">
                  <div>
                    <img [src]="'assets/img/coins/' + sharedModule.coinsLogo[hrData.coin]"
                         [alt]="hrData.coin" class="coin-logo mr-02">
                  </div>
                  <div class="hr-value">
                    {{hrData.hr | displayHr:true: true}}
                  </div>
                </ng-container>
                <ng-container *ngIf="!hrData.coin || !sharedModule.coinsLogo[hrData.coin]">
                  <div>{{hrData.algo}}</div>
                  <div class="hr-value">{{hrData.hr | displayHr:true:true}}</div>
                </ng-container>
              </div>
                  <!--              <ng-container *ngFor="let hrData of group.totalHr">-->
<!--                  <ng-container *ngIf="hrData.coin && sharedModule.coinsLogo[hrData.coin]">-->
<!--                    <div class="note note-default hr-sum active-element" title="{{hrData.coin}} ({{hrData.algo}})">-->
<!--                      <img [src]="'assets/img/coins/' + sharedModule.coinsLogo[hrData.coin]"-->
<!--                           [alt]="hrData.coin" class="coin-logo mr-02">{{hrData.hr | displayHr:true: true}}-->
<!--                    </div>-->
<!--                  </ng-container>-->
<!--                  <ng-container *ngIf="!hrData.coin || !sharedModule.coinsLogo[hrData.coin]">-->
<!--                    <div class="note note-default hr-sum active-element" [title]="hrData.algo">-->
<!--&lt;!&ndash;                      {{hrData.algo}} {{hrData.hr | displayHr:true: true}}&ndash;&gt;-->
<!--                      <div>{{hrData.algo}}</div>-->
<!--                      <div class="hr-value">{{hrData.hr | displayHr:true:true}}</div>-->
<!--                    </div>-->
<!--                  </ng-container>-->

<!--              </ng-container>-->
            </div>
          </div>
          <div class="card-content">
          <form [formGroup]="checkForm.form">
            <ng-container *ngFor="let rig of group.rigs">
              <div class="flex-container align-center no-wrap">
                <div>
                  <input type="checkbox" class="custom-checkbox" [formControlName]="rig.id" [id]="rig.id" value="1"
                         (change)="onChangeCheckBox(group.id, group.name)">
                  <label [for]="rig.id"></label>
                </div>
<!--                {{rig.msg | json}}-->
                <app-display-rig-short *ngIf="viewMode === 'short'" class="flex-grow-1" [rig]="rig" [osDataMiners]="osDataMiners" [tempLimit]="tempLimit" [currentTime]="currentTime"></app-display-rig-short>

                <app-display-rig-full *ngIf="viewMode === 'full'" class="flex-grow-1" [rig]="rig" [osDataMiners]="osDataMiners" [tempLimit]="tempLimit" [currentTime]="currentTime"></app-display-rig-full>
              </div>

              <hr *ngIf="group.rigs.indexOf(rig) !== group.rigs.length -1">
            </ng-container>
          </form>
        </div>
        </ng-container>
      </ng-template>
    </div>
  </div>
  <ng-template #appLoading>
    <app-loading></app-loading>
  </ng-template>
</div>
