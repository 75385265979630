<div id="main">
  <form [formGroup]="form" (ngSubmit)="submit()">

    <div class="flex-container w-100">
      <label>{{ 'actions.bios.keys.mode' | translate }}</label>
      <div class="form-control w-100 flex-container vertical">
        <div>
          <input formControlName="mode" type="radio" value="multi">{{ 'actions.bios.keys.multi' | translate }}
        </div>
        <div>
          <input formControlName="mode" type="radio" value="single">{{ 'actions.bios.keys.single' | translate }}
        </div>
      </div>
    </div>
    <div *ngIf="brand === 'N'" class="flex-container w-100">
      <mat-slide-toggle formControlName="ignoreMismatch">{{ 'actions.bios.keys.ignoreMismatch' | translate }}</mat-slide-toggle>
    </div>
    <div *ngIf="brand === 'A'" class="flex-container w-100">
      <mat-slide-toggle formControlName="disableServices">{{ 'actions.bios.keys.disableServices' | translate }}</mat-slide-toggle>
    </div>

    <div *ngIf="form.get('mode').value ==='single'" class="flex-container w-50">
      <label for="gpu">{{ 'actions.bios.keys.gpuSelect' | translate }}</label>
      <div class="form-control w-100">
        <select formControlName="gpu" id="gpu">
          <option *ngFor="let gpu of gpuArray" [ngValue]="gpu.bus">{{ gpu.bus + ':00.0 ' + gpu.vendor + ' ' + gpu.name + ' ' }}</option>
        </select>
      </div>
    </div>
    <div class="flex-container w-100">
      <label class="mr-02">{{ 'actions.bios.keys.file' | translate }}</label>
      <app-input-validation [component]="form.get('file')"></app-input-validation>
      <div class="form-control w-100">
        <input type="file" style="display: none" (change)="onFileSelected($event)" #fileUpload>
        <div class="file-upload">
          <button type="button" class="btn btn-default" (click)="fileUpload.click()">
            <fa-icon [icon]="sharedModule.faUpload" class="active-element"></fa-icon>
          </button>
          {{fileName || 'fileNotSelected' | translate }}
        </div>
      </div>
    </div>

    <div class="flex-container form-buttons">
      <button type="submit"
        [disabled]="submitted || !form.valid || (form.get('mode').value === 'single' && form.get('gpu').pristine)"
        class="btn btn-success pinned-right mr-0">{{'actions.bios.keys.flash' | translate}}</button>
    </div>
  </form>
</div>
