import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OsDataAlgo, WdAlgo} from '../../interfaces';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SharedModule} from '../../shared.module';
import {OsService} from '../../services/os.service';

@Component({
  selector: 'app-edit-wd-algo',
  templateUrl: './edit-wd-algo-component.html',
  styleUrls: ['./edit-wd-algo-component.scss']
})
export class EditWdAlgoComponent implements OnInit {
  @Input() data: WdAlgo
  @Output() formSubmit = new EventEmitter()
  section = 'wdAlgo'
  gAlgos: OsDataAlgo[]
  algos: OsDataAlgo[]
  form: FormGroup
  simpleControls = [
    'reboot'
  ]
  boolControls = [
    'enabled',
    'wakealarm',
    'noRebootIfNoNet'
  ]
  loading = true
  order = [
    'hs',
    'khs',
    'mhs',
    'ghs'
  ]
  constructor(
    private fb: FormBuilder,
    private osService: OsService,
    public sharedModule: SharedModule
  ) {}

  get hrArr(): FormArray {
    return this.form.get('hr') as FormArray
  }

  ngOnInit(): void {
    const allowedFields = ['enabled', 'wakealarm', 'noRebootIfNoNet', 'restart', 'reboot', 'noNetRebootTime']
    this.form = this.fb.group({
      enabled: [false],
      wakealarm: [false],
      noRebootIfNoNet: [false],
      // restart: [0, [
      //   Validators.min(0),
      //   Validators.max(86400)
      // ]],
      reboot: [300, [
        Validators.min(0),
        Validators.max(86400)
      ]],
      noNetRebootTime: [300, [
        Validators.min(0),
        Validators.max(86400)
      ]],
      hr: this.fb.array([]),
      algoSelect: []
    })
    if (this.data) {
      let locData = {}
      allowedFields.forEach(key => {
        if (this.data[key] !== undefined) {
          locData[key] = this.data[key]
        }
      })
      if (Object.keys(locData).length !== 0) {
        this.form.patchValue(locData)
      }
    }

    let sub = this.osService.getAlgos().subscribe((data) => {
      this.gAlgos = data
      this.algos = Object.assign([], data)
      this.form.get('algoSelect').setValue(data[0].algo)
      if (this.data.hr) {
        this.data.hr.forEach((hrRow) => {
          this.addAlgo(hrRow.algo, hrRow.hr)
        })
      }
      this.loading = false
      sub.unsubscribe()
    })
  }

  addAlgo(algo: string, hr: string = '') {
    let index = this.algos.findIndex((e) => e.algo === algo)
    if (index !== -1) {
      this.hrArr.push(
        this.fb.group({
          algo: [algo],
          hr: [hr],
          x: [this.algos[index].x]
        })
      )
      this.algos.splice(index, 1)

      this.form.get('algoSelect').patchValue(this.algos[0].algo)
    }
  }

  removeAlgo(algo: string) {
    let index = this.hrArr.value.findIndex((e) => e.algo === algo)
    if (index !== -1) {
      this.hrArr.removeAt(index)

      let el = this.gAlgos.find((e) => e.algo === algo)
      this.algos.push(el)
    }
  }

  submit() {
    let formValue = this.form.value
    delete formValue.algoSelect
    if (formValue.noRebootIfNoNet === false) {
      delete formValue.noNetRebootTime
    } else if (! formValue.noNetRebootTime) {
      formValue.noNetRebootTime = 300
    }

    formValue.hr = formValue.hr.filter((el) => {
      el.hr = +el.hr
      if (el.hr) {
        return el
      }
    })
    let data = {
      wd: formValue
    }
    // console.log('submit', data)
    this.formSubmit.emit(data)
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  findDescription(algo: string) {
    let index = this.gAlgos.findIndex((e) => e.algo === algo)
    if (index !== -1) {
      return this.gAlgos[index].description
    }
  }
  getUnit(algo: string) {
    let index = this.gAlgos.findIndex((e) => e.algo === algo)
    if (index !== -1) {
      const x = this.gAlgos[index].x
      let i = 0
      let unit = this.order[0]
      this.order.forEach(elem => {
        if (x / 1000 ** i >= 1) {
          unit = elem
        }
        i++
      })
      return unit
    }
  }
}
