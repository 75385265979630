import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {OsDataMiner, Tuning} from '../../interfaces';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {SharedModule} from '../../shared.module';
import {OsService} from '../../services/os.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-edit-tuning',
  templateUrl: './edit-tuning.component.html'
})
export class EditTuningComponent implements OnInit, OnDestroy {
  @Input() data: Tuning[]
  @Output() formSubmit = new EventEmitter()
  miners: string[] = []
  actualMiners: string[] = []
  form: FormGroup
  section = 'tuning'
  osDataMiners: OsDataMiner[] = []
  loading = true
  sub: Subscription

  constructor(
    private fb: FormBuilder,
    private osService: OsService,
    public sharedModule: SharedModule
  ) { }

  get tuning(): FormArray {
    return this.form.get('tuning') as FormArray
  }
  async ngOnInit() {
    // this.osDataMiners = await this.osService.getMiners().toPromise()
    if (!this.data || ! Array.isArray(this.data)) {
      this.data = []
    }
    // console.log ('Tuning data', this.data)
    this.sub = this.osService.getMiners().subscribe((data) => {
      this.miners = Object.values(data).map((el) => {
        return el.name
      })
      this.actualMiners = this.miners
      this.osDataMiners = data
      this.form = this.fb.group({
        minerSelect: [this.miners[0]],
        tuning: this.fb.array([])
      })

      if (Array.isArray(this.data)) {
        this.data.forEach((el) => {
          this.addMiner(el.miner, el.addition, el.rewrite, el.fork)
        })
      }
      this.loading = false
    })
  }

  findForks(miner: string): string[] {
    let forks = []
    let index = this.osDataMiners.findIndex((el) => el.name === miner)
    if (index !== -1) {
      forks = this.osDataMiners[index].forks
    }
    return forks
  }

  addMiner(miner: string, addition = '', rewrite = false, fork = '') {
    let index = this.actualMiners.findIndex((e) => e === miner)
    if (index !== -1) {
      this.actualMiners.splice(index, 1)
      this.form.get('minerSelect').patchValue(this.actualMiners[0])

      this.tuning.push(this.fb.group({
        miner,
        addition,
        rewrite,
        fork
      }))
    }
  }

  removeMiner(miner: string) {
    let index = this.tuning.value.findIndex((el) => el.miner === miner)
    if (index !== -1) {
      this.tuning.removeAt(index)
      this.actualMiners.push(miner)
    }
  }

  submit() {
    let tuning = this.form.get('tuning').value
    let changed = []
    tuning.forEach((minerData) => {
      let miner = minerData.miner
      let index = this.data.findIndex(el => el.miner === miner)
      if (index === -1) {
        changed.push(miner)
      } else if (! this.sharedModule.compareObjects(this.data[index], minerData)) {
        changed.push(miner)
      }
    })
    // if miner is deleted
    this.data.forEach((initialMinerData) => {
      let miner = initialMinerData.miner
      let index = tuning.findIndex(el => el.miner === miner)
      if (index === -1) {
        changed.push(miner)
      }
    })
    let data = {
      tuning,
      changed
    }
    // console.log (data)
    this.formSubmit.emit(data)
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  getMinerDescription(minerName: string): string {
    const index = this.osDataMiners.findIndex(el => el.name === minerName)
    return index !== -1 ? this.osDataMiners[index].description : minerName
  }
}
