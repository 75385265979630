import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core'
import {OcNvidia} from '../../interfaces'
import {FormBuilder, FormGroup} from '@angular/forms'
import {SharedModule} from '../../shared.module'
import {OcService} from '../../services/oc.service'
import {map} from 'rxjs/operators'

@Component({
  selector: 'app-edit-oc-nvidia',
  templateUrl: './edit-oc-nvidia.component.html'
})
export class EditOcNvidiaComponent implements OnInit, OnChanges {
  @Input() oc: OcNvidia
  @Input() owner: number
  @Output() formSubmit = new EventEmitter()
  brand = 'nvidia'
  form: FormGroup
  simpleControls = [
    'core_clock',
    'lock_clock',
    'memory_clock',
    'lock_memory',
    'power_limit',
    'fan'
  ]
  boolControls = [
    'light',
    'pill'
  ]

  constructor(
    public sharedModule: SharedModule,
    private fb: FormBuilder,
    private ocService: OcService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: [''],
      name: [''],
      favorite: [''],
      core_clock: [''],
      lock_clock: [''],
      memory_clock: [''],
      lock_memory: [''],
      power_limit: [''],
      fan: [''],
      light: [''],
      pill: [false],
      pill_args: [''],
      saveAsNew: [false]
    })

    if (this.oc) {
      // console.log ('this.oc', this.oc)
      this.form.patchValue(this.oc)
    }

    if (!this.oc.id) {
      this.form.get('saveAsNew').disable()
      this.form.get('saveAsNew').patchValue(true)
    }
  }

  toggleFavorite() {
    const currentValue = this.form.controls.favorite.value
    this.form.controls.favorite.setValue(!currentValue)
  }

  prepareFormData(): OcNvidia {
    let formData = this.form.value
    return {
      id: +formData.id,
      name: formData.name,
      favorite: formData.favorite,
      core_clock: formData.core_clock,
      lock_clock: formData.lock_clock,
      memory_clock: formData.memory_clock,
      lock_memory: formData.lock_memory,
      power_limit: formData.power_limit,
      fan: formData.fan,
      light: formData.light,
      pill: formData.pill,
      pill_args: formData.pill_args
    }
  }
  ngOnChanges() {
    if (this.form) {
      this.form.patchValue(this.oc)
    }
  }

  submit(command= 'save') {
    let data = this.prepareFormData()
    let dataToSend = {
      command,
      brand: this.brand,
      data
    }
    if (! this.sharedModule.compareObjects(data, this.oc)) { // data !==  initial data
      if (this.form.value.saveAsNew === false) {
        this.ocService.patch(this.owner, this.brand, this.form.value.id, data)
          .pipe(
            map((result) => {
              if (result === 'OK') {
                this.formSubmit.emit(dataToSend)
              } else {
                this.sharedModule.pushAlert('Error while changing OC profile')
              }
            })
          ).toPromise().catch((error) => {
          this.sharedModule.pushAlert(error.error.message, 'warning')
        })

      } else {
        delete(data.id)
        this.ocService.create(this.owner, this.brand, data)
          .pipe(
            map((result) => {
              if (Number.isInteger(result.id)) {
                data.id = result.id
                this.formSubmit.emit(dataToSend)
              } else {
                this.sharedModule.pushAlert('Error while creating OC profile', 'warning')
              }
            })
          ).toPromise().catch((error) => {
          this.sharedModule.pushAlert(error.error.message, 'warning')
        })
      }
    } else {
      this.formSubmit.emit(dataToSend)
    }
  }
}
