import {Component, OnInit} from '@angular/core'
import {Observable} from 'rxjs'
import {first, map, switchMap} from 'rxjs/operators'

import {RigComponent} from '../../rig.component'
import {Rig} from '../../../shared/interfaces'
import {SharedModule} from '../../../shared/shared.module'

@Component({
  selector: 'app-rig-settings',
  templateUrl: './rig-settings.component.html'
})
export class RigSettingsComponent implements OnInit {
  rig$: Observable<Rig>

  constructor(
    private rigComponent: RigComponent,
    private sharedModule: SharedModule
  ) { }

  ngOnInit() {
    this.rig$ = this.rigComponent.route.params
      .pipe(
        switchMap ((params) => {
          return this.rigComponent.rigsService.get(params.id, 'settings')
        })
      )
  }

  formSubmit(data: any) {
    if (Object.keys(data).length) {
      const commandData = {
        command: 'exec',
        args: 'hello'
      }
      this.rigComponent.route.params
        .pipe(
          first(),
          switchMap((params) => {
            // console.log ('patch', +params.id, data)
            return this.rigComponent.rigsService.patch(+params.id, data)
              .pipe(
                map(() => {
                  return this.rigComponent.rigsService.command(+params.id, commandData)
                })
              )
          })
        ).toPromise().catch((error) => {
          this.sharedModule.pushAlert(error.error.message, 'warning')
      })
    }
  }

}
