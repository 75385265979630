<div class="container">
  <div *ngIf="!loading; else loadingDiv" class="flex-container nav-panel">
    <a class="menu-link tab" *ngFor="let farm of farms"
      [routerLink]="['/', 'profiles', farm.id]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
      (click)="fetchManifests(farm.id)">
      {{ farm.name }}
    </a>
  </div>
  <div *ngIf="!manifestsLoading; else loadingDiv" class="pr-02 pl-02">
    <ng-container *ngIf="manifests.length > 0; else noProfiles">
      <div class="flex-container align-center mt-02 mb-01">
        <div>
          <input type="checkbox" class="custom-checkbox" id="checkAll" value="1" [checked]="checkAll === true" (change)="onCheckAll()">
          <label for="checkAll"></label>
        </div>
        <div class="flex-grow-1 flex-container align-center" id="input-profile">
          <div class="flex-grow-1">
            <input  type="text" placeholder="{{'manifests.findProfile' | translate}}"
              (change)="nullifyCheckboxes()" [(ngModel)]="searchStr">
          </div>
          <div *ngIf="selectedCheckBoxes.length > 0" class="pinned-right pt-02">
            <button class="btn btn-default mr-0 ml-02"
              title="{{'manifests.copySelectedProfiles' | translate}}"
              (click)="openCopyWindow()"
            >
              <fa-icon [icon]="this.sharedModule.faCopy"></fa-icon>
            </button>

            <button class="btn btn-default mr-0 ml-02"
              title="{{'manifests.deleteSelectedProfiles' | translate}}"
              (click)="deleteProfiles()"
            >
              <fa-icon [icon]="this.sharedModule.faTrash"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <form [formGroup]="form">
        <div *ngFor="let manifest of manifests | filterProfiles:searchStr" class="flex-container align-center">
          <div>
            <input type="checkbox" class="custom-checkbox" [formControlName]="manifest.id" [id]="manifest.id" value="1"
              (change)="onChangeCheckBox()">
            <label [for]="manifest.id"></label>
          </div>
<!--          {{manifest | json}}-->
          <app-manifest-row [manifest]="manifest" [osDataMiners]="osDataMiners" [showUsing]="true" (click)="editManifest(manifest)" class="flex-grow-1"
          ></app-manifest-row>
        </div>
      </form>
    </ng-container>
    <ng-template #noProfiles>
      {{'manifests.noManifests' | translate}}
    </ng-template>
    <div class="flex-container">
      <button class="btn btn-default pinned-right mr-0" (click)="editManifest({}, true)">
        <fa-icon [icon]="sharedModule.faPlusSquare" class="active-element"></fa-icon>
      </button>
    </div>
  </div>
  <ng-template #loadingDiv>
    <app-loading></app-loading>
  </ng-template>
</div>
