<div class="red" *ngIf="component.touched && component.invalid">
<!--  {{component.errors | json}}-->

  <small>
    <ng-template [ngIf]="component.errors.max">
      {{ 'validators.maxValue' | translate }} {{component.errors.max.max}}
      <br>
    </ng-template>
    <ng-template [ngIf]="component.errors.min">
      {{ 'validators.minValue' | translate }} {{component.errors.min.min}}
      <br>
    </ng-template>

    <ng-template [ngIf]="component.errors.minlength">
      {{ 'validators.minLength' | translate }} {{component.errors.minlength.requiredLength}}, {{ 'validators.now' | translate }} {{component.errors.minlength.actualLength}}
      <br>
    </ng-template>
    <ng-template [ngIf]="component.errors.maxlength">
      {{ 'validators.maxLength' | translate }} {{component.errors.maxlength.requiredLength}}, {{ 'validators.now' | translate }} {{component.errors.maxlength.actualLength}}
      <br>
    </ng-template>

<!--    {{component.errors | json}}-->
    <ng-template [ngIf]="component.errors.required">
      {{ 'validators.required' | translate }}
      <br>
    </ng-template>
    <ng-template [ngIf]="component.errors.email">
      {{ 'validators.email' | translate }}
      <br>
    </ng-template>
    <ng-template [ngIf]="component.errors.pattern">
      {{ 'validators.pattern' | translate }}
      <br>
    </ng-template>
    <ng-template [ngIf]="component.errors.isObject">
      {{ 'validators.object' | translate }}
      <br>
    </ng-template>
    <ng-template [ngIf]="component.errors.invalidSize">
<!--      {{component.errors|json}}-->
      {{ 'validators.invalidSize' | translate }} ({{component.errors.size / 1024 | number: '1.0-1'}} {{ 'kb' | translate }})
      <br>
    </ng-template>
    <ng-template [ngIf]="component.errors.forbiddenChars">
      {{ 'validators.forbiddenChars' | translate }}
      <br>
    </ng-template>
  </small>
</div>
