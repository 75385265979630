import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AuthResponce, SignUpForm, User} from '../interfaces';
import {Observable, Subject, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {SharedModule} from '../shared.module';

@Injectable()
export class AuthService {
  public error$: Subject<string> = new Subject<string>()

  constructor(
    private http: HttpClient,
    private sharedModule: SharedModule
  ) {}

  get token(): string {
    const expDate = new Date(localStorage.getItem('tokenExp'))
    if (new Date() > expDate) {
      this.removeCredentials()
      return null
    }
    return localStorage.getItem('authToken')
  }

  get loginName(): string {
    return localStorage.getItem('login')
  }

  login(user: User): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/auth/login`
    return this.http.post(url, user)
      .pipe(
        tap((response: AuthService) => {
            this.setToken(response, user.login)
          }
        ),
        catchError(this.handleError.bind(this))
      )
  }

  signUp(user: SignUpForm): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/auth/signup`
    return this.http.put(url, user)
      .pipe(
        tap((response: AuthService) => {
            this.setToken(response, user.login)
          }
        ),
        catchError(this.handleError.bind(this))
      )
  }

  logout() {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/auth/logout`
    if (this.token) { // if we have token
      this.http.post(url, this.token).subscribe(() => {
        this.removeCredentials()
      })
    } else { // no token = nothing to logout
      this.removeCredentials()
    }
  }

  removeCredentials() {
    this.setToken(null)
  }

  isAuthenticated(): boolean {
    return !!this.token
  }

  private handleError(error: HttpErrorResponse)  {
    const message = error.error.message
    // console.log ('Error from auth service', error)
    // switch (message) {
    //   case 'INVALID_EMAIL':
    //     this.error$.next('Uncorrect email')
    //     break
    //   case 'INVALID_PASSWORD':
    //     this.error$.next('Uncorrect password')
    //     break
    //   case 'EMAIL_NOT_FOUND':
    //     this.error$.next('No such email')
    //     break
    //   default:
    //     this.error$.next('Unknown error')
    //     break
    // }
    this.error$.next(message)
    return throwError(error)
  }

  private setToken(response: any, login?: string) {
    const filteredResponse: AuthResponce = response
    if (filteredResponse) {
      const expDate = new Date(new Date().getTime() + +response.expiresIn * 1000)
      localStorage.setItem('authToken', response.authToken)
      localStorage.setItem('tokenExp', expDate.toString())
      localStorage.setItem('login', login)
    } else {
      localStorage.removeItem('authToken')
      localStorage.removeItem('tokenExp')
      localStorage.removeItem('login')
    }
  }
}
