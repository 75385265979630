<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="flex-container vertical">
    <div>
      <label for="group">
        {{'groups.selectGroupToMove' | translate}}
      </label>
      <select formControlName="group" id="group" class="mt-05">
        <option *ngFor="let group of data.groups" [value]="+group.id">{{group.name}}</option>
      </select>
    </div>

    <div class="flex-container mt-05">
      <button class="btn btn-default ml-0" mat-dialog-close>{{ 'cancel' | translate }}</button>
      <button class="btn btn-default pinned-right mr-0"
        [disabled]="!form.get('group').value"
        type="submit"
        title="{{'groups.moveRigs' | translate}}"
      >
        <fa-icon [icon]="this.sharedModule.faExchangeAlt"></fa-icon>
      </button>
    </div>
  </div>
</form>
