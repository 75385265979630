<form *ngIf="! loading; else appLoading" [formGroup]="form" (ngSubmit)="submit()" style="padding: 0.5rem">
  <ng-container formGroupName="tuning">
    <ng-container *ngFor="let tuningRow of form.controls.tuning.value; index as index; trackBy: trackByFn" class="flex-container"
      [formArrayName]="index"
    >
      <label>{{getMinerDescription(tuningRow.miner)}}</label>
      <fa-icon [icon]="this.sharedModule.faMinusSquare" class="active-element" style="margin-left: 0.5rem"
        title="{{ 'tuning.' + section + '.keys.removeMiner' | translate }}"
        (click)="removeMiner(tuningRow.miner)">
      </fa-icon>
<!--      <button (click)="consolelog(tuningRow)">SHOW</button>-->
<!--      <div class="flex-container no-wrap" style="align-items: center">-->
<!--&lt;!&ndash;          <input type="hidden" formControlName="miner">&ndash;&gt;-->
<!--        <input type="text" formControlName="addition" appOnlyNumbers>-->
<!--        <fa-icon [icon]="this.sharedModule.faMinusSquare" class="active-element" size="2x" style="margin-left: 0.5rem"-->
<!--          title="{{ 'tuning.' + section + '.keys.removeMiner' | translate }}"-->
<!--          (click)="removeMiner(tuningRow.miner)"></fa-icon>-->
<!--      </div>-->

      <div class="flex-container w-100">
        <div class="form-control w-100">
          <textarea id="comment" formControlName="addition" type="text"></textarea>
        </div>
      </div>
      <div class="flex-container">
        <div class="form-control w-50 flex-container vertical">
          <label for="comment">{{ 'tuning.' + section + '.keys.addition' | translate }}</label>
          <div>
            <input formControlName="rewrite" type="radio" [value]="false">{{ 'tuning.' + section + '.keys.add' | translate }}
          </div>
          <div>
            <input formControlName="rewrite" type="radio" [value]="true">{{ 'tuning.' + section + '.keys.rewrite' | translate }}
          </div>
        </div>
        <div class="form-control w-50">
          <label for="fork">{{ 'tuning.' + section + '.keys.fork' | translate }}</label>
          <select id="fork" formControlName="fork">
            <option *ngFor="let fork of findForks(tuningRow.miner)" [ngValue]="fork">{{ fork }}</option>
            <option value="">{{ 'tuning.' + section + '.keys.noTuning' | translate }}</option>
          </select>
        </div>
      </div>
      <hr>
    </ng-container>
  </ng-container>

  <label for="minerSelect">{{ 'tuning.' + section + '.keys.addMiner' | translate }}</label>
  <div class="flex-container " style="align-items: center">
    <div class="form-control">
      <select id="minerSelect" formControlName="minerSelect">
        <option *ngFor="let minerLoc of actualMiners" [ngValue]="minerLoc">{{getMinerDescription(minerLoc)}}</option>
      </select>
    </div>
    <div class="form-control">
      <fa-icon [icon]="this.sharedModule.faPlusSquare" class="active-element ml-0" size="2x"
        title="{{ 'tuning.' + section + '.keys.addMiner' | translate }}"
        (click)="addMiner(form.get('minerSelect').value)"></fa-icon>
    </div>

  </div>
  <div class="flex-container w-100">
    <i>{{ 'tuning.' + section + '.description' | translate }}</i>
  </div>
  <div class="flex-container form-buttons">
    <button type="submit" class="btn btn-success pinned-right mr-0"
            [disabled]="!form.valid"
    >{{'save' | translate}}</button>
  </div>
</form>

<ng-template #appLoading>
  <app-loading></app-loading>
</ng-template>
