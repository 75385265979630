<div class="container">
  <div class="card">
    <div class="card-content">
      <div *ngIf="accountData; else loading">
        <form [formGroup]="form" (ngSubmit)="submit()" class="login-form">
          <div class="flex-container w-100">
            <div class="flex-container w-50">
              <label for="login">{{ 'account.login' | translate }}</label>
              <div class="form-control w-100">
                <input id="login" type="text" formControlName="login">
                <app-input-validation [component]="form.get('login')"></app-input-validation>
              </div>
            </div>
            <div class="flex-container w-50">
              <label for="regdate">{{ 'account.regdate' | translate }}</label>
              <div class="form-control w-100">
                <input id="regdate" type="text" formControlName="regdate">
              </div>
            </div>
          </div>

          <div class="flex-container w-50">
            <label for="mail">
              {{ 'account.mail' | translate }}
              <fa-icon *ngIf="this.accountData.mail_confirmed" [icon]="this.sharedModule.faCheck"></fa-icon>
            </label>
            <div class="form-control w-100"
              [ngClass]="{invalid: form.get('mail').touched && form.get('mail').invalid}">
              <input id="mail" type="text" formControlName="mail">
              <app-input-validation [component]="form.get('mail')"></app-input-validation>
            </div>
          </div>
          <div class="flex-container w-50">
            <label for="telegram">{{ 'account.telegram' | translate }}</label>
            <div class="form-control w-100" [ngClass]="{invalid: form.get('telegram').invalid}">
              <input id="telegram" type="text" formControlName="telegram">
            </div>
          </div>
          <div class="flex-container form-control w-100 vertical">
            <mat-slide-toggle formControlName="twofa_enabled" (click)="twoFaClicked($event)">{{ 'account.twofaEnabled' | translate }}</mat-slide-toggle>
          </div>


          <div class="flex-container form-buttons">
            <button
              *ngIf="!accountData.mail_confirmed"
              type="button"
              [disabled]="commandSent"
              class="btn btn-default" (click)="confirmMail()">{{ 'account.confirmMail' | translate }}</button>
            <button
              type="button"
              [disabled]="commandSent"
              class="btn btn-default" (click)="changePassword()">{{ 'account.changePassword' | translate }}</button>
<!--            <button class="btn btn-default" (click)="showForm()">test</button>-->
            <button
              type="submit"
              class="btn btn-default pinned-right mr-0"
              [disabled]="form.invalid || commandSent"
            >{{ 'save' | translate }}</button>
          </div>
        </form>
      </div>

      <ng-template #loading>
        <app-loading></app-loading>
      </ng-template>
    </div>
  </div>
</div>
