import {Injectable} from '@angular/core'
import {Subject} from 'rxjs'
import {ControlPanelCommand} from '../interfaces'

@Injectable({providedIn: 'root'})
export class ControlPanelService {
  visible = false
  groupId: number
  groupName: string
  rigIds: number[] = []
  monitorCommands$: Subject<ControlPanelCommand> = new Subject()

  hide() {
    this.visible = false
    this.monitorCommands$.complete()
    // console.log ('Control panel closed', this.monitorCommands$)
  }
  show() {
    this.visible = true
    this.monitorCommands$ = new Subject<ControlPanelCommand>()
    // console.log ('Control panel opened', this.monitorCommands$)
  }
  reset() {
    this.groupId = undefined
    this.groupName = undefined
    this.rigIds = []
  }

  // emitMonitorCommand(command: ControlPanelCommand) {
  //   this.monitorCommands$.next(command)
  // }
}
