<div class="bordered active-element">
  <div class="flex-container">
    <div class="flex-grow-1">
      <ng-container *ngIf="oc; else noOc">
        <div class="flex-container">
          <div>
            <ng-template [ngIf]="oc && oc.favorite">
              <fa-icon [icon]="this.sharedModule.faStar" class="gold"
                title="{{'oc.favoriteToggleOff' | translate}}"
              ></fa-icon>
            </ng-template>
            <ng-template [ngIf]="!oc.favorite">
              <fa-icon [icon]="this.sharedModule.farStar"
                title="{{'oc.favoriteToggleOn' | translate}}"
              ></fa-icon>
            </ng-template>
            {{oc.name}}
          </div>
          <div class="pinned-right">
            <img [src]="url" class="brand-logo">
          </div>
        </div>
        <div class="flex-container">
          <fa-icon [icon]="this.sharedModule.faCore"></fa-icon>
          <div class="manifest-info">
            <ng-container *ngIf="oc.core_clock && oc.core_clock.length > 0; else noCC">{{oc.core_clock | truncateText}}</ng-container>
            <ng-template #noCC>---</ng-template>
          </div>
        </div>
        <div class="flex-container">
          <fa-icon [icon]="this.sharedModule.faMem"></fa-icon>
          <div class="manifest-info">
            <ng-container *ngIf="oc.memory_clock && oc.memory_clock.length > 0; else noMC">{{oc.memory_clock | truncateText}}</ng-container>
            <ng-template #noMC>---</ng-template>
          </div>
        </div>
      </ng-container>
      <ng-template #noOc>
        {{'oc.noSelectedProfile' | translate}}
      </ng-template>
    </div>
<!--    <div *ngIf="controls" class="flex-container vertical ml-1">-->
<!--      <div (click)="controlOnClick($event, 'removeManifest')">-->
<!--        <fa-icon [icon]="this.sharedModule.faTimesCircle" title="{{ 'manifests.removeManifest' | translate }}" class="active-element"></fa-icon>-->
<!--      </div>-->
<!--      <div (click)="controlOnClick($event, 'restartManifest')">-->
<!--        <fa-icon [icon]="this.sharedModule.faRedo" title="{{ 'manifests.restartManifest' | translate }}" class="active-element"></fa-icon>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
