import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Group} from '../../interfaces';
import {SharedModule} from '../../shared.module';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-copy-entities',
  templateUrl: './copy-entities.component.html'
})
export class CopyEntitiesComponent implements OnInit {
  form: FormGroup
  @Output() formSubmit = new EventEmitter()
  constructor(
    public sharedModule: SharedModule,
    private dialogRef: MatDialogRef<CopyEntitiesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      farms: Group[],
      selectedFarm: number
    }
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      farm: new FormControl (this.data.selectedFarm)
    })
  }

  submit() {
    this.dialogRef.close(+this.form.value.farm);
  }
}
