import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'displayFan'
})
export class DisplayFanPipe implements PipeTransform {
  constructor() {}

  transform(rawFan: string, showPercent: boolean = true): string {
    if (+rawFan > 150) {
      return '[N/A]'
    }

    let result: string = rawFan
    if (showPercent) {
      result += '%'
    }

    return result
  }
}
