import {Component} from '@angular/core'
import {TranslateService} from '@ngx-translate/core'
import {LocalStorageService} from '../../services/local-storage.service'
import {environment} from '../../../../environments/environment'
import {SharedModule} from '../../shared.module'
import {Locale} from '../../interfaces'

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html'
})
export class LanguageSwitcherComponent {
  languages: Locale[] = environment.locales

  constructor(
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    public sharedModule: SharedModule
  ) { }

  changeLanguage(newLanguage): void {
    this.localStorageService.setLanguage(newLanguage)
    this.translateService.use(newLanguage)
  }
}
