<ng-container *ngIf="data | async as twoFaData; else appLoading">
<!--{{twoFaData | json }}-->
  <form [formGroup]="form" (ngSubmit)="submit()" class="login-form">
    <h2 mat-dialog-title>{{ 'twoFaSetup.title' | translate }}</h2>
    <mat-dialog-content>
      <div class="flex-container">
        <div class="pr-02 w-75">
          <ul>
            <li>{{ 'twoFaSetup.description_1' | translate }}</li>
            <li>{{ 'twoFaSetup.description_2' | translate }} <b>{{twoFaData.secret}}</b></li>
            <li>{{ 'twoFaSetup.description_3' | translate }}</li>
            <li>{{ 'twoFaSetup.description_4' | translate }}</li>
          </ul>
        </div>
        <div class="w-25 flex-container center align-center">
          <img class="img" [src]="twoFaData.url" alt="qr-code">
        </div>
      </div>

      <div class="form-control" [ngClass]="{invalid: form.get('twofa').touched && form.get('twofa').invalid}">
        <label for="twofa">{{ 'twoFaSetup.twofa' | translate }}</label>
        <app-input-validation [component]="form.get('twofa')"></app-input-validation>
        <input id="twofa" type="text" formControlName="twofa" appOnlyNumbers>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div class="flex-container pinned-right">
        <button class="btn btn-default" [mat-dialog-close]="true">{{ 'cancel' | translate }}</button>
  <!--      <button (click)="showForm()" type="button">showForm</button>-->
        <button
          type="submit"
          class="btn btn-default"
          [disabled]="form.invalid"
        >{{ 'save' | translate }}</button>
      </div>
    </mat-dialog-actions>
  </form>
</ng-container>
<ng-template #appLoading>
  <app-loading></app-loading>
</ng-template>
