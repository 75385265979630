import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-wrapper',
  templateUrl: './dialog-wrapper.component.html',
  styleUrls: ['dialog-wrapper.component.scss']
})
export class DialogWrapperComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private dialogRef: MatDialogRef<DialogWrapperComponent>
  ) { }

  sendCommand($event: any) {
    this.dialogRef.close($event)
  }
}
