import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'conjugateShares'
})
export class ConjugateShares implements PipeTransform {
  constructor(
    private translateService: TranslateService
  ) {}

  transform(sharesNumber: number, type = 'good'): any {
    const yaEnd = [2, 3 , 4]
    let ending = 'iy' // default
    let resultString: string
    if (yaEnd.indexOf(sharesNumber) > -1 || (yaEnd.indexOf(sharesNumber % 10) > -1 && sharesNumber >= 22) ) {
      ending = 'ya'
    } else if ((sharesNumber % 10 === 1 && sharesNumber >= 22) || +sharesNumber === 1) {
      ending = 'ie'
    }

    this.translateService.get([`shares.${ending}`, `${type}.${ending}`]).subscribe(translation => {
      resultString = `${translation[`${type}.${ending}`]} ${translation[`shares.${ending}`]}`
      return resultString
    })

    return resultString
  }
}
