import { Component, OnInit } from '@angular/core';
import {Observable, of} from 'rxjs';
import {Profile, Rig, RigCommand} from '../../../shared/interfaces';
import {RigComponent} from '../../rig.component';
import {first, map, switchMap} from 'rxjs/operators';
import {SharedModule} from '../../../shared/shared.module';
import {RigsService} from '../../../shared/services/rigs.service';

@Component({
  selector: 'app-rig-tuning',
  templateUrl: './rig-tuning.component.html',
  styleUrls: ['rig-tuning.component.scss']
})
export class RigTuningComponent implements OnInit {
  data$: Observable<Rig>
  subMethod = 'tuning'
  subMethods = [
    'tuning',
    'wdAlgo',
    'wd_temp',
    'wd_load',
    'wd_la',
    'mb_af',
    'autofan8mk',
    'autofan_coolbox',
    'autofan_napalm'
  ]
  constructor(
    private rigComponent: RigComponent,
    private sharedModule: SharedModule,
    private rigsService: RigsService
  ) {
  }

  ngOnInit() {
    this.data$ = this.rigComponent.route.params
      .pipe(
        switchMap((params) => {
          const fragment = this.rigComponent.route.snapshot.fragment
          if (fragment && this.subMethods.indexOf(fragment) !== -1) {
            this.subMethod = fragment
          }
          return this.rigComponent.rigsService.get(params.id, 'tuning')
        })
      )
  }

  onClick(subMethod: string) {
    if (subMethod !== this.subMethod) {
      this.subMethod = subMethod
    }
  }

  formSubmit(data: any) {
    // console.log ('got some event', data)
    if (Object.keys(data).length) {
      let commandData: RigCommand = {
        command: 'settings'
      }
      Object.keys(data).forEach((key) => {
        //  no need to shrink data for autofan-hardware/autofan_napalm
        if (data[key].enabled === true || ['autofan8mk', 'autofan_coolbox', 'autofan_napalm'].includes(key)) {
          commandData.args = data
        } else {
          commandData.args = {
            [key]: {
              enabled: false
            }
          }
        }
      })

      // console.log('data', data)
      // console.log('commandData', commandData)
      this.rigComponent.route.params
        .pipe(
          first(),
          switchMap((params) => {
            // console.log ('apply settings', +params.id, data)
            const patchSettings = Object.keys(data).length === 1 && ['autofan_coolbox', 'autofan_napalm'].includes(Object.keys(data)[0]);
            return this.rigComponent.rigsService.patch(+params.id, data, patchSettings)
              .pipe(
                map(() => {
                  return this.rigComponent.rigsService.command(+params.id, commandData)
                })
              )
          })
        ).toPromise()
        .catch((err) => {
          this.sharedModule.pushAlert(`Error: ${err.statusText}`, 'warning')
          // console.log ('got error', err)
        })

    }
  }

  tuningFormSubmit(data: any) {
    // console.log ('got some event', data)
    let changed = false
    let changedMiners: string[] = []
    if (data.changed.length > 0) {
      // console.log ('changed', data.changed)
      changed = true
      changedMiners = Object.assign([], data.changed)
    }
    delete data.changed
    if (Object.keys(data).length) {
      this.rigComponent.route.params
        .pipe(
          first(),
          switchMap((params) => {
            return this.rigComponent.rigsService.patch(+params.id, data)
              .pipe(
                switchMap((result) => {
                  if (result === 'OK') {
                    this.sharedModule.pushAlert('Tuning saved', 'success')
                  } else {
                    this.sharedModule.pushAlert(`Error while submitting: ${result}`, 'warning')
                  }
                  if (changed) {
                    return this.rigsService.get(+params.id, 'profiles')
                      .pipe(
                        switchMap((rigData) => {
                          if (rigData && rigData.profiles) {
                            let profilesToRestart: Profile[] = []
                            rigData.profiles.forEach(profile => {
                              let index = changedMiners.findIndex(el => el === profile.miner)
                              if (index > -1) {
                                profilesToRestart.push({
                                  id: profile.id,
                                  num: profile.num
                                })
                              }
                            })
                            // console.log('profilesToRestart', profilesToRestart)
                            if (profilesToRestart.length > 0) {
                              return this.rigsService.changeProfile(+params.id, profilesToRestart)
                                .pipe(
                                  map((res) => {
                                    // console.log ('res', res)
                                    if (res === 'OK') {
                                      let text: string
                                      if (profilesToRestart.length === 1) {
                                        text = 'Profile applied'
                                      } else {
                                        text = `${profilesToRestart.length} profiles applied`
                                      }
                                      this.sharedModule.pushAlert(text, 'success')
                                    } else {
                                      this.sharedModule.pushAlert(`Error while applying profiles: ${res}`, 'warning')
                                    }
                                  })
                                )
                            } else {
                              return of()
                            }
                          } else {
                            return of()
                          }
                        })
                      )
                  } else {
                    return of()
                  }
                })
              )
          })
        ).toPromise()
    }
  }
}
