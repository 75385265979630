import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
export function ValidateFilesize(maxSize = 1, minSize = 1): ValidatorFn {
  return (absControl: AbstractControl): ValidationErrors|null => {
    let size = +absControl.value.size
    // console.log ('size', size)
    if (size > maxSize) {
      return {
        invalidSize: true,
        size
      }
    }

    if (size < minSize) {
      return {
        invalidSize: true,
        size
      }
    }

    // if (maxSize > 1) {
    //   console.log ('minSize', minSize)
    // }
    // if (size < 260000 || size > 2550000) {
    //   return {
    //     invalidSize: true,
    //     size
    //   }
    // }
    return null;
  }
}
