import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {OcAmd, OcNvidia} from '../../interfaces';
@Component({
  selector: 'app-oc-select',
  templateUrl: './oc-select.component.html',
  styleUrls: ['./oc-select.component.scss']
})
export class OcSelectComponent{
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      ocArr: OcNvidia | OcAmd,
      brand: string
    },
  ) { }
}
