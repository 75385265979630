import {Pipe, PipeTransform} from '@angular/core';
import {isNumeric} from 'rxjs/internal-compatibility';

@Pipe({
  name: 'tempColorClass'
})
export class SetTempColorClassPipe implements PipeTransform {
  constructor() {}

  transform(temp: any, tempLimit: number = 50, notBackground = false): string {
    let template: string
    if (notBackground) {
      template = 'temp-color'
    } else {
      template = 'temp'
    }
    let result: string
    if (! isNumeric(temp)) {
      result = `${template}-0`
    } else if (+temp < tempLimit) {
      result = `${template}-1`
    } else if (+temp < tempLimit + 10) {
      result = `${template}-2`
    } else if (+temp < tempLimit + 15) {
      result = `${template}-3`
    } else if (+temp < tempLimit + 20) {
      result = `${template}-4`
    } else if (+temp < tempLimit + 25) {
      result = `${template}-5`
    } else {
      result = `${template}-6`
    }

    return result
  }
}
