import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {ValidateSpecialChars} from '../../validators/special-chars.validator';
import {AuthService} from '../../services/auth.service';
import {SignUpForm} from '../../interfaces';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {SharedModule} from '../../shared.module';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  form: FormGroup
  // @Output() formSubmit = new EventEmitter()
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private dialogRef: MatDialogRef<SignUpComponent>,
    private router: Router,
    private sharedModule: SharedModule
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      login: ['', [
        Validators.required,
        Validators.minLength(environment.patterns.login.minLength),
        Validators.maxLength(environment.patterns.login.maxLength),
        Validators.pattern(environment.patterns.login.pattern),
        ValidateSpecialChars
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(environment.patterns.password.minLength),
        Validators.maxLength(environment.patterns.password.maxLength),
        Validators.pattern(environment.patterns.password.pattern),
      ]],
      passwordConfirmation: ['', [
        Validators.required,
        Validators.minLength(environment.patterns.password.minLength),
        Validators.maxLength(environment.patterns.password.maxLength),
        Validators.pattern(environment.patterns.password.pattern),
      ]],
      mail: ['', [
        Validators.required,
        Validators.email,
        Validators.minLength(environment.patterns.mail.minLength),
        Validators.maxLength(environment.patterns.mail.maxLength)
      ]],
      name: [''],
      telegram: [''],
      promo: ['']
    })
  }

  submit() {
    const regForm: SignUpForm = this.form.value
    // console.log ('submit', this.form.value)
    // this.formSubmit.emit(this.form.value)

    this.authService.signUp(regForm)
      .pipe(
        map((data) => {
          if (!data || !data.authToken || !data.expiresIn) {
            this.sharedModule.pushAlert(`Error: ${data}`, 'warning')
          } else {
            this.form.reset()
            this.router.navigate(['/monitor'])
            this.dialogRef.close()
          }
        })
      ).toPromise().catch((error) => {
        this.sharedModule.pushAlert(`Error: ${error.error.message}`, 'warning')
      })
  }
}
