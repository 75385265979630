{{ deleteString | translate }}
<div class="flex-container">
  <button class="btn btn-default"
    [mat-dialog-close]="false"
  >
    {{ 'no' | translate}}
  </button>
  <button class="btn btn-danger pinned-right"
    [mat-dialog-close]="true">
    {{ 'yes' | translate}}
  </button>
</div>
