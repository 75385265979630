import {Injectable} from '@angular/core'
import {HttpClient, HttpParams} from '@angular/common/http'
import {Observable} from 'rxjs'
import {Profile} from '../interfaces'
import {SharedModule} from '../shared.module'

@Injectable({providedIn: 'root'})
export class ManifestsService {
  constructor(
    private http: HttpClient,
    private sharedModule: SharedModule
  ) {}

  fetchAll(farmId: number, data?: string): Observable<Profile[]> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/farms/${farmId}/manifests`
    if (data) {
      const params = new HttpParams().set('view', data)
      return this.http.get<Profile[]>(url, {params})
    } else {
      return this.http.get<Profile[]>(url)
    }
  }

  fetch(farmId: number, manifestId: number, data?: string): Observable<Profile> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/farms/${farmId}/manifests/${manifestId}`
    if (data) {
      const params = new HttpParams().set('view', data)
      return this.http.get<Profile>(url, {params})
    } else {
      return this.http.get<Profile>(url)
    }
  }

  patch(farmId: number, manifestId: number, data: Profile): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/farms/${farmId}/manifests/${manifestId}`
    return this.http.patch(url, data)
  }

  delete(farmId: number, profileId: number): Observable<string> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/farms/${farmId}/manifests/${profileId}`
    return this.http.delete<string>(url)
  }

  create(farmId: number, data: Profile): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/farms/${farmId}/manifests`
    return this.http.put(url, data)
  }
}
