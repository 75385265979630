import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {AccountService} from '../../services/account.service';
import {map} from 'rxjs/operators';
import {SharedModule} from '../../shared.module';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup
  constructor(
    private fb: FormBuilder,
    private accountsService: AccountService,
    private sharedModule: SharedModule,
    private dialogRef: MatDialogRef<ForgotPasswordComponent>,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      mail: ['', [
        Validators.required,
        Validators.email,
        Validators.minLength(environment.patterns.mail.minLength),
        Validators.maxLength(environment.patterns.mail.maxLength)
      ]]
    })

  }

  submit() {
    const dataToSend = this.form.value
    if (dataToSend.mail) {
      this.accountsService.sendPasswordReset(dataToSend)
        .pipe(
          map((data) => {
            if (data === 'OK') {
              this.form.reset()
              this.dialogRef.close()
              this.router.navigate(['/'])
              this.sharedModule.pushAlert('Email with reset link sent', 'success')
            } else {
              this.sharedModule.pushAlert(`Error: ${data}`, 'warning')
            }
          })
        ).toPromise()
        .catch((error) => {
          this.sharedModule.pushAlert(`Error: ${error.error.message}`, 'warning')
        })
    }
  }
}
