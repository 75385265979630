import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MotherboardAutofan} from '../../interfaces';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-edit-wd-mb-af',
  templateUrl: './edit-wd-mb-af.component.html'
})
export class EditWdMbAfComponent implements OnInit {
  @Input() data: MotherboardAutofan
  @Output() formSubmit = new EventEmitter()
  section = 'mb_af'
  form: FormGroup
  simpleControls = [
    'pwm',
    'tt',
    'hyst',
    'fanmin',
    'fanmax',
    'shift',
    'delay'
  ]

  boolControls = [
    'enabled',
    'invert'
  ]
  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      enabled: [false],
      invert: [false],
      pwm: ['', [
        Validators.required
      ]],
      tt: [65, [
          Validators.required,
          Validators.min(20),
          Validators.max(100)
        ]
      ],
      hyst: [3, [
          Validators.min(0),
          Validators.max(20)
        ]
      ],
      fanmin: [20, [
          Validators.min(0),
          Validators.max(100)
        ]],
      fanmax: [100, [
          Validators.min(1),
          Validators.max(100)
        ]],
      shift: [5, [
          Validators.min(1),
          Validators.max(30)
        ]],
      delay: [10, [
        Validators.min(3),
        Validators.max(120)
      ]]
    })

    this.form.patchValue(this.data)
  }

  submit() {
    let data = {
      mb_af: this.form.value
    }
    this.formSubmit.emit(data)
  }
}
