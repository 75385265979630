import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {SharedModule} from '../shared.module';

@Directive({
  selector: '[appCopyOnClick]'
})
export class CopyOnClickDirective {
  constructor(
    public elementRef: ElementRef,
    private clipboard: Clipboard,
    private sharedModule: SharedModule
  ) { }
  @Input('appCopyOnClick') content: string
  @HostListener('click') onMouseClick() {
    this.clipboard.copy(this.content)
    this.sharedModule.pushAlert('Copied to clipboard', 'success')
  }
}
