import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {SharedModule} from '../../shared.module';
import {OcAmd} from '../../interfaces';
import {FormBuilder, FormGroup} from '@angular/forms';
import {OcService} from '../../services/oc.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-edit-oc-amd',
  templateUrl: './edit-oc-amd.component.html'
})
export class EditOcAmdComponent implements OnInit, OnChanges {
  @Input() oc: OcAmd
  @Input() owner: number
  @Output() formSubmit = new EventEmitter()
  brand = 'amd'
  form: FormGroup
  simpleControls = [
    'core_clock',
    'core_state',
    'core_voltage',
    'memory_clock',
    'memory_state',
    'memory_voltage',
    'soc_clk_max',
    'soc_vdd_max',
    'vddci',
    'fan'
  ]
  boolControls = [
    'alt',
    'enhanced'
  ]

  constructor(
    public sharedModule: SharedModule,
    private fb: FormBuilder,
    private ocService: OcService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: [''],
      name: [''],
      favorite: [''],
      core_clock: [''],
      core_state: [''],
      core_voltage: [''],
      memory_clock: [''],
      memory_state: [''],
      memory_voltage: [''],
      memory_tweak: [''],
      memory_tweak_type: [''],
      soc_clk_max: [''],
      soc_vdd_max: [''],
      vddci: [''],
      fan: [''],
      alt: [false],
      enhanced: [''],
      saveAsNew: [false]
    })
    if (this.oc) {
      this.form.patchValue(this.oc)
    }
    if (!this.oc.id) {
      this.form.get('saveAsNew').patchValue(true)
      this.form.get('saveAsNew').disable()
    }
  }

  toggleFavorite() {
    const currentValue = this.form.controls.favorite.value
    this.form.controls.favorite.setValue(!currentValue)
  }

  prepareFormData(): OcAmd {
    let formData = this.form.value
    return {
      id: +formData.id,
      name: formData.name,
      favorite: formData.favorite,
      core_clock: formData.core_clock,
      core_state: formData.core_state,
      core_voltage: formData.core_voltage,
      memory_clock: formData.memory_clock,
      memory_state: formData.memory_state,
      memory_voltage: formData.memory_voltage,
      memory_tweak: formData.memory_tweak,
      memory_tweak_type: formData.memory_tweak_type,
      soc_clk_max: formData.soc_clk_max,
      soc_vdd_max: formData.soc_vdd_max,
      vddci: formData.vddci,
      fan: formData.fan,
      alt: formData.alt,
      enhanced: formData.enhanced,
    }
  }

  ngOnChanges() {
    if (this.form) {
      this.form.patchValue(this.oc)
    }
  }

  submit(command= 'save') {
    let data = this.prepareFormData()
    let dataToSend = {
      command,
      brand: this.brand,
      data
    }

    if (! this.sharedModule.compareObjects(data, this.oc)) { // data !==  initial data
      if (this.form.value.saveAsNew === false) {
        this.ocService.patch(this.owner, this.brand, this.form.value.id, data)
          .pipe(
            map((result) => {
              if (result === 'OK') {
                this.formSubmit.emit(dataToSend)
              } else {
                this.sharedModule.pushAlert('Error while changing OC profile')
              }
            })
          ).toPromise().catch((error) => {
            // console.log ('Patch error', dataToSend)
            this.sharedModule.pushAlert(error.error.message, 'warning')
          })
      } else {
        delete(data.id)
        this.ocService.create(this.owner, this.brand, data)
          .pipe(
            map((result) => {
              if (Number.isInteger(result.id)) {
                data.id = result.id
                this.formSubmit.emit(dataToSend)
              } else {
                this.sharedModule.pushAlert('Error while creating OC profile', 'warning')
              }
            })
          ).toPromise().catch((error) => {
            this.sharedModule.pushAlert(error.statusText, 'warning')
        })
      }
    } else {
      this.formSubmit.emit(dataToSend)
    }
  }
}
