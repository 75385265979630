import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable()

export class LocalStorageService {
  get language(): string {
    const lang: string = localStorage.getItem('language')
    if (lang) {
      return lang
    }
    // default
    return environment.defaults.language
  }

  get login(): string {
    const login: string = localStorage.getItem('login')
    if (login) {
      return login
    }
    return ''
  }

  get theme(): string {
    const theme: string = localStorage.getItem('theme')
    if (theme) {
      return theme
    }
    // default
    return environment.defaults.theme
  }

  get viewMode(): string {
    const viewMode: string = localStorage.getItem('viewMode')
    if (viewMode) {
      return viewMode
    }
    // default
    return environment.defaults.viewMode
  }

  get tempLimit(): number {
    const tempLimit: string = localStorage.getItem('tempLimit')
    if (tempLimit) {
      return +tempLimit
    }
    // default
    return environment.defaults.tempLimit
  }

  get additionalSysInfo(): boolean {
    const additionalSysInfo: string = localStorage.getItem('additionalSysInfo')
    if (additionalSysInfo === 'true') {
      return true
    } else if (additionalSysInfo === 'false') {
      return false
    }
    // default
    return environment.defaults.additionalSysInfo
  }

  get autofanHardwareShowZeroFan(): boolean {
    const autofanHardwareShowZeroFan: string = localStorage.getItem('autofanHardwareShowZeroFan')
    if (autofanHardwareShowZeroFan === 'true') {
      return true
    } else if (autofanHardwareShowZeroFan === 'false') {
      return false
    }
    // default
    return environment.defaults.autofanHardwareShowZeroFan
  }

  get autofanNapalmShowZeroFan(): boolean {
    const autofanNapalmShowZeroFan: string = localStorage.getItem('autofanNapalmShowZeroFan')
    if (autofanNapalmShowZeroFan === 'true') {
      return true
    } else if (autofanNapalmShowZeroFan === 'false') {
      return false
    }
    // default
    return environment.defaults.autofanNapalmShowZeroFan
  }

  setTheme(theme: string) {
    localStorage.setItem('theme', theme)
  }
  setLanguage(lang: string) {
    localStorage.setItem('language', lang)
  }
  setShowAdditonalSysInfo(additionalSysInfo: boolean) {
    localStorage.setItem('additionalSysInfo', '' + additionalSysInfo)
  }
  setViewMode(viewMode: string) {
    localStorage.setItem('viewMode', viewMode)
  }
  setTempLimit(tempLimit: number) {
    localStorage.setItem('tempLimit', '' + tempLimit)
  }
  setAutofanHardwareShowZeroFan(autofan8mkShowZeroFan: boolean) {
    localStorage.setItem('autofanHardwareShowZeroFan', '' + autofan8mkShowZeroFan)
  }

  // setAutofanNapalmShowZeroFan(value: boolean) {
  //   localStorage.setItem('autofanNapalmShowZeroFan', '' + value)
  // }

  // Hidden Farms fucntions
  get hiddenFarms(): number[] {
    const hiddenFarms: number[] = JSON.parse(localStorage.getItem('hiddenFarms'))
    if (hiddenFarms) {
      return hiddenFarms
    }

    return []
  }

  setHiddenFarm(farmId: number) {
    let hiddenFarms = this.hiddenFarms
    if (hiddenFarms) {
      if (hiddenFarms.indexOf(farmId) === -1) {
        hiddenFarms.push(farmId)
        localStorage.setItem('hiddenFarms', JSON.stringify(hiddenFarms))
      }
    } else {
      localStorage.setItem('hiddenFarms', JSON.stringify([farmId]))
    }
  }

  unsetHiddenFarm(farmId: number) {
    let hiddenFarms = this.hiddenFarms
    if (hiddenFarms) {
      if (hiddenFarms.indexOf(farmId) !== -1) {
        if (hiddenFarms.length === 1) {
          localStorage.removeItem('hiddenFarms')
        } else {
          hiddenFarms = hiddenFarms.filter(el => +el !== +farmId)
          localStorage.setItem('hiddenFarms', JSON.stringify(hiddenFarms))
        }
      }
    }
  }
}
