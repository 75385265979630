<form [formGroup]="form" (ngSubmit)="submit()" class="login-form" autocomplete="off">
  <h2 mat-dialog-title>{{ 'twoFaConfirmDelete.title' | translate }}</h2>
  <mat-dialog-content>
    <div class="form-control" [ngClass]="{invalid: form.get('twofa').touched && form.get('twofa').invalid}">
      <label for="twofa">{{ 'twoFaConfirmDelete.twofa' | translate }}</label>
      <app-input-validation [component]="form.get('twofa')"></app-input-validation>
      <input id="twofa" type="text" formControlName="twofa" appOnlyNumbers autocomplete="off">
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="flex-container pinned-right">
      <button class="btn btn-default" [mat-dialog-close]="true">{{ 'cancel' | translate }}</button>
      <button
        type="submit"
        class="btn btn-default"
        [disabled]="form.invalid"
      >{{ 'save' | translate }}</button>
    </div>
  </mat-dialog-actions>
</form>
