<div class="container-wide flex-container ml-0 section">
  <div class="ml-0 mt-0 p-0 sub-nav-panel-wrapper">
    <div class="nav-panel sub-nav-panel">
      <div *ngFor="let method of subMethods" class="menu-link tab"
          [ngClass]="{active: method === subMethod}" (click)="onClick(method)">
        {{'connections.' + method + '.name' | translate}}
      </div>
    </div>
  </div>

  <div class="container mt-0 w-100">
    <div class="card mt-0">
      <div class="card-content" [ngSwitch]="subMethod">
        <ng-template ngSwitchCase="dogmate" style="padding-bottom: 2px">
          <div class="container vertical mb-0">
            <div *ngFor="let arg of ['start', 'restart', 'stop']">
              <button class="btn btn-default btn-command" [disabled]="commandApplying" (click)="pushCommand('exec', 'dogmate ' + arg)">
                dogmate {{arg}}
              </button>
            </div>
          </div>
          <br>
          <hr>
          {{ 'connections.dogmate.description' | translate }}
        </ng-template>

        <ng-template ngSwitchCase="shellinabox">
          <div *ngIf="data$ | async as data">
            <div *ngIf="data.ip as ip">
              {{ 'connections.shellinabox.help1' | translate }}
              <a href="https://{{ip}}:4201" target="_blank">https://{{ip}}:4201</a>
              или
              <a href="http://{{ip}}:4201" target="_blank">http://{{ip}}:4201</a>
              <br>
              {{ 'connections.shellinabox.help2' | translate }} <b>user</b>
              <br>
              {{ 'connections.shellinabox.help3' | translate }} <b>1</b>
            </div>
          </div>
          <br>
          <hr>
          {{ 'connections.shellinabox.description' | translate }}
        </ng-template>

        <ng-template ngSwitchCase="ssh">
          <div *ngIf="data$ | async as data">
            <div *ngIf="data.ip as ip">
              {{ 'connections.ssh.help1' | translate }}
              <b>ssh user@{{ip}}</b>
              <br>
              {{ 'connections.ssh.help2' | translate }}
              <a href="https://www.putty.org/" target="_blank">Putty</a>
              <br>
              {{ 'connections.ssh.help3' | translate }} <b>user</b>
              <br>
              {{ 'connections.ssh.help4' | translate }} <b>1</b>
            </div>
          </div>
          <br>
          <hr>
          {{ 'connections.ssh.description' | translate }}
        </ng-template>

        <ng-template ngSwitchCase="vnc">
          <div *ngIf="data$ | async as data">
            <div *ngIf="data.ip as ip">
            {{ 'connections.vnc.help1' | translate }}
            <a href="https://www.realvnc.com/en/connect/download/viewer/" target="_blank">RealVNC Viewer</a>, {{ 'connections.vnc.help2' | translate }}
            <br>
            {{ 'connections.vnc.help3' | translate }} <b>{{ip}}:5900</b>
            <br>
            {{ 'connections.vnc.help4' | translate }} <b>1</b>
            </div>
          </div>
          <br>
          <hr>
          {{ 'connections.vnc.description' | translate }}
        </ng-template>

        <ng-template ngSwitchCase="openvpn">
          <ng-container *ngIf="data$ | async as data">
            <app-edit-openvpn [data]="data" (dataUpload)=dataUpload($event)></app-edit-openvpn>
          </ng-container>
          <hr>
          {{ 'connections.openvpn.description' | translate }}
        </ng-template>

        <ng-template ngSwitchCase="remotessh">
          <div *ngIf="data$ | async as data">
            <div *ngIf="data.remotessh">
              <app-edit-remotessh [data]="data.remotessh"></app-edit-remotessh>

            </div>
          </div>
          <hr>
          {{ 'connections.remotessh.description' | translate }}
        </ng-template>
      </div>
    </div>
  </div>

</div>
