import {Injectable} from '@angular/core';
import {Alert} from '../interfaces';

@Injectable({providedIn: 'root'})
export class AlertsService {
  alerts: Alert[] = []
  id = 0
  ttl = 3000

  pushAlert(alert: Alert) {
    alert.id = this.id
    this.id++

    this.alerts.push(alert)
    setTimeout(() => {
      let alertIndex = this.alerts.findIndex(el => el.id === alert.id)
      if (alertIndex > -1) {
        this.alerts.splice(alertIndex, 1)
      }
    }, this.ttl)
  }
}
