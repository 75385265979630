import {Component, OnInit} from '@angular/core';
import {first, map, switchMap} from 'rxjs/operators';
import {forkJoin, Observable, of} from 'rxjs';
import {RigComponent} from '../../rig.component';
import {Autofan, FarmOC, OcAmd, OcNvidia, Rig, RigCommand, RigOC} from '../../../shared/interfaces';
import {OcService} from '../../../shared/services/oc.service';
import {MatDialog} from '@angular/material/dialog';
import {OcSelectComponent} from '../../../shared/components/oc-select/oc-select.component';
import {SharedModule} from '../../../shared/shared.module';

@Component({
  selector: 'app-rig-oc',
  templateUrl: './rig-oc.component.html',
  styleUrls: ['./rig-oc.component.scss']
})
export class RigOcComponent implements OnInit {
  allOc$: Observable<FarmOC>
  oc: RigOC
  subMethod = 'amd'
  loading = true
  ocProfileChanged = false

  constructor(
    private rigComponent: RigComponent,
    private ocService: OcService,
    private dialog: MatDialog,
    private sharedModule: SharedModule
  ) {
  }

  ngOnInit() {
    let sub = this.rigComponent.route.params
      .pipe(
        switchMap((params) => {
          // console.log ('rig oc: switch')
          return this.rigComponent.rigsService.get(params.id, 'oc')
            .pipe(
              switchMap((rig) => {
                return forkJoin([
                  rig.oc_amd !== 0 ? this.ocService.fetch(rig.owner, 'amd', rig.oc_amd) : of({}),
                  rig.oc_nvidia !== 0 ? this.ocService.fetch(rig.owner, 'nvidia', rig.oc_nvidia) : of({}),
                  of(rig.af),
                  of(rig.owner)
                ])
                  .pipe(
                    map(merged => {
                      return {
                        amd: merged[0],
                        nvidia: merged[1],
                        af: merged[2],
                        owner: merged[3]
                      }
                    })
                  )
              })
            )
        })
      ).subscribe((data) => {
        this.oc = data
        this.loading = false
        sub.unsubscribe()
      })
  }

  onClick(subMethod: string) {
    if (subMethod !== this.subMethod) {
      this.subMethod = subMethod
    }
  }

  async openSelect(oc: RigOC, brand: string) {
    // if (!this.allOc$) {
    this.allOc$ = this.ocService.fetchAll(oc.owner, 'short', brand)
    // }
    let dialogReg = this.dialog.open(OcSelectComponent, {
      data: {
        ocArr: this.allOc$.pipe(
          map((data) => data[brand])
        ),
        brand
      },
      panelClass: 'custom-dialog-container',
      maxWidth: undefined
    })
    let result = await dialogReg.afterClosed()
      .pipe(
        switchMap((dialogData) => {
          if (dialogData) {
            return this.ocService.fetch(oc.owner, brand, dialogData)
          } else {
            return of(null)
          }
        })
      ).toPromise()
    if (result) {
      oc[brand] = result
      this.oc = oc
      this.ocProfileChanged = true
    }
  }

  ocFormSubmit(data: { command: string, brand: 'amd' | 'nvidia', data: OcAmd | OcNvidia }) {
    console.log ('ocFormSubmit', data)
    let commandData: RigCommand
    if (data.command === 'save') {
      commandData = {
        command: 'oc',
        args: {
          brand: data.brand,
          data: data.data
        }
      }
    } else if (data.command === 'saveAndReboot') {
      commandData = {
        command: 'reboot'
      }
    }
    this.oc[data.brand] = data.data
    // console.log (this.oc)
    if (this.ocProfileChanged) {
      let patchData: Rig
      if (data.brand === 'amd') {
        patchData = {
          oc_amd: data.data.id
        }
      } else if (data.brand === 'nvidia') {
        patchData = {
          oc_nvidia: data.data.id
        }
      }
      this.rigComponent.route.params
        .pipe(
          first(),
          switchMap((params) => {
            this.ocProfileChanged = false
            return this.rigComponent.rigsService.patch(+params.id, patchData)
            .pipe(
              map(() => {
                return this.rigComponent.rigsService.command(+params.id, commandData)
              })
            )
          })
        ).toPromise().catch((error) => {
        this.sharedModule.pushAlert(error.error.message, 'warning')
      })
    } else {
      this.rigComponent.route.params
        .pipe(
          first(),
          map((params) => {
            // console.log('apply oc', +params.id, commandData)
            return this.rigComponent.rigsService.command(+params.id, commandData)
          })
        ).toPromise().catch((error) => {
          this.sharedModule.pushAlert(error.error.message, 'warning')
        })
    }
  }

  afFormSubmit(af: Autofan) {
    let commandData: RigCommand = {
      command: 'settings',
      args: {
        af: {}
      }
    }
    if (af.enabled === true) {
      commandData.args.af = af
    } else {
      commandData.args.af = {
        enabled: false
      }
    }

    if (! this.sharedModule.compareObjects(af, this.oc.af)) {
      const patchData: Rig = {
        af
      }

      this.rigComponent.route.params
        .pipe(
          first(),
          switchMap((params) => {
            return this.rigComponent.rigsService.patch(+params.id, patchData)
              .pipe(
                map(() => {
                  return this.rigComponent.rigsService.command(+params.id, commandData)
                })
              )
          })
        ).toPromise().catch((error) => {
          this.sharedModule.pushAlert(error.error.message, 'warning')
      })
    } else {
      this.rigComponent.route.params
        .pipe(
          first(),
          map((params) => {
            console.log ('apply af', params.id, commandData)
            return this.rigComponent.rigsService.command(+params.id, commandData)
          })
        ).toPromise().catch((error) => {
          this.sharedModule.pushAlert(error.error.message, 'warning')
        })
    }
  }
}
