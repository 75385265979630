<ng-container *ngIf="controlPanelService.visible">
  <div *ngIf="controlPanelService.visible" class="fixedControlPanel">
    <div class="cpanel">
      <div *ngIf="controlPanelService.groupName && controlPanelService.groupId" class="flex-container align-center">
        <div id="groupInfo" class="visible-xl">
          <div id="groupName">
            {{controlPanelService.groupName}}
          </div>
          <fa-icon [icon]="this.sharedModule.faAngleRight" class="ml-02 mr-02"></fa-icon>
          {{controlPanelService.rigIds.length}} rigs
        </div>

        <div *ngIf="controlPanelService.rigIds.length > 0" class="flex-container align-center">
          <div class="action-button note-gray" (click)="openProfiles()">
            <fa-icon [icon]="this.sharedModule.faToolbox" class="active-element" title="{{'controlPanel.profiles' | translate}}"
            ></fa-icon>
          </div>

          <div class="action-button note-gray" (click)="openActions()">
            <fa-icon [icon]="this.sharedModule.faTerminal" class="active-element" title="{{'controlPanel.commands' | translate}}"></fa-icon>
          </div>

          <div class="action-button note-gray" (click)="deleteMessages()">
            <fa-icon [icon]="this.sharedModule.faTrash" class="active-element" title="{{'controlPanel.deleteMessages' | translate}}"></fa-icon>
          </div>
        </div>
      </div>

      <div class="pinned-right pr-02 flex-container align-center">
        <div class="action-button note-gray " (click)="editTempLimit()">
          <fa-icon [icon]="this.sharedModule.faTemperatureHigh" title="{{ 'controlPanel.editTempLimit' | translate }}"></fa-icon>
        </div>

         <div class="action-button note-gray" (click)="setViewMode('short')">
          <fa-icon [icon]="this.sharedModule.faShortMode" title="{{ 'controlPanel.compactMode' | translate }}"
            [ngClass]="{'gold': viewMode === 'short'}"></fa-icon>
        </div>

        <div class="action-button note-gray" (click)="setViewMode('full')">
          <fa-icon [icon]="this.sharedModule.faFullMode" title="{{ 'controlPanel.fullMode' | translate }}"
            [ngClass]="{'gold': viewMode === 'full'}"></fa-icon>
        </div>

        <div class="action-button note-gray" (click)="emitToMonitor({command: 'fetchData'})">
          <fa-icon [icon]="this.sharedModule.faSync" title="{{ 'monitor.refresh' | translate }}"></fa-icon>
        </div>
      </div>

    </div>
  </div>
  <div class="marginCP"></div>
</ng-container>
