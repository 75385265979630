import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {Rig, RigCommand} from '../../../shared/interfaces';
import {RigComponent} from '../../rig.component';
import {first, map, switchMap, tap} from 'rxjs/operators';
import {RigsService} from '../../../shared/services/rigs.service';
@Component({
  selector: 'app-rig-connections',
  templateUrl: './rig-connections.component.html'
})
export class RigConnectionsComponent {
  data$: Observable<Rig>
  data: Rig = {}
  commandApplying = false
  subMethod = 'dogmate'
  subMethods = [
    'dogmate',
    'shellinabox',
    'ssh',
    'vnc',
    'openvpn',
    'remotessh'
  ]
  constructor(
    private rigComponent: RigComponent,
    private rigsService: RigsService
  ) {
  }

  onClick(subMethod: string) {
    if (subMethod !== this.subMethod) {
      this.subMethod = subMethod
      if (subMethod !== 'dogmate' && this.subMethods.indexOf(subMethod) > -1) {
        let requestType = 'ip' // default for ssh, vnc, shellinabox

        if (subMethod === 'openvpn') {
          requestType = 'openvpn'
        } else if (subMethod === 'remotessh') {
          requestType = 'remotessh'
        }

        this.data$ = this.rigComponent.route.params
          .pipe(
            first(),
            switchMap((params) => {
              return this.rigComponent.rigsService.get(params.id, requestType)
            })
          )

        // this.data$.subscribe((result) => {
        //   console.log ('Data', result)
        // })
      }
    }
  }

  pushCommand(command: string, args?: string) {
    let commandData: RigCommand = {
      command, args
    }
    this.rigComponent.route.params
      .pipe(
        first(),
        map((params) => {
          return this.rigsService.command(params.id, commandData)
        })
      ).toPromise()
  }

  dataUpload(data: FormData) {
    // console.log ('After upload', data)
    if (data) {
    //   console.log ('Try to upload data')
    // }
      this.rigComponent.route.params
        .pipe(
          first(),
          switchMap((params) => {
            return this.rigComponent.rigsService.upload(params.id, 'openvpn', data)
              .pipe(
                tap((result) => {
                  // console.log ('result', result)
                  this.data$ = this.rigComponent.rigsService.get(params.id, 'openvpn')
                  return this.rigComponent.rigsService.get(params.id, 'openvpn')
                })
              )
          })
        )
        .toPromise()
        .catch((error) => {
          console.log('Error while dataUpload', error)
        })
    }
  }
}
//
// biosUpload(data: FormData) {
//   console.log ('After BIOS upload', data)
//   if (data) {
//     this.rigComponent.route.params
//       .pipe(
//         first(),
//         map((params) => {
//           return this.rigComponent.rigsService.upload(params.id, 'bios', data)
//         })
//       )
//       .toPromise()
//   }
// }
