import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ManifestsService} from '../../services/manifests.service';
import {Profile, OsDataMiner, RigCommand, OsDataAlgo} from '../../interfaces';
import {MatDialog} from '@angular/material/dialog';
import {CustomSelectComponent} from '../custom-select/custom-select.component';
import {LoadingComponent} from '../loading/loading.component';
import {RigsService} from '../../services/rigs.service';
import {SharedModule} from '../../shared.module';
import {EditProfileComponent} from '../edit-profile/edit-profile.component';
import {OsService} from '../../services/os.service';
import {map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

@Component({
  selector: 'app-wallets-block',
  templateUrl: './wallets-block.component.html'
})
export class WalletsBlockComponent implements OnInit{
  @Input() farmId
  @Input() rigId
  @Input() manifestsId
  @Input() profiles: Profile[]
  manifests: Profile[]
  hideNewProfile = true
  loading = false
  osDataMiners: OsDataMiner[] = [] // for edit profile
  osDataAlgos: OsDataAlgo[] // for edit profile

  constructor(
    private manifestsService: ManifestsService,
    private rigsService: RigsService,
    private dialog: MatDialog,
    public sharedModule: SharedModule,
    private osService: OsService
  ) { }

  sortProfiles(el1: Profile, el2: Profile): number {
    if (el1.num > el2.num) {
      return 1
    } else if (el1.num < el2.num) {
      return -1
    }
    return 0
  }
  async ngOnInit() {
    this.osDataMiners = await this.osService.getMiners().toPromise()
    // console.log(this.osDataMiners)
    // console.log ('profiles on init', this.profiles)
    if (! this.profiles) {
      console.log ('Bug with profiles')
    }
    this.profiles.sort((el1, el2) => this.sortProfiles(el1, el2))
    // console.log ('profiles', this.profiles)
    for (let i = this.profiles.length - 1; i >= 0; i--) {
      let profile = this.profiles[i]
      if (!profile.id) { // deleted profile in the end
        this.profiles.splice(i, 1)
      } else {
        break
      }
    }
  }

  getNewIndex(): number {
    if (this.profiles.length > 0) {
      let numArray = Object.assign([], this.profiles.map(el => +el.num))
      numArray.sort((n1, n2) => {
        if (n1 > n2) {
          return 1
        } else {
          return -1
        }
      })
      let lastNum = numArray[numArray.length - 1]
      return lastNum + 1
    }

    return 0
  }

  haveSockets() {
    const index = this.profiles.findIndex(el => !el.id)
    if (index > -1) {
      return true
    }
    return false
  }

  changeManifest(num: number, manifest: Profile|null) {
    this.loading = true
    let profilesTmp = Object.assign([], this.profiles)

    let index = profilesTmp.findIndex(el => el.num === num)
    if (manifest) {
      let shortProfile = {
        num,
        id: manifest.id,
        name: manifest.name,
        wallet: manifest.wallet,
        pool: manifest.pool,
        algo: manifest.algo,
        favorite: manifest.favorite,
        coin: manifest.coin
      }
      if (index > -1) {
        profilesTmp[index] = shortProfile
      } else { // new profile
        profilesTmp.push(shortProfile)
      }
    } else if (index > -1) {
      profilesTmp[index] = {
        num: profilesTmp[index].num,
        id: null
      }
      // Splice
      // if (index < this.profiles.length - 1) {
      //   for (let i = index + 1; i < profilesTmp.length; i++) {
      //     profilesTmp[i].num--
      //   }
      // }
      // profilesTmp.splice(index, 1)
    } else {
      this.sharedModule.pushAlert('Internal error', 'warning')
    }

    let commandData = profilesTmp.map(el => {
      return {
        num: el.num,
        id: el.id
      }
    })
    // console.log ('after commands', profilesTmp)
    this.rigsService.setProfiles(this.rigId, commandData)
      .pipe(
        map((result) => {
          this.profiles = profilesTmp
          this.hideNewProfile = true
          this.loading = false
          let alertText: string
          if (! manifest) { // stop profile
            alertText = 'Profile removed'
          } else if (result === 'OK') {
            alertText = 'Profile applied'
          } else {
            alertText = result
          }
          this.sharedModule.pushAlert(alertText, 'success')
        })
      ).toPromise()
      .catch((err) => {
        console.log ('got error', err)
        this.sharedModule.pushAlert(`Error: ${err.statusText}`, 'warning')
      })
  }

  async openSelect(manifestNumber: number) {
    if (!this.loading) {
      if (! this.manifests) {
        let dialogRef = this.dialog.open(LoadingComponent)
        this.manifests = await this.manifestsService.fetchAll(this.farmId).toPromise()
        dialogRef.close()
      }
      let ids = []
      if (this.profiles) {
        ids = this.profiles.map(el => el.id)
      }
      let filteredManifests = this.manifests.filter((el) => {
        return ! ids.includes(el.id)
      })

      let dialogReg = this.dialog.open(CustomSelectComponent, {
        panelClass: 'custom-dialog-container',
        maxWidth: undefined,
        data: {
          manifests: filteredManifests,
          osDataMiners: this.osDataMiners
        }
      })

      let sub = dialogReg.afterClosed().subscribe((manifest) => {
        if (manifest) {
          this.changeManifest(manifestNumber, manifest)
        }
        sub.unsubscribe()
      });
    }
  }

  controlOnClick($event: any, num: number) {
    if (!this.loading) {
      if ($event === 'removeManifest') {
        this.changeManifest(num, null)
      } else if ($event === 'restartManifest') {
        let index = this.profiles.findIndex(el => el.num === num)
        if (index > -1) {
          const data = {
            num: this.profiles[index].num,
            id: this.profiles[index].id
          }
          let commandData: RigCommand = {
            command: 'profile',
            args: data
          }
          this.rigsService.command(this.rigId, commandData)
        }
      } else if ($event === 'editProfile') {
        this.editProfile(num, this.profiles[num])
      }
    }
  }
  async editProfile(num: number, manifest: Profile) {
    if (! this.osDataMiners || ! this.osDataAlgos) {
      let dialogRefLoading = this.dialog.open(LoadingComponent, { panelClass: 'custom-dialog-container' })

      await Promise.all([
        this.osService.getAlgos().toPromise(),
        this.osService.getMiners().toPromise()
      ]).then((data) => {
        this.osDataAlgos = data[0]
        this.osDataMiners = data[1]
      }, (error) => {
        this.sharedModule.pushAlert(error.error.message, 'warning')
      })
      dialogRefLoading.close()
    }

    let dataToComponent = {
      osDataMiners: this.osDataMiners,
      osDataAlgos: this.osDataAlgos,
      manifest$: this.manifestsService.fetch(this.farmId, manifest.id)
    }

    let dialogRef = this.dialog.open(EditProfileComponent, {
      panelClass: 'custom-dialog-container',
      data: dataToComponent,
      maxWidth: undefined
    })

    dialogRef.afterClosed()
      .pipe(
        switchMap((data) => {
          if (data) {
            let usingOnline = Object.assign([], data.usingOnline)
            let usingOffline = Object.assign([], data.usingOffline)
            delete data.usingOnline
            delete data.usingOffline

            return this.manifestsService.patch(this.farmId, manifest.id, data)
              .pipe(
                switchMap((patchResult) => {
                  if (patchResult === 'OK') {
                    // dialogResult.id = manifest.id
                    data.usingOnline = usingOnline
                    data.usingOffline = usingOffline
                    this.sharedModule.pushAlert('Profile saved', 'success')
                  } else {
                    this.sharedModule.pushAlert(`Error while saving profile: ${patchResult}`, 'warning')
                  }
                  return of(patchResult)
                })
              )
          } else {
            return of()
          }
          // console.log ('data', data)
          return of(data)
        })
      ).toPromise()
      .catch((err) => {
        // console.log ('got error', err)
        this.sharedModule.pushAlert(`Error: ${err.statusText}`, 'warning')
      })
  }

  toggleHideNewProfile() {
    this.hideNewProfile = false
  }
}
