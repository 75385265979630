<form [formGroup]="form" (ngSubmit)="submit()">
  <h2 mat-dialog-title>{{ 'manifests.pasteJson' | translate }}</h2>
  <mat-dialog-content>
    <div class="form-control">
      <label for="json">{{ 'manifests.json' | translate }}</label>
      <app-input-validation [component]="form.get('json')"></app-input-validation>
      <textarea id="json" formControlName="json"></textarea>
    </div>
  </mat-dialog-content>
  <div class="flex-container pt-02">
    <button class="btn btn-default" mat-dialog-close>{{ 'cancel' | translate }}</button>
    <button [disabled]="!form.valid" type="submit" class="btn btn-success pinned-right mr-0">{{'save' | translate}}</button>
  </div>
</form>
