<div class="container-wide flex-container ml-0 section">
  <div class="ml-0 mt-0 p-0 sub-nav-panel-wrapper">
    <div class="nav-panel sub-nav-panel">
      <span *ngFor="let method of subMethods" class="menu-link tab"
            [ngClass]="{active: method === subMethod}" (click)="onClick(method)">
        {{'actions.' + method + '.name' | translate}}
      </span>
    </div>
  </div>
  <div class="container mt-0 w-100">
    <div class="card mt-0">
      <div class="card-content" [ngSwitch]="subMethod">

        <ng-template ngSwitchCase="commands" style="padding-bottom: 2px">
          <app-rig-actions-commands (sendCommand)=sendCommand($event) [rigId]="(rigComponent.route.params | async).id"></app-rig-actions-commands>
        </ng-template>

        <ng-template ngSwitchCase="amdBios">
          <div *ngIf="data$ | async as data">
            <app-bios-upload (biosUpload)=biosUpload($event) [data]="data" [submitted]="biosUploaded" brand="A"></app-bios-upload>
          </div>
        </ng-template>

        <ng-template ngSwitchCase="nvidiaBios">
          <div *ngIf="data$ | async as data">
            <app-bios-upload (biosUpload)=biosUpload($event) [data]="data" [submitted]="biosUploaded" brand="N"></app-bios-upload>
          </div>
        </ng-template>

      </div>
    </div>
  </div>

</div>
