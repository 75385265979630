import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '../../interfaces';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../../environments/environment';
import {ForgotPasswordComponent} from '../forgot-password/forgot-password.component';
import {map} from 'rxjs/operators';
import {SharedModule} from '../../shared.module';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit{
  form: FormGroup
  formSubmitted = false

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<LoginFormComponent>,
    public auth: AuthService,
    public sharedModule: SharedModule
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      login: ['', [
        Validators.required,
        Validators.minLength(environment.patterns.login.minLength),
        Validators.maxLength(environment.patterns.login.maxLength),
        Validators.pattern(environment.patterns.login.pattern),
        // ValidateSpecialChars
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(environment.patterns.password.minLength),
        Validators.maxLength(environment.patterns.password.maxLength),
        Validators.pattern(environment.patterns.password.pattern),
      ]],
      twofa: ['', [
        Validators.pattern(environment.patterns.twofa.pattern),
        Validators.minLength(environment.patterns.twofa.minLength),
        Validators.maxLength(environment.patterns.twofa.maxLength)
      ]],
      remember: [false]
    })
  }

  submit(): void {
    if (this.form.invalid) {
      return
    }
    this.formSubmitted = true
    const user: User = this.form.value
    if (! user.twofa) {
      delete user.twofa
    }

    this.auth.login(user)
      .pipe(
        map((data) => {
          if (! data) {
            this.sharedModule.pushAlert('Error: no data', 'warning')
            this.formSubmitted = false
          } else {
              this.dialogRef.close()
              this.router.navigate(['/monitor'])
          }
          this.formSubmitted = false
        })
      ).toPromise().catch(err => {
        // console.log ('Error', err)
        this.sharedModule.pushAlert(err.error.message, 'warning')
        this.formSubmitted = false
    })
  }

  openForgotPasswordDialog() {
    this.dialogRef.close()
    this.dialog.open(ForgotPasswordComponent, {
      panelClass: 'custom-dialog-container',
      maxWidth: undefined
    })
  }
}
