import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {SharedModule} from '../../shared.module';
import {FormControl, FormGroup} from '@angular/forms';

class Rigs {
}

@Component({
  selector: 'app-show-using-rigs',
  templateUrl: './show-using-rigs.component.html'
})
export class ShowUsingRigsComponent {
  form: FormGroup
  @Output() formSubmit = new EventEmitter()
  constructor(
    public sharedModule: SharedModule,
    // private dialogRef: MatDialogRef<ShowUsingRigsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Rigs[]
  ) { }
}
