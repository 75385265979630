import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {Autofan8mkCfg} from '../../interfaces';
import {FormBuilder, FormGroup, Validators} from '@angular/forms'

@Component({
  selector: 'app-edit-tuning-autofan8mk',
  templateUrl: './edit-tuning-autofan8mk.component.html'
})
export class EditTuningAutofan8mkComponent implements OnInit {
  @Input() data: Autofan8mkCfg
  @Output() formSubmit = new EventEmitter()
  section = 'autofan8mk'
  form: FormGroup
  simpleControls = [
    'target_temp',
    'target_mtemp',
    'manual_fan_speed',
    'min_fan',
    'max_fan'
  ]

  boolControls = [
    'fan_mode'
  ]
  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      target_temp: [50, [
          Validators.required,
          Validators.min(0),
          Validators.max(255)
        ]
      ],
      target_mtemp: [90, [
          Validators.min(0),
          Validators.max(255)
        ]
      ],
      fan_mode: [, [
        Validators.min(1),
        Validators.max(2)
      ]],
      manual_fan_speed: [50, [
        Validators.min(0),
        Validators.max(100)
      ]],
      min_fan: [0, [
        Validators.min(0),
        Validators.max(100)
      ]],
      max_fan: [100, [
        Validators.min(0),
        Validators.max(100)
      ]],
    })

    this.form.patchValue(this.data)
    this.form.get('fan_mode').setValue(this.data.fan_mode === 2)
  }

  submit() {
    let data = {
      autofan8mk: this.form.value
    }

    if (data.autofan8mk.fan_mode === true) {
      data.autofan8mk.fan_mode = 2;
    } else {
      data.autofan8mk.fan_mode = 1;
    }
    this.formSubmit.emit(data)
  }
}
