import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html'
})
export class DeleteConfirmationComponent implements OnInit {
  deleteString = 'groups.confirmDelete' // default
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      entity: string
    }
  ) { }

  ngOnInit(): void {
    if (this.data && this.data.entity) {
      this.deleteString = `${this.data.entity}.confirmDelete`
    }
  }

}
