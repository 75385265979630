<div class="container-wide">
  <div class="card ml-0 mr-0">
    <div class="card-content">
      <h2>
        {{ 'mainPage.title' | translate }}
      </h2>
      {{ 'mainPage.titleDescr' | translate }}
      <div class="flex-container mt-05 main-container">
        <div class="main-container-section">
          <p>{{ 'mainPage.text1' | translate }}</p>

          <div class="visible-xs main-container-section">
            <img class="round" src="assets/img/mockup_small.png" alt="os.dog mockup">
          </div>

          <div *ngIf="osData$ | async as data">
            <strong>{{ 'mainPage.currentOSVer' | translate }}</strong>&nbsp;<b>{{sharedModule.getValByKey(data, 'osdog').current_ver}}</b>
          </div>

          <div *ngIf="osData$ | async as data">
            <strong>{{ 'mainPage.currentISOVer' | translate }}</strong>&nbsp;<b>{{sharedModule.getValByKey(data, 'iso').current_ver | titlecase}}</b>
            <br>
            <a href="{{sharedModule.getValByKey(data, 'iso').url}}">
              <button class="btn btn-success">{{ 'download' | translate }} {{sharedModule.getValByKey(data, 'iso').current_ver | titlecase}}</button>
            </a>
          </div>
          <div>
            <button class="btn btn-success mt-02" (click)="openSignUpWindow()">
              {{ 'mainPage.signUp' | translate }}
            </button>
          </div>

          <div *ngIf="miners$ | async as miners">
            <strong>{{ 'mainPage.integratedMiners' | translate }}:</strong><br>
            <span class="note note-default" *ngFor="let miner of miners">{{miner.description}}</span>
          </div>

          <div>
            <b>{{ 'mainPage.termsOfUse' | translate }}:</b>
            <ul class="mt-02">
                <li>{{ 'mainPage.termsOfUseFree' | translate }}</li>
                <li>{{ 'mainPage.termsOfUsePaid' | translate }}</li>
<!--                <li>{{ 'mainPage.termsOfUseEvent' | translate }}</li>-->
            </ul>
          </div>
        </div>
        <div class="visible-xl pinned-right main-container-section">
          <img class="round" src="assets/img/mockup_small.png" alt="os.dog mockup">
        </div>
      </div>
      <div class="flex-container mt-05">
        <div>
          <b>{{ 'mainPage.howToStart.label' | translate }}</b>
          <ul class="mt-02">
            <li *ngFor="let i of createRange('mainPage.howToStart.stepCount' | translate)">{{'mainPage.howToStart.steps.' + i | translate}}</li>
          </ul>
        </div>



<!--          "howToStart": {-->
<!--          "label": "",-->
<!--          "stepCount": 11,-->
<!--          "steps": {-->

      </div>
    </div>
  </div>
</div>
