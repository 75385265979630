<div class="container">
  <div class="card">
    <div class="card-label">
      <h2>
        {{ 'confirmMail.title' | translate }}
      </h2>
    </div>
    <div class="card-content">
      <ng-container *ngIf="token; else appLoading">
        <ng-container *ngIf="token.length > 130; else wrongToken">
          <button class="btn btn-default" (click)="confirmMail()">{{ 'confirmMail.confirm' | translate }}</button>
        </ng-container>
        <ng-template #wrongToken>
          {{ 'confirmMail.wrongToken' | translate }}
        </ng-template>
      </ng-container>
      <ng-template #appLoading>
        <app-loading></app-loading>
      </ng-template>
    </div>
  </div>
</div>
