<div class="container-wide flex-container ml-0 section">
  <div class="ml-0 mt-0 p-0 sub-nav-panel-wrapper">
    <div class="nav-panel sub-nav-panel">
      <div *ngFor="let method of subMethods" class="menu-link tab"
        [ngClass]="{active: method === subMethod}" (click)="onClick(method)">
        {{'tuning.' + method + '.name' | translate}}
      </div>
    </div>
  </div>
  <div class="container mt-0 w-100">
    <div *ngIf="data$ | async as data; else appLoading" class="card mt-0">
      <div class="card-content" [ngSwitch]="subMethod">
        <ng-template ngSwitchCase="tuning">
          <app-edit-tuning [data]="data.tuning" (formSubmit)="tuningFormSubmit($event)"></app-edit-tuning>
        </ng-template>
        <ng-template ngSwitchCase="wdAlgo">
          <app-edit-wd-algo [data]="data.wd" (formSubmit)="formSubmit($event)"></app-edit-wd-algo>
        </ng-template>
        <ng-template ngSwitchCase="wd_temp">
          <app-edit-wd-temp [data]="data.wd_temp" (formSubmit)="formSubmit($event)"></app-edit-wd-temp>
        </ng-template>
        <ng-template ngSwitchCase="wd_load">
          <app-edit-wd-load [data]="data.wd_load" (formSubmit)="formSubmit($event)"></app-edit-wd-load>
        </ng-template>
        <ng-template ngSwitchCase="wd_la">
          <app-edit-wd-la [data]="data.wd_la" (formSubmit)="formSubmit($event)"></app-edit-wd-la>
        </ng-template>
        <ng-template ngSwitchCase="mb_af">
          <app-edit-wd-mb-af [data]="data.mb_af" (formSubmit)="formSubmit($event)"></app-edit-wd-mb-af>
        </ng-template>
        <ng-template ngSwitchCase="autofan8mk">
          <app-edit-tuning-autofan8mk [data]="data.autofan8mk" (formSubmit)="formSubmit($event)"></app-edit-tuning-autofan8mk>
        </ng-template>
        <ng-template ngSwitchCase="autofan_coolbox">
          <app-edit-tuning-autofan-coolbox [data]="data.autofan_coolbox" (formSubmit)="formSubmit($event)"></app-edit-tuning-autofan-coolbox>
        </ng-template>
        <ng-template ngSwitchCase="autofan_napalm">
          <app-edit-tuning-autofan-napalm [data]="data.autofan_napalm" (formSubmit)="formSubmit($event)"></app-edit-tuning-autofan-napalm>
        </ng-template>
      </div>
    </div>
    <ng-template #appLoading>
      <app-loading></app-loading>
    </ng-template>
  </div>

</div>
