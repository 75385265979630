import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {OsService} from '../shared/services/os.service';
import {SharedModule} from '../shared/shared.module';

@Component({
  selector: 'app-downloads-page',
  templateUrl: './downloads-page.component.html',
  styleUrls: ['./downloads-page.component.scss']
})
export class DownloadsPageComponent implements OnInit {
  isoArr$: Observable<any>
  constructor(
    public sharedModule: SharedModule,
    private osService: OsService
  ) { }

  ngOnInit(): void {
    this.isoArr$ = this.osService.getIso()
  }

}
