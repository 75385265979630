import { Component, OnInit } from '@angular/core';
import {SharedModule} from '../../shared.module';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html'
})
export class LoadingComponent {
  constructor(
    public sharedModule: SharedModule
  ) { }
}
