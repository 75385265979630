import { Component, OnInit } from '@angular/core';
import {GroupsService} from '../shared/services/groups.service';
import {Group} from '../shared/interfaces';
import {SharedModule} from '../shared/shared.module';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-groups-page',
  templateUrl: './groups-page.component.html',
  styleUrls: ['./groups-page.component.scss']
})
export class GroupsPageComponent implements OnInit {
  groups: Group[]
  invitations: any[]
  loading = true
  action = false
  subMethod = 'rigs'

  constructor(
    private groupsService: GroupsService,
    public sharedModule: SharedModule,
    private dialog: MatDialog,
    private router: Router
  ) { }

  async fetchData() {
    this.groups = await this.groupsService.get('medium').toPromise()
    this.loading = false
    this.groups.forEach((f) => {
      f.rigs$ = this.groupsService.getRigs(f.id, 'short')
    })
    this.invitations = await this.groupsService.getInvitations().toPromise()
  }

  async ngOnInit() {
    this.sharedModule.setTitle('links.groups', true)
    this.fetchData()
  }

  openGroup(id: number) {
    void this.router.navigate(['/groups', id])
  }

  declineInvitation(groupId: number) {
    if (! this.action) {
      this.action = true
      // console.log('declineInvitation', groupId)
      this.groupsService.declineInvitation(groupId)
        .pipe(
          map((result) => {
            if (!result) {
              this.sharedModule.pushAlert('Invitation declined', 'success')
            } else { // don`t know what should be to trigger that
              this.sharedModule.pushAlert(result, 'warning')
            }
            this.fetchData()
            this.action = false
          })
        ).toPromise().catch((error) => {
        this.sharedModule.pushAlert(`Error: ${error.error.message}`, 'warning')
      })
    }
  }

  acceptInvitation(groupId: number) {
    if (! this.action) {
      this.action = true
      this.groupsService.acceptInvitation(groupId)
        .pipe(
          map((result) => {
            // console.log('result', result)
            if (result === 'OK') {
              this.sharedModule.pushAlert('Invitation accepted', 'success')
            } else { // don`t know what should be to trigger that
              this.sharedModule.pushAlert(result, 'warning')
            }
            this.fetchData()
            this.action = false
          })
        ).toPromise().catch((error) => {
          // console.log('error', error)
          this.sharedModule.pushAlert(`Error: ${error.error.message}`, 'warning')
      })
    }
  }
}
