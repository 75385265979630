<div class="container">
  <div class="card">
    <div *ngIf="rig$ | async as rig; else appLoading" class="card-content">
      <app-edit-rig [rig]="rig" (formSubmit)="formSubmit($event)"></app-edit-rig>
    </div>

    <ng-template #appLoading>
      <app-loading></app-loading>
    </ng-template>
  </div>
</div>
