import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Currency} from '../../../shared/interfaces';
import {Observable, Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FilterRigsPipe} from '../../../shared/pipes/filter-rigs';
import {SharedModule} from '../../../shared/shared.module';
import {GroupsService} from '../../../shared/services/groups.service';

@Component({
  selector: 'app-group-finances',
  templateUrl: './group-finances.component.html',
  styleUrls: ['./group-finances.component.scss']
})
export class GroupFinancesComponent implements OnInit {
  @Input() groupId: number
  searchStr = ''
  form: FormGroup
  selectedCheckBoxes = []
  checkAll = false
  subs: Subscription[] = []
  data$: Observable<any>
  currencies$: Observable<Currency[]>
  paymentButtonPushed = false
  constructor(
    private fb: FormBuilder,
    private filterRigs: FilterRigsPipe,
    private changeDetection: ChangeDetectorRef,
    private groupsService: GroupsService,
    public sharedModule: SharedModule
  ) { }

  ngOnInit(): void {
    this.data$ = this.groupsService.getGroup(+this.groupId, 'finances')

    this.currencies$ = this.groupsService.getCurrencies(+this.groupId)

    this.form = this.fb.group({
      currency: ['', [Validators.required, Validators.minLength(1)]],
      amount: ['', [Validators.required, Validators.min(1)]]
    })
  }

  async getPaymentLink() {
    this.paymentButtonPushed = true
    let form = this.form.value
    let data = {
      currency: form.currency,
      amount: form.amount
    }
    await this.groupsService.getPaymentLink(this.groupId, data).pipe(
      ).toPromise()
      .then((result) => {
          console.log(result);
          this.sharedModule.pushAlert('OK', 'success')
          window.open(result.url, '_blank'); // in new tab
      },
      (err) => {
        this.sharedModule.pushAlert(`Error: ${err.statusText}`, 'warning')
      })
  }
}
