<div class="container vertical mb-0">
  <div *ngFor="let command of popularCommands" class="div-command flex-container">
    <div class="command-name">
      <button class="btn btn-default btn-command" [disabled]="commandApplying" (click)="rigCommand(command.command, command.args)">
        {{command.name}}
      </button>
    </div>
    <div class="command-description">{{ 'actions.commands.keys.' + command.id | translate }}</div>
    <div class="visible-xs">
      <hr>
    </div>
  </div>


  <div class="div-command flex-container">
    <div class="command-name">
      <button class="btn btn-default btn-command" [disabled]="commandApplying" (click)="shutdown()">
        sreboot shutdown
      </button>
    </div>
    <div class="command-description">{{ 'actions.commands.keys.srebootShutdown' | translate }}</div>
    <div class="visible-xs">
      <hr>
    </div>
  </div>


</div>
<form [formGroup]="form" (ngSubmit)="applyCustomCommand()">
  <div class="form-control w-100 mb-0" style="padding-bottom: 0">
    <label for="customCommand">{{ 'actions.commands.keys.customCommand' | translate }}</label>
    <textarea id="customCommand" formControlName="customCommand" type="text" rows="3"></textarea>
  </div>
  <div class="form-control w-100 flex-container">
    <button [disabled]="commandApplying" class="btn btn-default btn-command pinned-right mr-0">{{ 'actions.commands.keys.customCommandApply' | translate }}</button>
  </div>
</form>
