import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTabsModule} from '@angular/material/tabs';
import {ClipboardModule} from '@angular/cdk/clipboard';

const modules = [
  BrowserAnimationsModule,
  MatDialogModule,
  MatFormFieldModule,
  MatSlideToggleModule,
  // MatProgressSpinnerModule,
  MatMenuModule,
  ClipboardModule
]

@NgModule({
  imports: [
    ...modules,
    MatTabsModule
  ],
  exports: [
    ...modules,
    MatTabsModule
  ],

  providers: [],
})
export class MaterialModule { }
