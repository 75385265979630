import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SharedModule} from '../../shared.module';
import {ValidateFilesize} from '../../validators/filesize.validator';

@Component({
  selector: 'app-bios-upload',
  templateUrl: './bios-upload.component.html'
})
export class BiosUploadComponent implements OnInit {
  @Input() data: any
  @Input() brand: 'N'|'A'
  @Input() submitted: boolean
  @Output() biosUpload = new EventEmitter()
  brandName: string
  gpuArray = []
  form: FormGroup
  fileName = ''

  constructor(
    private fb: FormBuilder,
    public sharedModule: SharedModule
  ) { }

  ngOnInit(): void {
    if (this.brand === 'N') {
      this.brandName = 'Nvidia'
    } else if (this.brand === 'A') {
      this.brandName = 'AMD'
    }
    this.form = this.fb.group({
      file: ['',
        [ Validators.required, ValidateFilesize(2550000, 120000) ]
      ],
      gpu: ['',
        // [Validators.required]
      ],
      mode: ['single'],
      ignoreMismatch: [false],
      disableServices: [false]
    })
    if (this.data.gpu_brand && this.data.gpu_brand.length > 0) {
      this.data.gpu_brand.forEach((el, index) => {
        if (el === this.brand) {
          let item = {
            bus: this.data.gpu_busid[index],
            vendor: this.data.gpu_vendor[index],
            name: this.data.gpu_name[index]
          }
          this.gpuArray.push(item)
        }
      })
    }
    // console.log(this.gpuArray)
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    if (file) {
      this.form.get('file').markAsTouched()
      this.form.get('file').patchValue(file)
      this.fileName = file.name;
    }
  }

  submit() {
    const form = this.form.value

    let formData = new FormData()
    formData.append('thumbnail', form.file)
    formData.append('mode', form.mode)
    formData.append('brand', this.brandName.toLowerCase())
    formData.append('command', `Flash ${this.brandName} BIOS`)
    if (this.form.value.mode === 'single') {
      formData.append('gpu', form.gpu)
    }
    if (this.brand === 'N') {
      formData.append('ignoreMismatch', form.ignoreMismatch)
    } else if (this.brand === 'A') {
      formData.append('disableServices', form.disableServices)
    }
    this.biosUpload.emit(formData)
  }
}
