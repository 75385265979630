<!--{{profiles | json}}-->
<ng-container *ngFor="let profile of profiles">
<!--  ; let i = index-->

<!--  {{osDataMiners | json}}-->
  <app-manifest-row *ngIf="profile.id"
    [manifest]="profile"
    [loading]="loading"
    [osDataMiners]="osDataMiners"
    [controls]="true" (controlClicked)="controlOnClick($event, profile.num)"
    (click)="openSelect(profile.num)"></app-manifest-row>
  <app-manifest-row *ngIf="!profile.id" [osDataMiners]="osDataMiners" [loading]="loading" addNew="true" (click)="openSelect(profile.num)">
  </app-manifest-row>
</ng-container>
<button class="btn btn-default" *ngIf="! haveSockets() && hideNewProfile && (profiles[profiles.length - 1] || profiles.length === 0)" (click)="toggleHideNewProfile()">
  <fa-icon [icon]="sharedModule.faPlusSquare" class="active-element"></fa-icon>
</button>
<app-manifest-row *ngIf="!hideNewProfile && (profiles[profiles.length - 1] || profiles.length === 0)"
  [loading]="loading" [osDataMiners]="osDataMiners" addNew="true" (click)="openSelect(getNewIndex())"></app-manifest-row>
<!--<button class="btn" (click)="getNewIndex()">getNewIndex</button>-->
<!--<ng-template #loadingDiv>-->
<!--  <app-loading></app-loading>-->
<!--</ng-template>-->
