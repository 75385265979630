<ng-container  *ngFor="let i of managedProfiles">
  <div class="flex-container align-center">
    <div class="mr-02">
      {{'applyMultiProfiles.profile' | translate}} {{i}}
    </div>
    <div class="action-button note-gray" (click)="openSelect(i)">
      <fa-icon [icon]="this.sharedModule.faSearch" class="active-element" title="{{'applyMultiProfiles.selectProfile' | translate}}"
      ></fa-icon>
    </div>
<!--    <div class="action-button note-gray" (click)="restartProfile(i)">-->
<!--      <fa-icon [icon]="this.sharedModule.faRedo" class="active-element" title="{{'applyMultiProfiles.restartProfile' | translate}}"-->
<!--      ></fa-icon>-->
<!--    </div>-->
    <div class="action-button note-gray" (click)="stopProfile(i)">
      <fa-icon [icon]="this.sharedModule.faTimes" class="active-element" title="{{'applyMultiProfiles.stopProfile' | translate}}"
      ></fa-icon>
    </div>
  </div>
  <hr *ngIf="i < managedProfiles.length">
</ng-container>
<i>{{'applyMultiProfiles.description' | translate}}</i>
