<div class="panel header">
  <ng-container *ngIf="this.authService.isAuthenticated(); else noAuth">
    <input id="menu-toggle" type="checkbox" [(ngModel)]="menuState">
    <label class="menu-btn" for="menu-toggle">
      <span></span>
    </label>
  </ng-container>
  <div class="navbar">
    <div *ngIf="this.authService.isAuthenticated(); else noAuth" >
      <div class="menu-box flex-container">
        <span>
          <a routerLink="/monitor" routerLinkActive="active" class="menu-link flex-container">
            <div class="icon-wrapper">
              <fa-icon [icon]="this.sharedModule.faTv" ></fa-icon>
            </div>
            <div class="ml-02">
              {{ 'links.monitor'| translate }}
            </div>
          </a>
        </span>
        <span>
          <a routerLink="/groups" [class.active]="router.url  === '/groups'" class="menu-link flex-container">
            <div class="icon-wrapper">
              <fa-icon [icon]="this.sharedModule.faSitemap" ></fa-icon>
            </div>
            <div class="ml-02">
              {{ 'links.groups'| translate }}
            </div>
          </a>
        </span>
        <span>
          <a routerLink="/profiles" routerLinkActive="active" class="menu-link flex-container">
            <div class="icon-wrapper">
              <fa-icon [icon]="this.sharedModule.faToolbox"></fa-icon>
            </div>
            <div class="ml-02">
              {{ 'links.profiles'| translate }}
            </div>
          </a>
        </span>
        <span>
          <a routerLink="/oc" routerLinkActive="active" class="menu-link flex-container">
            <div class="icon-wrapper">
              <fa-icon [icon]="this.sharedModule.faFan"></fa-icon>
            </div>
            <div class="ml-02">
              {{ 'links.oc'| translate }}
            </div>
          </a>
        </span>
        <span class="flex-container">
          <div>
            <a [routerLink]="['/account']" routerLinkActive="active" class="menu-link flex-container mr-0">
              <div class="icon-wrapper">
                <fa-icon [icon]="this.sharedModule.faUser"></fa-icon>
              </div>
              <div class="ml-02">
                {{ this.authService.loginName }}

              </div>
            </a>
          </div>
          <div>
            <fa-icon class="menu-link" [icon]="this.sharedModule.faSignOut" (click)="logout($event)"></fa-icon>
          </div>
        </span>
      </div>
    </div>
    <ng-template #noAuth>
      <button class="btn btn-default" id="login-button" (click)="openDialog()">{{ 'login.loginTitle' | translate }}</button>
    </ng-template>
  </div>
  <div class="pinned-right">
    <div class="flex-container align-center pinned-right mr-0">
      <a [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class="menu-link visible-xl">OSdog</a>
      <a [routerLink]="['/']" class="pinned-right"><img [src]="imgUrl" alt="os.dog"></a>
    </div>
  </div>
</div>
<div class="margin"></div>
<app-control-panel></app-control-panel>
<router-outlet></router-outlet>

<div class="panel footer">
  <a [routerLink]="['/changelog']" routerLinkActive="active" class="menu-link">
    <fa-icon [icon]="this.sharedModule.faCodeBranch"
      title="{{ 'links.changelog' | translate }}"
    ></fa-icon>
    <span class="visible-xl">
      {{ 'links.changelog' | translate }}
  </span>
  </a>
  <a [routerLink]="['/downloads']" routerLinkActive="active" class="menu-link">
    <fa-icon [icon]="this.sharedModule.faCloudDownloadAlt"
      title="{{ 'links.downloads' | translate }}"
    ></fa-icon>
    <span class="visible-xl">
      {{ 'links.downloads' | translate }}
  </span>
  </a>
  <a href="{{ chatlink }}" class="menu-link">
    <fa-icon [icon]="this.sharedModule.faTelegram"
      title="{{ 'links.support' | translate }}"
    ></fa-icon>
    <span class="visible-xl">
      {{ 'links.support' | translate }}
    </span>
  </a>

  <div class="pinned-right">
    <app-language-switcher></app-language-switcher>
    <app-theme-menu
      [options]="themeOptions$ | async"
      (themeChange)="themeChangeHandler($event)"
    ></app-theme-menu>
  </div>
</div>
<app-alerts></app-alerts>
