<div class="container-wide">
  <div *ngIf="rig; else appLoading">
    <div class="flex-container" id="neighboursNavigation">
      <div>
        <ng-container *ngIf="(groupRigs$ | async) as groupRigs; else prev">
            <a [routerLink]="['/rig', neighbour.id]" *ngIf="groupRigs && getPrevNeighbour(rig.id, groupRigs) as neighbour"
              title="{{'rig.switchToPrevRig' | translate}}"
            >
              <span class="note note-gray"><fa-icon [icon]="this.sharedModule.faAngleLeft"></fa-icon></span>
              {{neighbour.name}}
            </a>

        </ng-container>
        <ng-template #prev>
          <fa-icon [icon]="this.sharedModule.faAngleLeft"></fa-icon>
        </ng-template>
      </div>
      <div class="pinned-right">
        <ng-container *ngIf="(groupRigs$ | async) as groupRigs; else next">
          <a [routerLink]="['/rig', neighbour.id]" *ngIf="groupRigs && getNextNeighbour(rig.id, groupRigs) as neighbour"
             title="{{'rig.switchToNextRig' | translate}}"
          >
            {{neighbour.name}}
            <span class="note note-gray"><fa-icon [icon]="this.sharedModule.faAngleRight"></fa-icon></span>
          </a>
        </ng-container>
        <ng-template #next>
          <fa-icon [icon]="this.sharedModule.faAngleRight"></fa-icon>
        </ng-template>
      </div>
    </div>

    <div class="flex-container">
      <div>
        <h4>
          <a class="mr-02" [routerLink]="['/groups', rig.owner]">{{rig.ownerName}}</a>
          <fa-icon [icon]="this.sharedModule.faAngleRight"></fa-icon>
          {{rig.name}}

          <fa-icon [icon]="this.sharedModule.faBan" *ngIf="!rig.active"
             title="{{ 'rig.nonActiveDescr' | translate }}"
          ></fa-icon>
        </h4>
        <i [innerText]="rig.comment"></i>
      </div>

      <div class="pinned-right flex-container vertical" style="align-items: flex-end">
        <span class="note note-success" *ngIf="!rig.offline">
          {{ rig.online | dateAgo:currentTime }}
        </span>
        <span *ngIf="rig.offline" class="note note-danger">
          {{ rig.refresh | dateAgo:currentTime }}
        </span>
        <div *ngIf="rig.totalPower">
          <fa-icon [icon]="this.sharedModule.faBolt"></fa-icon>
          {{rig.totalPower}}
        </div>
      </div>
    </div>

    <div *ngIf="rig.messages.length > 0">
      <b>{{ 'messages.messages' | translate }} ({{rig.messages.length - deletedMessages.length}})</b>
      <div class="flex-container align-center">
        <ng-container *ngFor="let msg of rig.messages">
          <div *ngIf="! deletedMessages.includes(msg.id)" class="note active-element" [ngClass]="msg.type">
            <fa-icon *ngIf="msg.type === 'note-file'" [icon]="this.sharedModule.faFile" class="active-element mr-02"
              (click)="getMessage(rig.id, msg.id)"
            ></fa-icon>
            <i (click)="getMessage(rig.id, msg.id)" class="mr-05">{{msg.date | date: 'dd.MM.yy HH:mm:ss'}}</i>
            <b (click)="getMessage(rig.id, msg.id)" class="mr-05">{{msg.title}}</b>
            <fa-icon [icon]="this.sharedModule.faTimes" class="active-element"
              (click)="deleteMessage(rig.id, msg.id)"
              title="{{ 'messages.delete' | translate }}"
            ></fa-icon>
          </div>
        </ng-container>
        <div><fa-icon [icon]="this.sharedModule.faTrash" class="active-element ml-02"
          (click)="deleteAllMessages(rig.messages)"
          title="{{ 'messages.deleteAll' | translate }}"
        ></fa-icon></div>
      </div>
      <div class="flex-container">
        <ng-container *ngFor="let msg of fullMessages">
<!--          <ng-container *ngIf="message.data | async as msg">-->
            <div *ngIf="! deletedMessages.includes(msg.id)" class="card mr-02 w-100">
              <div class="card-label flex-container">
                <div class="mr-02"><b class="mr-02">{{msg.title}}</b>{{msg.date | date: 'dd.MM.yy HH:mm:ss'}}</div>
                <div class="pinned-right">
                  <fa-icon [icon]="this.sharedModule.faTimes" class="active-element"
                     (click)="closeFullMessage(msg.id)"
                  ></fa-icon>
                </div>
              </div>
              <div class="msg-container card-content">
                {{msg.date | date: 'dd.MM.yy HH:mm:ss'}}
                <pre [innerHTML]="msg.data "></pre>
              </div>
            </div>
<!--          </ng-container>-->
        </ng-container>
      </div>
    </div>

    <div *ngIf="rig.commands.length > 0">
      <b>{{ 'rig.commands' | translate }} ({{rig.commands.length}})</b>
      <div class="flex-container align-center">
        <ng-container *ngFor="let command of rig.commands">
<!--                    {{command | json}}-->
          <div class="note note-gray active-element fit-screen">
            <ng-template [ngIf]="command.title">
              <b>{{command.title}}</b>
            </ng-template>
            <ng-template [ngIf]="! command.title">
              <b>{{command.type}} {{command.argument}}</b>
            </ng-template>
          </div>
        </ng-container>
        <div><fa-icon [icon]="this.sharedModule.faTrash" class="active-element ml-02"
                      (click)="deleteAllCommands()"
                      title="{{ 'rig.deleteAllCommands' | translate }}"
        ></fa-icon></div>
      </div>
    </div>

<!--    <hr>-->
    <div class="flex-container nav-panel">
      <a class="menu-link tab" [routerLink]="['stats']" [class.active]="router.url  === '/rig/' + rig.id ">
        <fa-icon [icon]="this.sharedModule.faHome"
          title="{{ 'rig.stats' | translate}}"
        ></fa-icon>
        <span class="visible-xl ml-02">{{ 'rig.stats' | translate }}</span>
      </a>

      <a class="menu-link tab" [routerLink]="['graph']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
        <fa-icon [icon]="this.sharedModule.faChartBar" title="{{ 'rig.graph' | translate}}"></fa-icon>
        <span class="visible-xl ml-02">{{ 'rig.graph' | translate }}</span>
      </a>

      <a class="menu-link tab" [routerLink]="['settings']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
        <fa-icon [icon]="this.sharedModule.faCog" title="{{ 'rig.settings' | translate}}"></fa-icon>
        <span class="visible-xl ml-02">{{ 'rig.settings' | translate }}</span>
      </a>

      <a class="menu-link tab" [routerLink]="['oc']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
        <fa-icon [icon]="this.sharedModule.faSlidersH" title="{{ 'rig.oc' | translate}}"></fa-icon>
        <span class="visible-xl ml-02">{{ 'rig.oc' | translate }}</span>
      </a>

      <a class="menu-link tab" [routerLink]="['tuning']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
        <fa-icon [icon]="this.sharedModule.faTools" title="{{ 'rig.tuning' | translate}}"></fa-icon>
        <span class="visible-xl ml-02">{{ 'rig.tuning' | translate }}</span>
      </a>

      <a class="menu-link tab" [routerLink]="['connections']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
        <fa-icon [icon]="this.sharedModule.faWifi" title="{{ 'rig.connections' | translate}}"></fa-icon>
        <span class="visible-xl ml-02">{{ 'rig.connections' | translate }}</span>
      </a>

      <a class="menu-link tab" [routerLink]="['actions']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
        <fa-icon [icon]="this.sharedModule.faTerminal" title="{{ 'rig.actions' | translate}}"></fa-icon>
        <span class="visible-xl ml-02">{{ 'rig.actions' | translate }}</span>
      </a>
    </div>
<!--    <hr>-->
    <router-outlet></router-outlet>
  </div>
  <ng-template #appLoading>
    <app-loading *ngIf="!error; else error"></app-loading>
    <ng-template #error>
      <div class="flex-container center">
        <div>
          <div>{{ 'error' | translate }}: {{errorText}}</div>
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>
