import {Pipe, PipeTransform} from '@angular/core';
import {Rig} from '../interfaces';

@Pipe({
  name: 'filterRigs'
})
export class FilterRigsPipe implements PipeTransform {
  transform(rigs: Rig[], search = ''): Rig[] {
    if (!search.trim()) {
      return rigs
    }

    return rigs.filter(rig => {
      // console.log (rig)
      if (rig.name.toLowerCase().includes(search.toLowerCase())) {
        return true
      } else if (rig.comment.toLowerCase().includes(search.toLowerCase())) {
        return true
      }
      return false
    })
  }
}

// return profiles.filter(profile => {
//   if (profile.miner.toLowerCase().includes(search.toLowerCase())) {
//     return true
//   } else if (profile.name.toLowerCase().includes(search.toLowerCase())) {
//     return true
//   } else if (profile.algo.toLowerCase().includes(search.toLowerCase())) {
//     return true
//   } else if (profile.wallet.toLowerCase().includes(search.toLowerCase())) {
//     return true
//   } else if (profile.pool.toLowerCase().includes(search.toLowerCase())) {
//     return true
//   }
//   return false
// })
// }
