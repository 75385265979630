import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core'
import {GroupsService} from '../shared/services/groups.service'
import {CheckForm, Group, MessagesContainer, OsDataMiner} from '../shared/interfaces'
import {SharedModule} from '../shared/shared.module'
import {LocalStorageService} from '../shared/services/local-storage.service'
import {DataPreparationService} from '../shared/services/data-preparation.service'
import { environment } from 'src/environments/environment'
import {FormBuilder} from '@angular/forms'
import {ControlPanelService} from '../shared/services/control-panel.service'
import {OsService} from '../shared/services/os.service'

@Component({
  selector: 'app-monitor-page',
  templateUrl: './monitor-page.component.html',
  styleUrls: ['./monitor-page.component.scss']
})

export class MonitorPageComponent implements OnInit, OnDestroy {
  refreshTime = environment.refreshTime
  viewMode: string
  tempLimit: number
  groups: Group[] = []
  checkForms: CheckForm[] = []
  messages: MessagesContainer[] = []
  hiddenFarms: Group[]
  hiddenFarmsId: number[]
  loading = true
  currentTime: number
  interval: number
  osDataMiners: OsDataMiner[]

  constructor(
    private farmsService: GroupsService,
    public sharedModule: SharedModule,
    public localStorageService: LocalStorageService,
    private dataPreparationService: DataPreparationService,
    private fb: FormBuilder,
    private changeDetection: ChangeDetectorRef,
    private controlPanel: ControlPanelService,
    private osService: OsService
  ) {}

  async ngOnInit() {
    this.osDataMiners = await this.osService.getMiners().toPromise()
    this.sharedModule.setTitle('links.monitor', true)
    this.viewMode = this.localStorageService.viewMode
    this.tempLimit = this.localStorageService.tempLimit
    this.fetchData()
    this.interval = setInterval(() => {
      this.fetchData()
    }, this.refreshTime)
    this.controlPanel.show()
    this.controlPanel.monitorCommands$.subscribe((data) => {
      const command = data.command
      if (command === 'fetchData') {
        this.fetchData()
      } else if (command === 'viewMode') {
        this.viewMode = this.localStorageService.viewMode
      } else if (command === 'tempLimit') {
        this.tempLimit = this.localStorageService.tempLimit
      } else if (command === 'deleteMessages') {
        const args = data.arguments
        const groupIndex = this.groups.findIndex(el => el.id === args.groupId)
        if (groupIndex === -1) {
          return
        }

        const rigIds: number[] = args.rigIds
        rigIds.forEach(rigId => {
          const rigIndex = this.groups[groupIndex].rigs.findIndex(el => el.id === rigId)
          if (groupIndex !== -1) {
            this.groups[groupIndex].rigs[rigIndex].msg = []
          }
        })
      }
    })
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval)
    }
    this.controlPanel.hide()
    this.controlPanel.monitorCommands$.unsubscribe()
  }

  fetchData(): any {
    let firstSub = this.farmsService.get('short').subscribe((data) => {
      // console.log ('fetchData', data)
      this.hiddenFarmsId = this.localStorageService.hiddenFarms
      this.hiddenFarms = data.filter(el => this.hiddenFarmsId.some(id => id === el.id))

      // Check if we got something old in our local storage
      this.hiddenFarmsId.forEach((hidedFarm: number) => {
        let index = data.findIndex(el => el.id === hidedFarm)
        if (index === -1) {
          this.localStorageService.unsetHiddenFarm(hidedFarm)
          this.hiddenFarms.splice(index, 1)
        }
      })

      this.groups = this.groups.filter(farm => data.findIndex(el => el.id === farm.id) > -1)
      data = data.filter(farm => !this.hiddenFarmsId.some(id => {
        return farm.id === id
      }))
      let loadedData = 0
      data.forEach((group, index) => {
        let farmSub = this.dataPreparationService.getFarmRigsData(group.id).subscribe((farmData) => {
          this.groups[index] = {
            ...group,
            ...farmData
          }
          this.fillCheckForm(group.id, farmData.rigs.map(el => el.id))

          loadedData++
          if (loadedData === data.length) {
            this.loading = false
          }
          farmSub.unsubscribe()
        })
      })
      //
      this.currentTime = Math.floor(Date.now() / 1000)
      firstSub.unsubscribe()
    })
  }

  hideGroup(id: number) {
    if (this.hiddenFarms.findIndex(el => el.id === id) === -1) {
      const name = this.groups.find(farm => farm.id === id).name
      this.localStorageService.setHiddenFarm(id)
      let checkForm = this.getCheckForm(id)
      if (checkForm && checkForm.selectedCheckBoxes.length > 0) {
        this.controlPanel.reset()
      }

      this.groups = this.groups.filter(farm => +farm.id !== +id)
      this.hiddenFarms.push({id, name})
      this.fillCheckForm(id, [])
    }
  }

  showFarm(id: number) {
    let sub = this.dataPreparationService.getFarmRigsData(id).subscribe((data) => {
      let farm = {
        id,
        name: this.hiddenFarms.find(el => el.id === id).name,
        ...data
      }
      this.fillCheckForm(id, data.rigs.map(el => el.id))
      this.groups.push(farm)

      this.groups.sort((f1, f2) => {
        const name1 = f1.name.toLowerCase();
        const name2 = f2.name.toLowerCase();
        if (name1 > name2) { return 1; }
        if (name1 < name2) { return -1; }
        return 0;
      })

      this.localStorageService.unsetHiddenFarm(id)

      const index = this.hiddenFarms.findIndex(el => el.id === id)
      this.hiddenFarms.splice(index, 1)
      sub.unsubscribe()
    })
  }

  fillCheckForm(groupId: number, rigs: number[]) {
    const index = this.checkForms.findIndex(el => el.id === groupId)

    if (index === -1 && rigs.length > 0) {
      let form = {
        id: groupId,
        active: false,
        form: this.fb.group({}),
        checkAll: false,
        selectedCheckBoxes: []
      }

      rigs.forEach((rigId) => {
        form.form.addControl(String(rigId), this.fb.control(false))
      })
      this.checkForms.push(form)
    } else if (index > -1 && rigs.length === 0) {
      this.checkForms.splice(index, 1)
    }
  }

  getCheckForm(groupId): CheckForm {
    const index = this.checkForms.findIndex(el => el.id === groupId)
    if (index !== -1 ) {
      return this.checkForms[index]
    }
  }

  onChangeCheckBox(groupId: number, groupName: string) {
    this.checkForms.forEach((checkForm) => {
      if (checkForm.id === groupId) {
        checkForm.selectedCheckBoxes = Object.keys(checkForm.form.value).filter((id) => {
          return (checkForm.form.value[id] === true)
        }).map((id) => +id)

        // working
        // checkForm.checkAll = true
        // if (checkForm.selectedCheckBoxes.length < Object.keys(checkForm.form.value).length) {
        //   checkForm.checkAll = false
        // }

        checkForm.checkAll = checkForm.selectedCheckBoxes.length >= Object.keys(checkForm.form.value).length

        if (checkForm.selectedCheckBoxes.length > 0) {
          this.controlPanel.groupId = groupId
          this.controlPanel.groupName = groupName
          this.controlPanel.rigIds = checkForm.selectedCheckBoxes
        } else {
          this.controlPanel.reset()
        }

      } else { // other checkForms
        checkForm.form.reset()
        checkForm.checkAll = false
        checkForm.selectedCheckBoxes = []

      }
    })
    this.changeDetection.detectChanges()

  }

  onCheckAll(groupId: number, groupName: string) {
    let checkForm = this.getCheckForm(groupId)
    if (checkForm !== -1) {
      checkForm.checkAll = !checkForm.checkAll
      Object.keys(checkForm.form.value).forEach((id) => {
        checkForm.form.get(id).setValue(false)
      })
      if (checkForm.checkAll === true) {
        Object.keys(checkForm.form.value).forEach((rigId) => {
          checkForm.form.get(rigId).setValue(true)
        })
      }
      this.onChangeCheckBox(groupId, groupName)
    }
  }
}
