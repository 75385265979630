import {Component, Input, OnInit} from '@angular/core'
import {Autofan8mkData} from '../../interfaces'
import {SharedModule} from '../../shared.module'
import {LocalStorageService} from '../../services/local-storage.service'

@Component({
  selector: 'app-autofan-hardware',
  templateUrl: './autofan-hardware.component.html',
  styleUrls: ['./autofan-hardware.component.scss']
})
export class AutofanHardwareComponent implements OnInit {
  @Input() data: Autofan8mkData
  @Input() device: string

  autofanHardwareShowZeroFan: boolean

  constructor(
    private localStorageService: LocalStorageService,
    public sharedModule: SharedModule
  ) { }

  ngOnInit(): void {
    this.autofanHardwareShowZeroFan = this.localStorageService.autofanHardwareShowZeroFan
  }

  toggleAutofanHardwareShowZeroFan() {
    this.autofanHardwareShowZeroFan = !this.autofanHardwareShowZeroFan
    this.localStorageService.setAutofanHardwareShowZeroFan(this.autofanHardwareShowZeroFan)
  }

}
