import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'displayFilesize'
})
export class DisplayFilesizePipe implements PipeTransform {
  constructor(
    private translateService: TranslateService
  ) {}

  private order = [
    'b',
    'kb',
    'mb',
    'gb'
  ]

  transform(rawSize: number, showMb = false, exact =  false): string {
    let hrString: string
    let i = 0
    let hr = 0
    let unit: string

    this.order.forEach(elem => {
      if (rawSize / 1024 ** i >= 1) {
        hr = rawSize / 1024 ** i
        unit = elem
      }
      i++
    })

    if (exact) {
      hrString = hr.toFixed(2)
    } else {
      hrString = hr.toFixed(0)
    }

    if (hr > 0 && showMb) {
      // console.log (unit)
      this.translateService.get([`sizeUnits.${unit}`]).subscribe(translation => {
        hrString += ' ' + translation[`sizeUnits.${unit}`]
        return hrString
        }
      )
    }

    return hrString
  }
}
