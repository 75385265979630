import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RigCommand} from '../../interfaces';
import {AlertsService} from '../../services/alerts.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {RigsService} from '../../services/rigs.service';
import {DeleteConfirmationComponent} from '../delete-confirmation/delete-confirmation.component';
import {map, switchMap} from 'rxjs/operators';
import {EMPTY, Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
// import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-rig-actions-commands',
  templateUrl: './rig-actions-commands.component.html',
  styleUrls: ['./rig-actions-commands.component.scss']
})
export class RigActionsCommandsComponent implements OnInit {
  @Output() sendCommand = new EventEmitter()

  @Input() rigId: number
  form: FormGroup
  commandApplying = false
  popularCommands = [
    {id: 'upgrade', name: 'upgrade', command: 'upgrade'},
    {id: 'nvidiaSmi', name: 'nvidia-smi', command: 'exec', args: 'nvidia-smi'},
    {id: 'amdInfo', name: 'amd-info', command: 'exec', args: 'amd-info'},
    {id: 'hello', name: 'hello', command: 'exec', args: 'hello'},
    {id: 'sreboot', name: 'sreboot', command: 'reboot'},
    {id: 'srebootWakealarm', name: 'sreboot wakealarm', command: 'reboot', args: 'wakealarm'},
    {id: 'miner0Log', name: 'miner log', command: 'exec', args: 'miner log'},
    {id: 'miner1Log', name: 'miner log 1', command: 'exec', args: 'miner log 1'},
    // {id: 'benchmarkLog', name: 'benchmark log', command: 'benchmark', args: 'log'},
    {id: 'wdLog', name: 'wd log', command: 'exec', args: 'wd log'},
    {id: 'afLog', name: 'af log', command: 'exec', args: 'af log'},
    // {id: 'aswLog', name: 'asw log', command: 'autoswitch', args: 'log'},
    {id: 'wdTempLog', name: 'wd-temp log', command: 'exec', args: 'wd-temp log'},
    {id: 'wdLaLog', name: 'wd-la log', command: 'exec', args: 'wd-la log'},
    {id: 'diskExpand', name: 'disk-expand', command: 'exec', args: 'disk-expand'},
    {id: 'dogToolFixTime', name: 'dog-tool fix-time', command: 'exec', args: 'dog-tool fix-time'},
    {id: 'dogToolFixRo', name: 'dog-tool fix-ro', command: 'exec', args: 'dog-tool fix-ro'},

  ]

  // shutdownCommand = {id: 'srebootShutdown', name: 'sreboot shutdown', command: 'exec', args: 'sreboot shutdown'}

  constructor(
    private rigsService: RigsService,
    private alertsService: AlertsService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      customCommand: ['']
    })
  }

  rigCommand(command: string, args?: string) {
    let data: RigCommand = {
      command, args
    }
    this.sendCommand.emit(data)

    // if (this.rigId) {
    //   this.commandApplying = true
    //   let commandData: RigCommand = {
    //     command, args
    //   }
    //   console.log (commandData)
    //   let sub = this.rigsService.pushCommand(this.rigId, commandData)
    //     .subscribe(() => {
    //         let alert: Alert = {
    //           type: 'success',
    //           text: 'Command applied'
    //         }
    //         this.alertsService.pushAlert(alert)
    //         this.commandApplying = false
    //         sub.unsubscribe()
    //       },
    //       () => {
    //         let alert: Alert = {
    //           type: 'error',
    //           text: 'Error while applying command'
    //         }
    //         this.alertsService.pushAlert(alert)
    //         this.commandApplying = false
    //         sub.unsubscribe()
    //       })
    // }
  }

  applyCustomCommand() {
    let command = this.form.get('customCommand').value
    if (command) {
      this.rigCommand('exec', command)
    }
  }

  shutdown() {
    let dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        entity: 'actions.commands.keys'
      }
    })

    dialogRef.afterClosed()
      .pipe(
        map((result) => {
          // let returnVal: Observable<any>
          if (result === true) {
            this.rigCommand('exec', 'sreboot shutdown')
          }
        })
      ).toPromise()

      // .toPromise().catch(error())

    //   .
    // this.subs.push(dialogRef.afterClosed()
    //   .pipe(
    //     switchMap((result) => {
    //       let returnVal: Observable<any>
    //       if (result === true) {
    //         returnVal = this.groupsService.delete(this.group.id)
    //       } else {
    //         returnVal = EMPTY
    //       }
    //       return returnVal
    //     })
    //   ).subscribe((result) => {
    //     if (!result) {
    //       this.sharedModule.pushAlert('Group deleted', 'success')
    //       this.router.navigate(['/groups'])
    //     } else { // don`t know what should be to trigger that
    //       this.sharedModule.pushAlert(result, 'warning')
    //     }
    //   }, (result) => {
    //     this.sharedModule.pushAlert(`Error: ${result.statusText}`, 'warning')
    //   })
    //
    // )
  }
}
