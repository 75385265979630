import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Group, FarmOC} from '../shared/interfaces';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {GroupsService} from '../shared/services/groups.service';
import {OcService} from '../shared/services/oc.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {FilterOverclocksPipe} from '../shared/pipes/filter-oc';

@Component({
  selector: 'app-profiles-page',
  templateUrl: './oc-profiles-page.component.html'
})
export class OcProfilesPageComponent implements OnInit, OnDestroy {
  ocProfiles: FarmOC
  farms: Group[]
  sub: Subscription
  loading = true
  ocLoading = true
  farmId: number
  brand = 'amd'
  searchStr = ''
  form: FormGroup
  selectedCheckBoxes = []
  checkAll = false

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public router: Router,
    private farmsService: GroupsService,
    private ocService: OcService,
    public sharedModule: SharedModule,
    private filterOc: FilterOverclocksPipe,
    private changeDetection: ChangeDetectorRef

  // private dialog: MatDialog,
  ) { }

  async fetchOcs(farmId) {
    this.ocLoading = true
    this.ocProfiles = await this.ocService.fetchAll(farmId, 'short').toPromise()
    this.prepareForm()
    this.ocLoading = false
  }

  async ngOnInit() {
    this.sharedModule.setTitle('links.oc', true)
    this.farms = await this.farmsService.get('short').toPromise()
    this.loading = false

    this.sub = this.route.params.subscribe((params) => {
      if (! params.farmId) {
        let farmIndex = this.farms.findIndex(el => el.home === true)
        if (farmIndex === -1) {
          farmIndex = 0
        }
        const farmId = this.farms[farmIndex].id
        this.router.navigate(['/oc', farmId])
      } else {
        this.farmId = params.farmId
        this.fetchOcs(params.farmId)
      }
    })
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }

  onGroupSelect(brand: string) {
    if (brand !== this.brand) {
      this.brand = brand
      this.prepareForm()
    }
    // this.changeDetection.detectChanges()
  }

  prepareForm() {
    this.form = this.fb.group({})
    this.ocProfiles[this.brand].forEach((profile) => {
      this.form.addControl(String(profile.id), this.fb.control(false))
    })
    this.checkAll = false
  }

  nullifyCheckboxes() {
    this.checkAll = false
    this.selectedCheckBoxes = []
    Object.keys(this.form.value).forEach((id) => {
      this.form.get(id).setValue(false)
    })
    this.onChangeCheckBox()
  }

  onCheckAll() {
    this.checkAll = !this.checkAll
    Object.keys(this.form.value).forEach((id) => {
      this.form.get(id).setValue(false)
    })
    if (this.checkAll === true) {
      this.filterOc.transform(this.ocProfiles[this.brand], this.searchStr).forEach((profile) => {
        this.form.get('' + profile.id).setValue(true)
      })
    }
    this.onChangeCheckBox()
  }

  onChangeCheckBox() {
    this.selectedCheckBoxes = Object.keys(this.form.value).filter((id) => {
      return (this.form.value[id] === true)
    }).map((id) => +id)

    // this.checkAll = this.selectedCheckBoxes.length < this.filterOc.transform(this.ocProfiles[this.brand], this.searchStr).length
    if (this.selectedCheckBoxes.length < this.filterOc.transform(this.ocProfiles[this.brand], this.searchStr).length) {
      this.checkAll = false
    } else {
      this.checkAll = true
    }
    this.changeDetection.detectChanges()
  }

  deleteProfiles() {
    let ocCount = this.selectedCheckBoxes.length
    if (ocCount > 0) {
      let sub = this.ocService.delete(this.farmId, this.brand, this.selectedCheckBoxes).subscribe((result) => {
        if (!result) {
          this.selectedCheckBoxes.forEach((id) => {
            this.form.removeControl(id)
            let ocIndex = this.ocProfiles[this.brand].findIndex(el => el.id === id)
            if (ocIndex !== -1) {
              this.ocProfiles[this.brand].splice(ocIndex, 1)
            }
          })
          this.sharedModule.pushAlert(`${ocCount} OC profile(s) deleted`, 'success')
        } else { // don`t know what should be to trigger that
          this.fetchOcs(this.farmId)
          this.sharedModule.pushAlert(result, 'warning')
        }
        sub.unsubscribe()
      }, (result) => {
        this.sharedModule.pushAlert(`Error: ${result.statusText}`, 'warning')
        sub.unsubscribe()
      })
    }
  }
}
