import {NgModule} from '@angular/core'
import {FontAwesomeModule, FaIconLibrary} from '@fortawesome/angular-fontawesome'
import {TranslateModule, TranslateService} from '@ngx-translate/core'
import {DatePipe} from '@angular/common'
import {MaterialModule} from './material.module'
import {ThemeService} from './services/theme.service'
import {StyleManagerService} from './services/style-manager.service'
import {LocalStorageService} from './services/local-storage.service'
import {AuthGuard} from './services/auth.guard'
import {AlertsService} from './services/alerts.service'
import {Alert, OsVersion} from './interfaces'
import * as deepEqual from 'deep-equal'
import {Title} from '@angular/platform-browser'
import {Subscription} from 'rxjs'
import {environment} from '../../environments/environment'
import {HttpClient} from '@angular/common/http'

import {
  faAlignJustify, faAngleLeft, faAngleRight, faArchive, faArrowCircleUp,
  faBan, faBars, faBolt,
  faChartBar, faCheck, faCheckCircle, faChevronLeft, faChevronRight, faCloudDownloadAlt,
  faCode, faCodeBranch, faCog, faCopy, faCube, faCubes,
  faDollar, faDownload,
  faFile,
  faGlobe,
  faEdit, faEye, faEyeSlash, faExchangeAlt,
  faFan,
  faHashtag, faHdd, faHome,
  faInfoCircle,
  faSearch,
  faList, faListAlt,
  faMemory, faMicrochip, faMinusSquare,
  faWallet, faWifi,
  faPalette, faPaste, faPercent, faPlug, faPlusSquare,
  faQuestionCircle,
  faRandom, faRedo,
  faSignOutAlt, faSitemap, faShieldDog, faSlash, faSlidersH, faSpinner, faSquare, faStar, faSync,
  faTachometerAlt, faTemperatureHigh, faTerminal, faThermometerHalf, faTimes, faTimesCircle, faToolbox, faTools, faTrash, faTv,
  faUpload, faUserAlt, faUserMinus, faUserPlus, faUserTimes, faUsers,
  fas
} from '@fortawesome/free-solid-svg-icons'
import {
  faStar as farStar
} from '@fortawesome/free-regular-svg-icons'
import {
  faTelegram
} from '@fortawesome/free-brands-svg-icons'

@NgModule({
  imports: [
    FontAwesomeModule,
    TranslateModule,
    MaterialModule
  ],
  exports: [
    FontAwesomeModule,
    MaterialModule
  ],
  providers: [
    ThemeService,
    StyleManagerService,
    TranslateModule,
    AuthGuard,
    DatePipe,
    LocalStorageService
  ]
})

export class SharedModule {
  public faAngleLeft = faAngleLeft
  public faAngleRight = faAngleRight
  public faArchive = faArchive
  public faBan = faBan
  public faTv = faTv
  public faWallet = faWallet
  public faArrowCircleUp = faArrowCircleUp
  public faChartBar = faChartBar
  public faCode = faCode
  public faCog = faCog
  public faCopy = faCopy
  public faEdit = faEdit
  public faHome = faHome
  public faSitemap = faSitemap
  public faChevronLeft = faChevronLeft
  public faChevronRight = faChevronRight
  public faCodeBranch = faCodeBranch
  public faCloudDownloadAlt = faCloudDownloadAlt
  public faPercent = faPercent
  public faDollar = faDollar
  public faShieldDog = faShieldDog
  public faDownload = faDownload
  public faFile = faFile
  public faTelegram = faTelegram
  public faGlobe = faGlobe
  public faPallete = faPalette
  public faSignOut = faSignOutAlt
  public faUser = faUserAlt
  public faCheck = faCheck
  public faCheckCircle = faCheckCircle
  public faBolt = faBolt
  public faCube = faCube
  public faCubes = faCubes
  public faEye = faEye
  public faEyeSlash = faEyeSlash
  public faExchangeAlt = faExchangeAlt
  public faPaste = faPaste
  public faQuestionCircle = faQuestionCircle
  public faRandom = faRandom
  public faRedo = faRedo
  public faSearch = faSearch
  public faSlash = faSlash
  public faSlidersH = faSlidersH
  public faSquare = faSquare
  public faStar = faStar
  public faPlusSquare = faPlusSquare
  public farStar = farStar
  public faSync = faSync
  public faTemperatureHigh = faTemperatureHigh
  public faTerminal = faTerminal
  public faTimes = faTimes
  public faTimesCircle = faTimesCircle
  public faToolbox = faToolbox
  public faTools = faTools
  public faTrash = faTrash
  public faInfoCircle = faInfoCircle
  public faName = faList
  public faListAlt = faListAlt
  public faHashtag = faHashtag
  public faFan = faFan
  public faMem = faMemory
  public faCore = faMicrochip
  public faMinusSquare = faMinusSquare
  public faHr = faTachometerAlt
  public faSpinner = faSpinner
  public faTemp = faThermometerHalf
  public faPower = faPlug
  public faHdd = faHdd
  public faUpload = faUpload
  public faUsers = faUsers
  public faUserMinus = faUserMinus
  public faUserPlus = faUserPlus
  public faUserTimes = faUserTimes
  public faWifi = faWifi

  public faShortMode = faAlignJustify
  public faFullMode = faBars

  public coinsLogo = environment.coinsLogo

  constructor(
    private http: HttpClient,
    private alertsService: AlertsService,
    private translateService: TranslateService,
    private title: Title,
    library: FaIconLibrary
  ) {
    library.addIconPacks(fas);
  }

  public pushAlert(text: string, type?: '' | 'success' | 'warning') { // wrapper for alertsService.pushAlert
    let alert: Alert = {
      text, type
    }
    this.alertsService.pushAlert(alert)
  }

  public compareObjects(a: any, b: any): boolean {
    return deepEqual(a, b)
  }

  public setTitle(title: string, translate = false, prefix = 'OSdog | '): void {
    let sub: Subscription
    if (! translate) {
      this.title.setTitle(prefix + title)
    } else {
      sub = this.translateService.get([title]).subscribe(translation => {
        if (translation[title]) {
          this.title.setTitle(prefix + translation[title])
        }
        if (sub) {
          sub.unsubscribe()
        }
      })

    }
  }

  getValByKey(data: OsVersion[], key) {
    let index = data.findIndex(el => el.section === key)
    if (index !== -1) {
      return data[index]
    }
  }

  isArray(data: any): boolean {
    return Array.isArray(data)
  }

  dataURItoBlob(dataURI) {
  // , type= 'application/x-www-form-urlencoded'
    const byteString = window.atob(dataURI)
    const arrayBuffer = new ArrayBuffer(byteString.length)
    const int8Array = new Uint8Array(arrayBuffer)
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i)
    }
    // const blob = new Blob([int8Array], { type })
    return  new Blob([int8Array])
  }

  download(payload: string, fileName: string) {
    const imageBlob = this.dataURItoBlob(payload)
    const imageFile = new File([imageBlob], fileName)
    const blobUrl = URL.createObjectURL(imageFile)
    const link = document.createElement('a')
    link.href = blobUrl
    link.download = fileName
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
    document.body.removeChild(link);
  }

  get apiUrl(): string {
    const url = window.location.href
    if (url.indexOf('https://eu.os.dog/') === 0) {
      return 'https://eu.os.dog/api'
    }
    return environment.apiUrl
  }
}
