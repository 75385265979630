import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Group} from '../../interfaces';
import {SharedModule} from '../../shared.module';
import {FormBuilder,  FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  form: FormGroup
  @Output() formSubmit = new EventEmitter()
  constructor(
    public sharedModule: SharedModule,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      farms: Group[],
      selectedFarm: number
    }
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      password: ['', [
        Validators.required,
        Validators.minLength(environment.patterns.password.minLength),
        Validators.maxLength(environment.patterns.password.maxLength),
        Validators.pattern(environment.patterns.password.pattern),
      ]],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(environment.patterns.password.minLength),
        Validators.maxLength(environment.patterns.password.maxLength),
        Validators.pattern(environment.patterns.password.pattern),
      ]],
      newPassword2: ['', [
        Validators.required,
        Validators.minLength(environment.patterns.password.minLength),
        Validators.maxLength(environment.patterns.password.maxLength),
        Validators.pattern(environment.patterns.password.pattern),
      ]],
      twofa: ['', [
        Validators.pattern(environment.patterns.twofa.pattern),
        Validators.minLength(environment.patterns.twofa.minLength),
        Validators.maxLength(environment.patterns.twofa.maxLength)
      ]],
    })
  }

  submit() {
    this.dialogRef.close(this.form.value);
  }

  // showForm() {
  //   console.log(this.form, this.form.value)
  // }
}
