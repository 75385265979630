import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appHideContent]'
})
export class HideContentDirective {
  stub: string
  constructor(
    public elementRef: ElementRef,
  ) { }
  @Input('appHideContent') content: string
  @HostListener('mouseenter') onMouseEnter() {
    this.stub = this.elementRef.nativeElement.textContent
    this.elementRef.nativeElement.textContent = this.content
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.elementRef.nativeElement.textContent = this.stub
  }
}
