<div class="container">
  <div class="card">
    <div class="card-label">
      <h2>
        {{ 'Page404.title' | translate }}
      </h2>
    </div>
    <div class="card-content">
      {{ 'Page404.titleDescr' | translate }}
      <p>
        {{ 'Page404.text1' | translate }} <b>{{this.page}}</b> {{ 'Page404.text2' | translate }}
      </p>

      <p>
        <strong>{{ 'Page404.text3' | translate }}</strong>
      </p>

      <ul>
        <li>
          <a [routerLink]="['/']">
            {{ 'links.main' | translate }}
          </a>
        </li>
        <li *ngFor="let link of links">
          <a [routerLink]="['/' + link]">
            {{ 'links.' + link | translate }}
          </a>
        </li>
      </ul>


      {{ 'Page404.text4' | translate }}
      <fa-icon class="mr-02" [icon]="this.sharedModule.faTelegram"></fa-icon><a href="{{ this.chatLink }}">@osdog_chat</a>
    </div>
  </div>
</div>
