import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../environments/environment';
import {SharedModule} from '../shared/shared.module';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit{
  constructor(
    public route: ActivatedRoute,
    public sharedModule: SharedModule
  ) { }
  public page = ''
  public chatLink = environment.chatLink
  public links = [
    'monitor',
    'groups',
    'profiles',
    'account',
    'settings',
    'changelog',
    'downloads',
  ]
  ngOnInit(): void {
    this.sharedModule.setTitle('404')
    this.route.url.subscribe((url) => {
        this.page = 'os.dog'
        url.forEach((e) => {
          this.page += `/${e}`
        })
    })
  }
}
