<div class="flex-container">
  <div>
    <h4>{{ 'rig.devices.' + device + '.title' | translate }}</h4>
  </div>
  <div class="pinned-right">
    <fa-icon [icon]="this.sharedModule.faCog" [routerLink]="['tuning']" [fragment]="device" class="active-element mr-02"
             title="{{ 'rig.devices.routeToConfig' | translate }}"
    ></fa-icon>
    <fa-icon *ngIf="!autofanHardwareShowZeroFan" [icon]="this.sharedModule.faEye" (click)="toggleAutofanHardwareShowZeroFan()" class="active-element"
             title="{{ 'rig.devices.showZeroFans' | translate }}"
    ></fa-icon>
    <fa-icon *ngIf="autofanHardwareShowZeroFan" [icon]="this.sharedModule.faEyeSlash" (click)="toggleAutofanHardwareShowZeroFan()" class="active-element"
             title="{{ 'rig.devices.dontShowZeroFans' | translate }}"
    ></fa-icon>
  </div>
</div>

<div  class="flex-container w-100">
  <div *ngIf="data.thermosensors && data.thermosensors.length > 0; else noThermoSensors" class="bordered mr-02 flex-container vertical">
    <div *ngFor="let temp of data.thermosensors; index as i" class="active-element"
         title="{{ 'rig.devices.thermosensor' | translate }} {{i + 1}}">
      <fa-icon [icon]="this.sharedModule.faTemperatureHigh"></fa-icon>
      {{temp}}°
    </div>
  </div>
  <ng-template #noThermoSensors>
    {{ 'rig.devices.noConnectedThermoSensors' | translate }}
  </ng-template>

  <div *ngIf="data.casefan && data.casefan.length > 0; else noConnectedFans" class="flex-container">
    <ng-container *ngFor="let fan of data.casefan; index as i">
      <div *ngIf="(! autofanHardwareShowZeroFan && fan > 0) || autofanHardwareShowZeroFan"
           class="outer-fan bordered flex-container vertical center active-element"
           title="{{ 'rig.devices.fan' | translate }} {{i + 1}}"
      >
        <div class="flex-container center">
          <fa-icon [icon]="this.sharedModule.faFan"></fa-icon>&nbsp;{{fan}}%
        </div>
        <div class="flex-container center">
          <fa-icon [icon]="this.sharedModule.faHashtag"></fa-icon>&nbsp;{{i + 1}}
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template #noConnectedFans>
    {{ 'rig.devices.noConnectedFans' | translate }}
  </ng-template>

</div>
