<div class="container">
  <div class="card " *ngIf="group.rigs.length > 0; else noRigs">
      <div class="flex-container align-center">
        <div>
          <input type="checkbox" class="custom-checkbox" id="checkAll" value="1" [checked]="checkAll === true" (change)="onCheckAll()">
          <label for="checkAll"></label>
        </div>
        <div class="flex-grow-1 flex-container align-center">
          <div class="flex-grow-1">
            <input type="text" placeholder="{{'groups.findRig' | translate}}" (change)="nullifyCheckboxes()" [(ngModel)]="searchStr" style="margin-top: 0.3rem">
          </div>
          <div *ngIf="selectedCheckBoxes.length > 0" class="pinned-right pt-02">
            <button class="btn btn-default mr-0 ml-02"
              title="{{'groups.moveRigs' | translate}}"
              (click)="openMoveWindow()"
            >
              <fa-icon [icon]="this.sharedModule.faExchangeAlt"></fa-icon>
            </button>

            <button class="btn btn-default mr-0 ml-02"
              title="{{'groups.deleteRigs' | translate}}"
              (click)="deleteRigs()"
            >
              <fa-icon [icon]="this.sharedModule.faTrash"></fa-icon>
            </button>
          </div>
        </div>
      </div>

      <form [formGroup]="form" >
        <div *ngFor="let rig of group.rigs | filterRigs:searchStr" class="flex-container align-center">
          <div>
            <input type="checkbox" class="custom-checkbox" [formControlName]="rig.id" [id]="rig.id" value="1"
              (change)="onChangeCheckBox()">
            <label [for]="rig.id"></label>
          </div>
          <app-rig-row [rig]="rig" class="flex-grow-1"></app-rig-row>
        </div>
      </form>
  </div>
  <ng-template #noRigs>
    {{'groups.noRigs' | translate}}
  </ng-template>

  <div class="flex-container">
    <button class="btn btn-default pinned-right" (click)="createRig()"
      title="{{'groups.createRig' | translate}}"
    >
      <fa-icon [icon]="sharedModule.faPlusSquare" class="active-element"></fa-icon>
    </button>
  </div>
</div>



