<div id="main">
  <form [formGroup]="form" (ngSubmit)="submit()" style="padding: 0.5rem">
      <div class="flex-container form-control w-100 vertical" *ngIf="'fan_mode' as controlName">
        <mat-slide-toggle formControlName="fan_mode" >
          {{ 'tuning.' + section + '.keys.' + controlName | translate }}:
          <ng-container *ngIf="!(controlName === 'fan_mode' && ! form.get(controlName).value); else disabled">
            {{ 'tuning.' + section + '.keys.' + controlName + '_1' | translate }}
          </ng-container>
          <ng-template #disabled>
            {{ 'tuning.' + section + '.keys.' + controlName + '_2'  | translate }}
          </ng-template>
        </mat-slide-toggle>
      </div>

    <div *ngFor="let controlName of simpleControls" class="flex-container w-100 vertical">
      <label [for]="controlName">{{ 'tuning.' + section + '.keys.' + controlName | translate }}</label>
      <div class="form-control w-50">
        <input [id]="controlName" [formControlName]="controlName" type="number" appOnlyNumbers>
        <app-input-validation [component]="form.get(controlName)"></app-input-validation>
      </div>
    </div>

    <div class="flex-container w-100">
      <i>{{ 'tuning.' + section + '.description' | translate }}</i>
    </div>
    <div class="flex-container form-buttons">
      <button type="submit" class="btn btn-success pinned-right mr-0"
        [disabled]="!form.valid"
      >{{'save' | translate}}</button>
    </div>
  </form>
</div>
