import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SharedModule} from '../../shared.module';
import {OpenVPN} from '../../interfaces';
import {ValidateFilesize} from '../../validators/filesize.validator';

@Component({
  selector: 'app-edit-openvpn',
  templateUrl: './edit-openvpn.component.html'
})
export class EditOpenvpnComponent implements OnChanges {
  @Input() data: any
  @Output() dataUpload = new EventEmitter()
  settings: OpenVPN = {}
  form: FormGroup
  fileName = ''

  constructor(
    private fb: FormBuilder,
    public sharedModule: SharedModule
  ) { }

  ngOnChanges(): void {
    if (typeof this.data.openvpn === 'object') {
      this.settings = {...this.data.openvpn}
    }

    // console.log ('this.settings', this.settings)
    this.form = this.fb.group({
      enabled: [false],
      login: [''],
      password: [''],
      conf: ['',
        [ Validators.required, ValidateFilesize(20000) ]
      ],
      action: ['new']
    })
    // console.log('this form get conf', this.form.get('conf'))

    this.form.patchValue(this.settings)

    if (this.data.openvpn_conf) {
      // console.log ('got something')
      this.settings.conf = this.data.openvpn_conf
      // this.fileName = 'ovpn.conf'
      this.form.get('action').setValue('keep')
      this.form.get('conf').setValue(this.data.openvpn_conf)
    }
    // console.log (this.form.value)
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    if (file) {
      this.form.get('conf').markAsTouched()
      this.form.get('conf').patchValue(file)
      this.fileName = file.name;
    }
  }

  submit() {
    const form = this.form.value

    let formData = new FormData()
    if (form.action === 'new') {
      formData.append('thumbnail', form.conf)
    } else if (form.action === 'remove') {
      form.enabled = false
    }

    formData.append('enabled', form.enabled)
    formData.append('action', form.action)
    formData.append('login', form.login)
    formData.append('password', form.password)
    formData.append('command', 'OpenVPN config')
    this.dataUpload.emit(formData)
  }

}
