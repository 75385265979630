import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'truncateText'
})
export class TruncateTextPipe implements PipeTransform {
  transform(text: string, length = 40): string {
    if (text.length > 0) {
      let ellipsisTrigger = false
      let index = text.indexOf('\n')
      if (index > 0) {
        text = text.substr(0, index)
        ellipsisTrigger = true
      }

      if (text && text.length >= length - 3) {
        text = text.substr(0, length)
        ellipsisTrigger = true
      }

      if (ellipsisTrigger) {
        text += '...'
      }
      return text
    }
  }
}
