// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: '2.11',
  refreshTime: 35000,
  // locales: ['en', 'ru'],
  locales: [
    {
      locale: 'ru',
      description: 'Русский'
    },
    {
      locale: 'en',
      description: 'English'
    },
    {
      locale: 'de',
      description: 'Deutsch'
    },
  ],
  chatLink: 'https://t.me/osdog_chat',
  apiUrl: 'https://os.dog/api/v2',
  patterns: {
    login: {
      pattern: '[0-9a-zA-Z_]*',
      minLength: 3,
      maxLength: 20
    },
    password: {
      pattern: '[-0-9a-zA-Z_@.#]*',
      minLength: 6,
      maxLength: 60
    },
    mail: {
      minLength: 6,
      maxLength: 30
    },
    twofa: {
      pattern: '[0-9]*',
      minLength: 6,
      maxLength: 6
    }
  },
  defaults: {
    language: 'ru',
    theme: 'dark',
    additionalSysInfo: false,       // additional sys info in rig
    autofanHardwareShowZeroFan: true,    // show zero fans from hardware autofan controller (autofan8mk, Coolbox)
    autofanNapalmShowZeroFan: true, // show zero fans from Autofan Napalm controller,
    viewMode: 'short',
    tempLimit: 80
  },
  farmAccessLevels: [
    0, 3, 4, 7, 8, 9
  ],

  defaultCoins: [
    'ALPH',
    'CHN',
    'DNX',
    'ERGO',
    'ETC',
    'IRON',
    'KAS',
    'MEWC',
    'NEXA',
    'PEPEW',
    'REDB',
    'RXD',
    'RVN',
    'XLA'
  ],

  coinsLogo: {
    ALPH: 'alph.png',

    CHN: 'chinet.png',

    DNX: 'dnx.png',

    ERGO: 'ergo.png',
    ETC: 'etc.png',

    IRON:   'iron.png',

    KAS: 'kaspa.png',

    MEWC: 'mewc.png',

    NEXA: 'nexa.svg',

    PEPEW: 'pepew.png',

    REDB: 'redb.png',

    RXD: 'radiant.png',
    RVN: 'rvn.png',

    XLA: 'xla.png',
  }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
