import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WdLoad} from '../../interfaces';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-edit-wd-load',
  templateUrl: './edit-wd-load.component.html'
})
export class EditWdLoadComponent implements OnInit {
  @Input() data: WdLoad
  @Output() formSubmit = new EventEmitter()
  section = 'wd_load'
  form: FormGroup
  simpleControls = [
    'restart',
    'reboot',
    'min'
  ]
  boolControls = [
    'enabled'
  ]
  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      enabled: [false],
      restart: [180],
      reboot: [300],
      min: [80],
    })

    this.form.patchValue(this.data)
  }

  submit() {
    let data = {
      wd_load: this.form.value
    }
    this.formSubmit.emit(data)
  }
}
