import { Component, OnInit } from '@angular/core';
import {LocalStorageService} from '../shared/services/local-storage.service'
import {TranslateService} from '@ngx-translate/core'
import {Router} from '@angular/router'

@Component({
  template: ''
})
export class EnForwarderComponent implements OnInit {
  language = 'en'

  constructor(
    private localStorage: LocalStorageService,
    private translateService: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.localStorage.setLanguage(this.language)
    this.translateService.use(this.language)

    this.router.navigate(['/']).finally()
  }

}
