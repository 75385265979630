import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core'
import {forkJoin, of, Subscription} from 'rxjs';
import {Group, Profile, OsDataMiner, OsDataAlgo} from '../shared/interfaces';
import {ActivatedRoute, Router} from '@angular/router'
import {GroupsService} from '../shared/services/groups.service'
import {ManifestsService} from '../shared/services/manifests.service'
import {MatDialog} from '@angular/material/dialog'
import {EditProfileComponent} from '../shared/components/edit-profile/edit-profile.component'
import {OsService} from '../shared/services/os.service'
import {LoadingComponent} from '../shared/components/loading/loading.component'
import {FormBuilder, FormGroup} from '@angular/forms'
import {SharedModule} from '../shared/shared.module'
import {FilterProfilesPipe} from '../shared/pipes/filter-profiles'
import {CopyEntitiesComponent} from '../shared/components/copy-entities/copy-entities.component'
import {map, switchMap} from 'rxjs/operators'

@Component({
  selector: 'app-manifests-page',
  templateUrl: './profiles-page.component.html',
  styleUrls: ['profiles-page.component.scss']
})
export class ProfilesPageComponent implements OnInit, OnDestroy {
  manifests: Profile[]
  farms: Group[]
  sub: Subscription
  loading = true
  manifestsLoading = true
  osDataMiners: OsDataMiner[]
  osDataAlgos: OsDataAlgo[]
  farmId: number
  searchStr = ''
  form: FormGroup
  selectedCheckBoxes = []
  checkAll = false

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private farmsService: GroupsService,
    private manifestsService: ManifestsService,
    private dialog: MatDialog,
    private osService: OsService,
    private fb: FormBuilder,
    public sharedModule: SharedModule,
    private filterProfiles: FilterProfilesPipe,
    private changeDetection: ChangeDetectorRef
  ) { }

  async fetchManifests(farmId) {
    this.manifestsLoading = true
    this.manifests = await this.manifestsService.fetchAll(farmId, 'short').toPromise()

    if (this.manifests) {
      this.form = this.fb.group({
      })

      this.manifests.forEach((profile) => {
        this.form.addControl(String(profile.id), this.fb.control(false))
      })
    }
    this.manifestsLoading = false
  }

  async ngOnInit() {
    this.sharedModule.setTitle('links.profiles', true)

    this.farms = await this.farmsService.get('short').toPromise()
    this.osDataMiners = await this.osService.getMiners().toPromise()
    this.loading = false

    this.sub = this.route.params.subscribe((params) => {
      if (! params.farmId) {
        let farmIndex = this.farms.findIndex(el => el.home === true)
        if (farmIndex === -1) {
          farmIndex = 0
        }
        const farmId = this.farms[farmIndex].id
        this.router.navigate(['/profiles', farmId])
      } else {
        this.farmId = +params.farmId
        this.fetchManifests(params.farmId)
      }
    })

  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }

  async editManifest(manifest: Profile, newProfile = false) {
    if (! this.osDataMiners || ! this.osDataAlgos) {
      let dialogRefLoading = this.dialog.open(LoadingComponent, { panelClass: 'custom-dialog-container' })

      await Promise.all([
        this.osService.getAlgos().toPromise(),
        this.osService.getMiners().toPromise()
      ]).then((data) => {
        this.osDataAlgos = data[0]
        this.osDataMiners = data[1]
      }, (error) => {
        this.sharedModule.pushAlert(error.error.message, 'warning')
      })
      dialogRefLoading.close()
    }

    let dataToComponent

    if (newProfile === true) {
      dataToComponent = {
        osDataMiners: this.osDataMiners,
        osDataAlgos: this.osDataAlgos,
        manifest$: of({})
      }
    } else {
      dataToComponent = {
        osDataMiners: this.osDataMiners,
        osDataAlgos: this.osDataAlgos,
        manifest$: this.manifestsService.fetch(this.farmId, manifest.id)
      }
    }
    let dialogRef = this.dialog.open(EditProfileComponent, {
      panelClass: 'custom-dialog-container',
      data: dataToComponent,
      maxWidth: undefined
    })

    dialogRef.afterClosed()
      .pipe(
        switchMap((dialogResult) => {
          if (dialogResult) {
            let usingOnline = Object.assign([], dialogResult.usingOnline)
            let usingOffline = Object.assign([], dialogResult.usingOffline)
            delete dialogResult.usingOnline
            delete dialogResult.usingOffline
            if (newProfile === false) {
              return this.manifestsService.patch(this.farmId, manifest.id, dialogResult)
                .pipe(
                  switchMap((patchResult) => {
                    if (patchResult === 'OK') {
                      this.sharedModule.pushAlert(`Profile saved`, 'success')
                      let index = this.manifests.findIndex((el) => el.id === manifest.id)
                      if (index !== -1) {
                        dialogResult.id = manifest.id
                        dialogResult.usingOnline = usingOnline
                        dialogResult.usingOffline = usingOffline
                        this.manifests[index] = dialogResult
                        // console.log ('usingOnline', usingOnline)
                        return of(123)
                        // usingOnline.forEach((rig: Rig) => {
                        //   return this.rigsService.changeProfile(rig.id)
                        // })
                      } else {
                        this.sharedModule.pushAlert(`Unknown error 5`, 'warning')
                        // return of()
                      }
                    } else {
                      this.sharedModule.pushAlert(`Error: ${patchResult}`, 'warning')
                      // return of()
                    }
                  })
                )
            } else {
              return this.manifestsService.create(this.farmId, dialogResult)
                .pipe(
                  map((createResult) => {
                    if (createResult && createResult.id && +createResult.id > 0) {
                      this.sharedModule.pushAlert(`Profile created`, 'success')
                      let profileId = +createResult.id
                      dialogResult.id = profileId
                      this.manifests.push(dialogResult)
                      this.form.addControl(String(profileId), this.fb.control(false))
                      this.onChangeCheckBox()
                    } else {
                      this.sharedModule.pushAlert(`Error: ${createResult}`, 'warning')
                    }
                  })
                )
            }
          } else {
            return of()
          }
        })
      ).toPromise().catch((error) => {
        // console.log ('Error while insert', error.error.message)
        this.sharedModule.pushAlert(`Error: ${error.error.message}`, 'warning')
    })
  }

  onChangeCheckBox() {
    this.selectedCheckBoxes = Object.keys(this.form.value).filter((id) => {
      return (this.form.value[id] === true)
    }).map((id) => +id)
    if (this.selectedCheckBoxes.length < this.filterProfiles.transform(this.manifests, this.searchStr).length) {
      this.checkAll = false
    } else {
      this.checkAll = true
    }
    this.changeDetection.detectChanges()
  }

  onCheckAll() {
    this.checkAll = !this.checkAll
    Object.keys(this.form.value).forEach((id) => {
      this.form.get(id).setValue(false)
    })
    if (this.checkAll === true) {
      this.filterProfiles.transform(this.manifests, this.searchStr).forEach((profile) => {
        this.form.get('' + profile.id).setValue(true)
      })
    }
    this.onChangeCheckBox()
  }

  nullifyCheckboxes() {
    this.checkAll = false
    this.selectedCheckBoxes = []
    Object.keys(this.form.value).forEach((id) => {
      this.form.get(id).setValue(false)
    })
    this.onChangeCheckBox()
  }

  deleteProfiles() {
    if (this.selectedCheckBoxes.length > 0) {
      this.selectedCheckBoxes.forEach((id) => {
        let sub = this.manifestsService.delete(this.farmId, id).subscribe((result) => {
          if (!result) {
            this.form.removeControl(id)
            let profileIndex = this.manifests.findIndex(el => el.id === id)

            if (profileIndex !== -1) {
              this.manifests.splice(profileIndex, 1)
            }
            this.sharedModule.pushAlert('Profile deleted', 'success')
          } else { // don`t know what should be to trigger that
            this.sharedModule.pushAlert(result, 'warning')
          }

          sub.unsubscribe()
        }, (result) => {
          this.sharedModule.pushAlert(`Error: ${result.statusText}`, 'warning')
          sub.unsubscribe()
        })
      })
      this.checkAll = false
      this.selectedCheckBoxes = []
    }
  }
  //
  // addNewProfile() {
  //   this.editManifest({})
  // }

  openCopyWindow() {
    if (this.selectedCheckBoxes.length > 0) {
      let dialogRef = this.dialog.open(CopyEntitiesComponent, {
        panelClass: 'custom-dialog-container',
        maxWidth: undefined,
        data: {
          farms: this.farms,
          selectedFarm: +this.farmId
        }
      })

      let sub = dialogRef.afterClosed().subscribe((farmId) => {
        if (farmId) {
          this.selectedCheckBoxes.forEach((oldProfileId) => {
            let index = this.manifests.findIndex((el) => el.id === oldProfileId)
            if (index !== -1) {
              let subSub = this.manifestsService.fetch(farmId, this.manifests[index].id).pipe(
                switchMap((profileData) => {
                  profileData.favorite = false
                  delete profileData.id
                  delete profileData.owner
                  delete profileData.usingOffline
                  delete profileData.usingOnline
                  let newProfile = {}
                  Object.keys(profileData).forEach((key) => {
                    if (profileData[key]) {
                      newProfile[key] = profileData[key]
                    }
                  })
                  return this.manifestsService.create(farmId, newProfile)
                    .pipe(
                      map((result) => {
                        if (result && result.id && +result.id > 0) {
                          if (farmId === this.farmId) {
                            let profileId = +result.id
                            profileData.id = profileId
                            this.manifests.push(profileData)
                            this.form.addControl(String(profileId), this.fb.control(false))
                          }
                          this.sharedModule.pushAlert('Profile copied', 'success')
                        } else {
                          this.sharedModule.pushAlert('Error while copying profile', 'warning')
                        }
                      })
                    )
                })
              ).subscribe(() => {
                if (subSub) {
                  subSub.unsubscribe()
                }
              })
            }
          })
          this.nullifyCheckboxes()
        }
        if (sub) {
          sub.unsubscribe()
        }
      })
    }
  }
}
