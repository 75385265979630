<div class="bordered active-element" style="height: 2.8rem"
     [ngClass]="{offline: rig.nonActive && rig.refresh !== 0}"
>
  <div class="flex-container flex-grow-1" [routerLink]="['/rig', rig.id]">
    <div *ngIf="rig.refresh === 0" class="mr-02">
      <fa-icon [icon]="this.sharedModule.faQuestionCircle"
        title="{{'rig.newRigDescr' | translate}}"
      ></fa-icon>
    </div>
    <div class="flex-container vertical">
      <div>
        <a [routerLink]="['/rig', rig.id]">
          {{rig.name}}
        </a>
      </div>
      <div *ngIf="rig.comment">
        <i>{{'' + rig.comment | truncateText}}</i>
      </div>
    </div>
    <div class="flex-container pinned-right" *ngIf="rig.gpuCount > 0">
      <div class="pinned-right" title="{{ 'groups.rigGpuCount' | translate }}">
        {{ rig.gpuCount}}
        <fa-icon [icon]="this.sharedModule.faCube" class="info-element"></fa-icon>
      </div>
    </div>
  </div>
</div>
