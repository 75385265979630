<div class="container-wide">
  <div class="card ml-0 mr-0">
    <div class="card-content">
      <h2>
        {{'downloads.title' | translate}}
      </h2>
      <table *ngIf="isoArr$ | async as isoArr" class="w-100">
        <tr>
          <th>{{'downloads.name' | translate}}</th>
          <th>{{'downloads.size' | translate}}</th>
          <th>{{'downloads.kernel' | translate}}</th>
          <th class="visible-xl">{{'downloads.amd' | translate}}</th>
          <th class="visible-xl">{{'downloads.nvidia' | translate}}</th>
          <th>{{'downloads.date' | translate}}</th>
        </tr>
        <tr *ngFor="let iso of isoArr" >
          <td>
            <b>
              <a href="{{iso.url}}">
                <fa-icon [icon]="this.sharedModule.faFile" class="active-element" ></fa-icon>
                {{iso.name | titlecase}}
              </a>
            </b>
          </td>
          <td>{{iso.size | displayFilesize: true: true}}</td>
          <td>{{iso.kernel}}</td>
          <td class="visible-xl">{{iso.amd}}</td>
          <td class="visible-xl">{{iso.nvidia}}</td>
          <td>{{iso.date}}</td>
        </tr>
      </table>
    </div>
  </div>
</div>
