import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RigsService} from '../shared/services/rigs.service';
import {Message, Rig} from '../shared/interfaces';
import {DataPreparationService} from '../shared/services/data-preparation.service';
import {SharedModule} from '../shared/shared.module';
import {Observable, of, Subject, Subscription, throwError, timer} from 'rxjs';
import {catchError, first, map, switchMap, takeUntil} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import {GroupsService} from '../shared/services/groups.service';
import {HttpErrorResponse} from '@angular/common/http';
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-rig-page',
  templateUrl: './rig.component.html',
  styleUrls: ['./rig.component.scss']
})
export class RigComponent implements OnInit, OnDestroy {
  refreshTime = environment.refreshTime
  // refreshTime = 5000

  rigId: number
  rig: Rig
  currentTime: number
  loading = true
  fullMessages: Message[]

  deletedMessages: number[] = []
  groupRigs$: Observable<Rig[]>
  groupRigs: Rig[] = []
  prevNeighbour: Rig
  nextNeighbour: Rig
  rigIdNext: number
  rigIdPrev: number
  error = false
  errorText: string

  destroy$ = new Subject<void>()

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public rigsService: RigsService,
    public groupsService: GroupsService,
    public dataPreparationService: DataPreparationService,
    public sharedModule: SharedModule,
    public cd: ChangeDetectorRef,
    public title: Title
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  ngOnInit(): void {
    this.title.setTitle('OSdog | Rig')
    this.route.params
      .pipe(
        switchMap((params) => timer(0, this.refreshTime)
          .pipe(
            takeUntil(this.destroy$),
            switchMap (() => {
              if (! this.rigId || this.rigId !== +params.id) {
                this.rigId = +params.id
                this.fullMessages = []
                // this.fullMessages$ = []
                this.deletedMessages = []
              }

              this.currentTime = Math.floor(Date.now() / 1000)
              return this.rigsService.get(params.id)
                .pipe(
                  map((rigData) => {
                    if (rigData) {
                      this.error = false
                      this.sharedModule.setTitle(rigData.name)
                      this.groupRigs$ = this.groupsService.getRigs(rigData.owner, 'short').
                        pipe(
                          switchMap(rigs => of(this.dataPreparationService.sortRigs(rigs)))
                      )
                      if (rigData.messages) {
                        rigData.messages = this.dataPreparationService.prepareMsgData(rigData.messages)
                      }
                    }
                    this.rig = this.dataPreparationService.prepareRigData(rigData)
                  })
                )
                .pipe(
                  catchError((err: HttpErrorResponse) => {
                    this.error = true
                    if (err.error.message) {
                      this.errorText = err.error.message
                    } else {
                      this.errorText = err.statusText
                    }
                    return throwError(this.error)
                  })
                )
            })
          )
        )
      ).toPromise().catch(er => {
        console.log('got error', er)
    })
  }

  private findNeighbour(rigId: number, rigs: Rig[], next = true): Rig {
    if (rigs.length > 1) {
      let index = rigs.findIndex(el => el.id === rigId)
      if (index > -1) {
        let neighbourIndex: number
        if (next) {
          neighbourIndex = index + 1
        } else {
          neighbourIndex = index - 1
        }
        if (neighbourIndex >= rigs.length) {
          neighbourIndex -= rigs.length
        } else if (neighbourIndex < 0) {
          neighbourIndex += rigs.length
        }
        return rigs[neighbourIndex]
      }
    }
  }
  public getPrevNeighbour(rigId: number, rigs: Rig[]) {
    if (! this.prevNeighbour || rigId !== this.rigIdPrev) {
      this.prevNeighbour = this.findNeighbour(rigId, rigs, false)
      this.rigIdPrev = rigId
    }
    return this.prevNeighbour
  }
  public getNextNeighbour(rigId: number, rigs: Rig[]) {
    if (! this.nextNeighbour || rigId !== this.rigIdNext) {
      this.nextNeighbour = this.findNeighbour(rigId, rigs)
      this.rigIdNext = rigId
    }
    return this.nextNeighbour
  }

  async deleteMessage(rigId: number, msgId: number) {
    let sub: Subscription
    sub = await this.rigsService.deleteMessage(rigId, msgId)
      .subscribe(() => {
        this.sharedModule.pushAlert('Message deleted', 'success')
        this.deletedMessages.push(msgId)
        sub.unsubscribe()
      },
        () => {
          this.sharedModule.pushAlert('Error while deleting message', 'warning')
          sub.unsubscribe()
        }
    )
  }

  async getMessage(rigId: number, msgId: number) {
    let msg = await this.rigsService.getMessage(rigId, msgId).toPromise()

    if (msg.type !== 'file' || !msg.payload) {
      let index = this.fullMessages.findIndex(el => +el.id === +msgId)

      if (index === -1) {
        this.fullMessages.push(msg)
      } else {
        this.closeFullMessage(msgId)
      }

    } else {
      this.sharedModule.download(msg.payload, msg.title)
    }
  }

  closeFullMessage(msgId: number) {
    let index = this.fullMessages.findIndex(el => +el.id === +msgId)
    this.fullMessages.splice(index, 1)
  }

  deleteAllMessages(messages: Message[]) {
    this.deletedMessages = messages.map(el => el.id)
    let sub = this.route.params
      .pipe(
        first(), // mb unnecessary here
        switchMap((params) => {
          return this.rigsService.deleteMessages(+params.id)
        })
      ).subscribe(() => {
        this.sharedModule.pushAlert('Messages deleted', 'success')
        // delete this.messages$
        delete this.fullMessages
        sub.unsubscribe()
      },
      () => {
        this.sharedModule.pushAlert('Error while deleting messages', 'warning')
        sub.unsubscribe()
      })
  }

  deleteAllCommands() {
    this.rigsService.deleteCommands(this.rigId)
      .pipe(
        map((result) => {
          if (result === 'OK') {
            this.rig.commands = []
            this.sharedModule.pushAlert('Commands deleted', 'success')
          } else {
            this.sharedModule.pushAlert(`Error while deleting commands: ${result}`, 'warning')
          }
        })
      ).toPromise().catch((error) => {
      this.sharedModule.pushAlert(error.error.message, 'warning')
    })
  }
}
