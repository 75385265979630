import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../services/account.service';
import {SharedModule} from '../../shared.module';
import {map} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {ValidateSpecialChars} from '../../validators/special-chars.validator';
import {SignUpForm} from '../../interfaces';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss']
})
export class RestorePasswordComponent implements OnInit {
  form: FormGroup
  token: string
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private accountService: AccountService,
    public sharedModule: SharedModule
  ) { }

  ngOnInit(): void {
    this.route.params
      .pipe(
        map((params) => {
          this.token = params.token
        })).toPromise().catch((err) => {
      console.log (err)
    })

    this.form = this.fb.group({
      password: ['', [
        Validators.required,
        Validators.minLength(environment.patterns.password.minLength),
        Validators.maxLength(environment.patterns.password.maxLength),
        Validators.pattern(environment.patterns.password.pattern),
      ]],
      passwordConfirmation: ['', [
        Validators.required,
        Validators.minLength(environment.patterns.password.minLength),
        Validators.maxLength(environment.patterns.password.maxLength),
        Validators.pattern(environment.patterns.password.pattern),
      ]]
    })
  }

  submit() {
    const dataToSend = this.form.value
    dataToSend.token = this.token
    // console.log ('submit', this.form.value)
    // this.formSubmit.emit(this.form.value)

    this.accountService.resetPassword(dataToSend)
      .pipe(
        map((data) => {
          if (data === 'OK') {
            this.form.reset()
            this.router.navigate(['/'])
            this.sharedModule.pushAlert('Password reset successful')
          } else {
            this.sharedModule.pushAlert(`Error: ${data}`, 'warning')
          }
        })
      ).toPromise()
      .catch((error) => {
      this.sharedModule.pushAlert(`Error: ${error.error.message}`, 'warning')
    })
  }
}
