import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {WalletsBlockComponent} from '../wallets-block/wallets-block.component';
import {LoadingComponent} from '../loading/loading.component';
import {CustomSelectComponent} from '../custom-select/custom-select.component';
import {Profile} from '../../interfaces';
import {ManifestsService} from '../../services/manifests.service';
import {SharedModule} from '../../shared.module';
import {OsService} from '../../services/os.service';

@Component({
  selector: 'app-apply-multi-profiles',
  templateUrl: './apply-multi-profiles.component.html',
  styleUrls: ['./apply-multi-profiles.component.scss']
})
export class ApplyMultiProfilesComponent implements OnInit {
  @Input() groupId: number
  managedProfiles = [0, 1]
  profiles: Profile[]
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      groupId: number
    },
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ApplyMultiProfilesComponent>,
    private osService: OsService,
    private profilesService: ManifestsService,
    public sharedModule: SharedModule
  ) { }

  ngOnInit(): void {
    // this.manifests = await this.manifestsService.fetchAll(this.farmId).toPromise()
  }

  async openSelect(profileNumber: number) {
    if (! this.profiles) {
      let dialogRef = this.dialog.open(LoadingComponent)
      this.profiles = await this.profilesService.fetchAll(this.data.groupId).toPromise()
      dialogRef.close()
    }

    let dialogReg = this.dialog.open(CustomSelectComponent, {
      data: {
        manifests: this.profiles,
        osDataMiners: await this.osService.getMiners().toPromise()
      }
    })

    let sub = dialogReg.afterClosed().subscribe((profile) => {
      if (profile) {
        // console.log ('selected profile', profile)
        let data = {
          profileNumber,
          profile,
          command: 'apply'
        }
        this.submit(data)
        // this.changeManifest(profileNumber, profile)
      }
      sub.unsubscribe()
    });
  }

  submit(data: any): void {
    this.dialogRef.close(data)
  }

  restartProfile(i: number) {
    let data = {
      profileNumber: i,
      command: 'restart'
    }
    this.submit(data)
  }

  stopProfile(i: number) {
    let data = {
      profileNumber: i,
      command: 'stop'
    }
    this.submit(data)
  }
}
