import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedModule} from '../../shared.module';
import {AccountService} from '../../services/account.service';

@Component({
  selector: 'app-confirm-mail',
  templateUrl: './confirm-mail.component.html',
  styleUrls: ['./confirm-mail.component.scss']
})
export class ConfirmMailComponent implements OnInit {
  token: string
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    public sharedModule: SharedModule
  ) { }

  ngOnInit(): void {
    this.route.params
      .pipe(
        map((params) => {
          this.token = params.token
        })).toPromise().catch((err) => {
          console.log (err)
    })
  }

  confirmMail() {
    this.accountService.confirmMail({token: this.token})
      .pipe(
        map((data) => {
          if (data === 'OK') {
            this.sharedModule.pushAlert('Mail confirmed', 'success')
            this.router.navigate(['monitor']);
          } else {
            this.sharedModule.pushAlert(`Error: ${data}`, 'warning')
          }
          console.log (data)
        })
      )
      .toPromise().catch((err) => {
        this.sharedModule.pushAlert(err.error.message, 'warning')
    })
  }
}
