<ng-container *ngIf="(group && accessLevel > 0) || newGroup">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="flex-container" style="align-items: end">
      <div class="form-control w-50" [ngClass]="{invalid: form.get('name').touched && form.get('name').invalid}">
        <label for="name">
          {{ 'groups.name' | translate }}
          <small *ngIf="form.get('name').touched && form.get('name').invalid" class="red">
            {{ 'groups.nameRequired' | translate }}
          </small>
        </label>
        <input id="name" formControlName="name" type="text">
      </div>

      <div class="pinned-right" style="align-self: start">
        <ng-template [ngIf]="group.home">
          <fa-icon [icon]="sharedModule.faHome" class="active-element" size="lg" title="{{'groups.homeDescr' | translate}}"
          ></fa-icon>
        </ng-template>
      </div>
    </div>

    <div class="flex-container">
      <label for="comment">{{ 'groups.comment' | translate }}</label>
      <div class="form-control w-100">
        <textarea id="comment" formControlName="comment" type="text"></textarea>
      </div>
    </div>
    <!--    <hr>-->
    <div class="flex-container vertical">
      <div>
        <label class="ml-02">{{'groups.access' | translate}}</label>
      </div>
      <div>
        <mat-slide-toggle formControlName="allowAccess">
          <span *ngIf="form.get('allowAccess').value; else allowAccessOff">
              {{ 'groups.allowAccessOn' | translate }}
            </span>
          <ng-template #allowAccessOff>
            {{ 'groups.allowAccessOff' | translate }}
          </ng-template>
        </mat-slide-toggle>
      </div>
    </div>
    <div *ngIf="group.users" class="flex-container w-100 vertical">
      <div>
        <div formArrayName="users">
          <div *ngFor="let user of form.get('users').value; let i = index" [formGroupName]="i" class="flex-container user-container align-start pt-02">
            <input type="hidden" formControlName="id">
            <input type="hidden" formControlName="login">
            <div class="w-25">
              <div class="note note-default">
                {{user.login}}
              </div>
            </div>
            <div class="w-25 ml-1">
              <select formControlName="level" style="min-width: 200px">
                <ng-container *ngFor="let level of accessLevels; last as isLast">
                  <option *ngIf="!isLast && user.level !== 9" [ngValue]="level">
                    {{ 'farmAccessLevels.names.' + level | translate}}
                  </option>
                  <option *ngIf="isLast && user.level === 9" [ngValue]="level">
                    {{ 'farmAccessLevels.names.' + level | translate}}
                  </option>
                </ng-container>
              </select>
            </div>
            <div class="pinned-right">
              <fa-icon
                *ngIf="user.level !== 9 && (user.login === login || (accessLevel >= 8 && accessLevel > user.level))" [icon]="sharedModule.faUserTimes"
                class="active-element" size="lg" (click)="removeUser(user.id)"
                       title="{{'groups.removeUser' | translate}}"
              ></fa-icon>
            </div>

          </div>
        </div>
<!--        {{group | json}}-->
        <div *ngIf="group.home === false && form.get('newLogin') && form.get('newLevel'); else addUserButton" class="flex-container align-start pt-02 pb-02">
          <div class="w-25">
            <input type="text" formControlName="newLogin">
          </div>
          <div class="w-25 ml-1">
            <select formControlName="newLevel" style="min-width: 200px">
              <ng-container *ngFor="let level of accessLevels; last as isLast">
                <option *ngIf="!isLast" [ngValue]="level">
                  {{ 'farmAccessLevels.names.' + level | translate}}
                </option>
              </ng-container>
            </select>
          </div>
          <div class="pinned-right">
            <fa-icon [icon]="sharedModule.faUserPlus" class="active-element mr-05" size="lg" (click)="addUser()"
                     title="{{'groups.addUser' | translate}}"
            ></fa-icon>
            <fa-icon [icon]="this.sharedModule.faUserMinus" (click)="removeNewUserInputs()" class="active-element pinned-right ml-1"
                     title="{{ 'close' | translate }}" size="lg"
            ></fa-icon>
          </div>
        </div>
        <ng-template #addUserButton>
          <ng-container *ngIf="group.home === false && accessLevel >= 8">
            <fa-icon [icon]="sharedModule.faUserPlus" class="active-element" size="lg" (click)="addNewUserInputs()"
              title="{{'groups.addUser' | translate}}"
            ></fa-icon>
          </ng-container>
        </ng-template>
      </div>
    </div>

    <div class="flex-container w-100">
      <div class="form-control flex-container vertical">
        <label class="">{{'groups.teleNotifications' | translate}}</label>
        <mat-slide-toggle formControlName="t_not_on" (click)="onToggle()">
          <span *ngIf="form.get('t_not_on').value; else t_notificationsOff">
            {{ 'groups.teleNotificationsTypes.on' | translate }}
          </span>
          <ng-template #t_notificationsOff>
            {{ 'groups.teleNotificationsTypes.off' | translate }}
          </ng-template>
        </mat-slide-toggle>
        <mat-slide-toggle formControlName="t_not_hour">
          {{ 'groups.teleNotificationsTypes.hourly' | translate }}
        </mat-slide-toggle>
        <mat-slide-toggle formControlName="t_not_offline">{{ 'groups.teleNotificationsTypes.rigOffline' | translate }}</mat-slide-toggle>
        <mat-slide-toggle formControlName="t_not_online">{{ 'groups.teleNotificationsTypes.rigOnline' | translate }}</mat-slide-toggle>
      </div>

      <div class="form-control pinned-right">
        <label for="language">{{ 'groups.language' | translate }}</label>
        <select formControlName="language" id="language" class="pinned-right" style="width: auto">
          <option *ngFor="let lang of environment.locales" [ngValue]="lang.locale">{{lang.description}}</option>
        </select>
      </div>
    </div>

    <div *ngIf="group.users" class="flex-container w-100">
      {{ 'groups.teleNotificationsStart' | translate}}&nbsp;<b>/start {{group.tele_code}}</b>
      <fa-icon [icon]="this.sharedModule.faCopy" class="active-element ml-02"
               appCopyOnClick="/start {{group.tele_code}}" title="{{ 'copyOnClick' | translate }}">
      </fa-icon>
    </div>
    <div *ngIf="showHelp" class="flex-container vertical bordered">
      <div class="flex-container">
        <label class="ml-02">{{'groups.accessDescription' | translate}}</label>

        <fa-icon [icon]="this.sharedModule.faTimes" (click)="toggleShowHelp()" class="active-element pinned-right mr-02"
                 title="{{ 'closeHelp' | translate }}" size="lg"
        ></fa-icon>
      </div>
      <div *ngFor="let level of accessLevels" class="flex-container">
        <div style="min-width: 120px">
          <span class="note note-default">{{'farmAccessLevels.names.' + level | translate}}</span>
        </div>
        <div class="ml-1">{{'farmAccessLevels.descriptions.' + level | translate}}</div>
      </div>
    </div>

    <div class="flex-container form-buttons">
      <button class="btn btn-default" [routerLink]="['/groups']">{{ 'back' | translate }}</button>
      <ng-template [ngIf]="! newGroup">
        <button class="btn btn-default" (click)="toggleShowHelp()" type="button">
          {{'help' | translate}}
          <fa-icon [icon]="this.sharedModule.faInfoCircle" title="{{ 'showHelp' | translate }}" size="lg" class="ml-02"></fa-icon>
        </button>
        <button *ngIf="!group.home " class="btn btn-danger" type="button"
          title="{{'groups.deleteGroup' | translate}}"
          (click)="deleteGroup()"
        >
          <fa-icon [icon]="this.sharedModule.faTrash"></fa-icon>
        </button>
      </ng-template>
      <button [disabled]="!form.valid" type="submit" class="btn btn-success pinned-right mr-0">{{'save' | translate}}</button>
    </div>
  </form>
</ng-container>

