import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {AutofanNapalmCfg} from '../../interfaces';
import {FormBuilder, FormGroup, Validators} from '@angular/forms'

@Component({
  selector: 'app-edit-tuning-autofan-napalm',
  templateUrl: './edit-tuning-autofan-napalm.component.html'
})
export class EditTuningAutofanNapalmComponent implements OnInit {
  @Input() data: AutofanNapalmCfg
  @Output() formSubmit = new EventEmitter()
  section = 'autofan_napalm'
  form: FormGroup
  simpleControls = [
    'target_temp',
    'target_mtemp',
    'pwm_min',
    'pwm_max',
    'pwm_manual',
    'delay'
  ]
  // radioControls = [ 'operating_mode' ]

  // boolControls = [
  //   'fan_mode'
  // ]
  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      target_temp: [50, [
        Validators.required,
        Validators.min(0),
        Validators.max(255)
      ]
      ],
      target_mtemp: [90, [
        Validators.min(0),
        Validators.max(255)
      ]
      ],
      operating_mode: [0, [
        Validators.min(0),
        Validators.max(2)
      ]],
      pwm_min: [0, [
        Validators.min(0),
        Validators.max(30)
      ]],
      pwm_max: [100, [
        Validators.min(50),
        Validators.max(100)
      ]],
      pwm_manual: [50, [
        Validators.min(0),
        Validators.max(100)
      ]],
      delay: [5, [
        Validators.min(1),
        Validators.max(60)
      ]],
    })

    if (this.data) {
      this.form.patchValue(this.data)
    }
    // this.form.get('fan_mode').setValue(this.data.fan_mode === 2)
  }

  submit() {
    let data = {
      autofan_napalm: this.form.value
    }
    // if (data.autofan_napalm.fan_mode === true) {
    //   data.autofan_napalm.fan_mode = 2;
    // } else {
    //   data.autofan_napalm.fan_mode = 1;
    // }
    this.formSubmit.emit(data)
  }
}
