import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OsDataMiner, Profile, Rig} from '../../interfaces';
import {SharedModule} from '../../shared.module';
import {MatDialog} from '@angular/material/dialog';
import {ShowUsingRigsComponent} from '../show-using-rigs/show-using-rigs.component';

@Component({
  selector: 'app-manifest-row',
  templateUrl: './manifest-row.component.html',
  styleUrls: ['./manifest-row.component.scss']
})
export class ManifestRowComponent implements OnInit {
  @Input() manifest: Profile
  @Input() osDataMiners: OsDataMiner[]
  @Input() manifestNumber: number
  @Input() controls = false
  @Input() addNew = false
  @Input() loading = false
  @Input() showUsing = false
  @Output() controlClicked = new EventEmitter()
  walletLength = 17 // default
  poolLength = 40 // default
  constructor(
    public sharedModule: SharedModule,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    if (window.screen.width < 350) {
      this.walletLength = Math.floor(window.screen.width / 24)
      this.poolLength = Math.floor(window.screen.width / 16)
    }
    // console.log (this.walletLength, this.poolLength)
  }
  controlOnClick($event: MouseEvent, controlName: string) {
    // console.log (this.manifest)
    $event.stopPropagation() // that works!
    this.controlClicked.emit(controlName)
  }

  showRigs($event: MouseEvent, onlineRigs: Rig[], offlineRigs: Rig[]) {
    let rigs: Rig[] = []
    if (onlineRigs) {
      onlineRigs.map(el => {
        el.nonActive = false
        return el
      })
      rigs = [...rigs, ...onlineRigs]
    }
    if (offlineRigs) {
      offlineRigs.map(el => {
        el.nonActive = true
        return el
      })
      rigs = [...rigs, ...offlineRigs]
    }
    $event.stopPropagation() // that works!
    this.dialog.open(ShowUsingRigsComponent, {
      panelClass: 'custom-dialog-container',
      data: rigs
      // maxWidth: undefined
    })
  }

  getMinerDescription(minerName: string): string {
    const index = this.osDataMiners.findIndex(el => el.name === minerName)
    return index !== -1 ? this.osDataMiners[index].description : minerName
  }
}
