import {Pipe, PipeTransform} from '@angular/core';
import {Profile} from '../interfaces';

@Pipe({
  name: 'filterProfiles'
})
export class FilterProfilesPipe implements PipeTransform {
  transform(profiles: Profile[], search = ''): Profile[] {
    if (!search.trim()) {
      return profiles
    }

    return profiles.filter(profile => {
      if (profile.miner && profile.miner.toLowerCase().includes(search.toLowerCase())) {
        return true
      } else if (profile.name && profile.name.toLowerCase().includes(search.toLowerCase())) {
        return true
      } else if (profile.algo && profile.algo.toLowerCase().includes(search.toLowerCase())) {
        return true
      } else if (profile.wallet && profile.wallet.toLowerCase().includes(search.toLowerCase())) {
        return true
      } else if (profile.pool && profile.pool.toLowerCase().includes(search.toLowerCase())) {
        return true
      }
      return false
    })
  }
}
