import {Injectable} from '@angular/core'
import {HttpClient, HttpParams} from '@angular/common/http'
import {Observable} from 'rxjs'
import {FarmOC, OcAmd, OcNvidia} from '../interfaces'
import {SharedModule} from '../shared.module'

@Injectable({providedIn: 'root'})
export class OcService {
  constructor(
    private http: HttpClient,
    private sharedModule: SharedModule
  ) {}

  fetchAll(farmId: number, data?: string, brand?: string): Observable<FarmOC> {
    const apiUrl = this.sharedModule.apiUrl
    let url = `${apiUrl}/farms/${farmId}/oc`
    if (brand) {
      url += `/${brand}`
    }
    if (data) {
      let params = new HttpParams()
      if (data) {
        params = params.append('view', data)
      }
      return this.http.get<FarmOC>(url, {params})
    } else {
      return this.http.get<FarmOC>(url)
    }
  }

  fetch(farmId: number, brand: string, ocId: number, data?: string): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    let url = `${apiUrl}/farms/${farmId}/oc/${brand}/${ocId}`
    if (data) {
      const params = new HttpParams().set('view', data)
      return this.http.get<any>(url, {params})
    } else {
      return this.http.get<any>(url)
    }
  }

  patch(farmId: number, brand: string, ocId: number, data: OcAmd | OcNvidia): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    let url = `${apiUrl}/farms/${farmId}/oc/${brand}/${ocId}`
    return this.http.patch(url, data)
  }

  create(farmId: number, brand: string, data: OcAmd | OcNvidia): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    let url = `${apiUrl}/farms/${farmId}/oc/${brand}`
    // console.log ('patch data', data)
    return this.http.put(url, data)
  }

  delete(farmId: number, brand: string, data: number | number[]): Observable<string|null> {
    const apiUrl = this.sharedModule.apiUrl
    let url = `${apiUrl}/farms/${farmId}/oc/${brand}`
    if (! Array.isArray(data)) {
      data = [data]
    }
    return this.http.request<string|null>('DELETE', url, {body: {data}})
  }
}
