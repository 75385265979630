import {Injectable} from '@angular/core'
import {HttpClient, HttpParams} from '@angular/common/http'
import {Observable} from 'rxjs'
import {Changelog, Iso, OsDataAlgo, OsDataMiner, OsVersion} from '../interfaces';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class OsService {
  constructor(
    private http: HttpClient
  ) {}

  get apiUrl(): string {
    const url = window.location.href
    if (url.indexOf('https://eu.os.dog/') === 0) {
      return 'https://eu.os.dog/api'
    }
    return environment.apiUrl
  }

  getMiners(data?: string): Observable<OsDataMiner[]> {
    const apiUrl = this.apiUrl
    const url = `${apiUrl}/os/miners`
    if (data) {
      const params = new HttpParams().set('request', data)
      return this.http.get<OsDataMiner[]>(url, {params})
    } else {
      return this.http.get<OsDataMiner[]>(url)
    }
  }

  getSystem(data?: string): Observable<OsVersion[]> {
    const apiUrl = this.apiUrl
    const url = `${apiUrl}/os/system`
    if (data) {
      const params = new HttpParams().set('request', data)
      return this.http.get<OsVersion[]>(url, {params})
    } else {
      return this.http.get<OsVersion[]>(url)
    }
  }

  getIso(data?: string): Observable<Iso[]> {
    const apiUrl = this.apiUrl
    const url = `${apiUrl}/os/iso`
    if (data) {
      const params = new HttpParams().set('request', data)
      return this.http.get<Iso[]>(url, {params})
    } else {
      return this.http.get<Iso[]>(url)
    }
  }

  getProfilesExamples(): Observable<any> {
    const apiUrl = this.apiUrl
    const url = `${apiUrl}/os/profiles-examples`
    return this.http.get(url)
  }

  private getData(method: string, locale?: string, limit?: number, offset?: number): Observable<Changelog[]> {
    const apiUrl = this.apiUrl
    const url = `${apiUrl}/os/${method}`;
    let params = new HttpParams()
    if (locale) {
      params = params.append('locale', locale)
    }
    if (limit) {
      params = params.append('limit', '' + limit)
    }
    if (offset) {
      params = params.append('offset', '' + offset)
    }
    return this.http.get<Changelog[]>(url, {params})
  }

  getChangelog(locale?: string, limit?: number, offset?: number): Observable<Changelog[]> {
    return this.getData('changelog', locale, limit, offset)
  }

  getOsChangelog(locale?: string, limit?: number, offset?: number): Observable<Changelog[]> {
    return this.getData('os_changelog', locale, limit, offset)
  }

  getAlgos(): Observable<OsDataAlgo[]> {
    const apiUrl = this.apiUrl
    const url = `${apiUrl}/os/algos`;
    return this.http.get<OsDataAlgo[]>(url)
  }

  postChangelog(data: any): Observable<any> {
    const apiUrl = this.apiUrl
    const url = `${apiUrl}/os/changelog`
    return this.http.post(url, data)
  }
}
