import { Component, OnInit } from '@angular/core';
import {OsService} from '../shared/services/os.service';
import {OsDataMiner, OsVersion} from '../shared/interfaces';
import {Observable} from 'rxjs';
import {SharedModule} from '../shared/shared.module';
import {MatDialog} from '@angular/material/dialog';
import {SignUpComponent} from '../shared/components/sign-up/sign-up.component';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['main-page.component.scss']
})
export class MainPageComponent implements OnInit {
  miners$: Observable<OsDataMiner[]>
  osData$: Observable<OsVersion[]>
  constructor(
    private osService: OsService,
    private dialog: MatDialog,
    public sharedModule: SharedModule
  ) { }

  createRange(count: number){
    let items: number[] = []

    for (let i = 0; i < count; i++){
      items.push(i)
    }
    return items
  }

  ngOnInit(): void {
    this.sharedModule.setTitle('OSdog', true, '')
    this.miners$ = this.osService.getMiners()
    this.osData$ = this.osService.getSystem()
  }

  openSignUpWindow() {
    this.dialog.open(SignUpComponent, {
      panelClass: 'custom-dialog-container',
      maxWidth: undefined
    })
  }
}
