import { AbstractControl } from '@angular/forms';
export function ValidateSpecialChars(control: AbstractControl) {
  const format = /[ №\`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/

  // console.log (format.test(control.value))
  if (format.test(control.value)) {
    return { forbiddenChars: true }
  }
  return null;
}
