<div id="main">
  <form [formGroup]="form" (ngSubmit)="submit()" style="padding: 0.5rem">
    <div class="flex-container">
      <div class="form-control flex-container vertical">
        <mat-slide-toggle *ngFor="let controlName of boolControls" [formControlName]="controlName">
          <ng-container *ngIf="!(controlName === 'enabled' && ! form.get(controlName).value); else disabled">
            {{ 'tuning.' + section + '.keys.' + controlName  | translate }}
          </ng-container>
          <ng-template #disabled>
            {{ 'tuning.' + section + '.keys.' + 'disabled'  | translate }}
          </ng-template>
        </mat-slide-toggle>
      </div>
    </div>

    <div *ngFor="let controlName of simpleControls" class="flex-container w-50">
      <label [for]="controlName">{{ 'tuning.' + section + '.keys.' + controlName | translate }}</label>
      <div class="form-control w-100">
        <input [id]="controlName" [formControlName]="controlName" type="number" appOnlyNumbers>
        <app-input-validation [component]="form.get(controlName)"></app-input-validation>
      </div>
    </div>
    <div *ngIf="form.get('noRebootIfNoNet').value === 1 || form.get('noRebootIfNoNet').value === true" class="flex-container w-50">
      <label for="noNetRebootTime">{{ 'tuning.' + section + '.keys.noNetRebootTime' | translate }}</label>
      <div class="form-control w-100">
        <input id="noNetRebootTime" formControlName="noNetRebootTime" type="number" appOnlyNumbers>
        <app-input-validation [component]="form.get('noNetRebootTime')"></app-input-validation>
      </div>
    </div>

    <div formGroupName="hr" *ngIf="form.controls.hr.value.length">
      <hr>
      <div *ngFor="let hrRow of form.controls.hr.value; index as i; trackBy: trackByFn" class="flex-container hr-container form-control"
        [formArrayName]="i"
      >
        <label>{{findDescription(hrRow.algo)}}</label>
        <div class="flex-container no-wrap align-center">
          <input type="hidden" formControlName="algo">
          <input type="text" formControlName="hr">
          <input type="text" class="unit"  value="{{'units.' + getUnit(hrRow.algo) | translate}}" READONLY>
          <input type="hidden" formControlName="x">
          <fa-icon [icon]="this.sharedModule.faMinusSquare" class="active-element" size="2x" style="margin-left: 0.5rem"
            (click)="removeAlgo(hrRow.algo)"></fa-icon>
        </div>
      </div>
    </div>
    <hr>
    <label for="minerSelect">{{ 'tuning.' + section + '.keys.addAlgo' | translate }}</label>
    <div class="flex-container " style="align-items: center">
      <div class="form-control">
        <select id="minerSelect" formControlName="algoSelect">
          <option *ngFor="let el of algos" [ngValue]="el.algo">{{el.description}}</option>
        </select>
      </div>
      <div class="form-control">
        <fa-icon [icon]="this.sharedModule.faPlusSquare" class="active-element ml-0" size="2x"
          (click)="addAlgo(form.get('algoSelect').value)"></fa-icon>
      </div>

    </div>
    <div class="flex-container w-100">
      <i>{{ 'tuning.' + section + '.description' | translate }}</i>
    </div>
    <div class="flex-container form-buttons">
      <button type="submit" class="btn btn-success pinned-right mr-0"
        [disabled]="!form.valid"
      >{{'save' | translate}}</button>
    </div>
  </form>
</div>
