import { AbstractControl } from '@angular/forms';
export function ValidateObject(control: AbstractControl) {
  if (control.value.length < 2) {
    return { isObject: true }
  } else {
    try {
      let data = JSON.parse(control.value)
      if (typeof(data) !== 'object') {
        return { isObject: true }
      }
    } catch (err) {
      return { isObject: true }
    }

    // console.log (data)
  }
  return null;
}
