<div class="container-wide" *ngIf="!loading; else appLoading">
  <div class="flex-container nav-panel">
    <a class="menu-link tab" *ngFor="let farm of farms"
       [routerLink]="['/', 'oc', farm.id]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
       (click)="fetchOcs(farm.id)">
      {{ farm.name }}
    </a>
  </div>

  <div class="container">
    <div class="ml-0 mt-0 sub-nav-panel-wrapper">
      <div class="nav-panel sub-nav-panel">
        <div class="menu-link tab" [ngClass]="{active: brand === 'amd'}" (click)="onGroupSelect('amd')">{{'oc.amd' | translate}}</div>
        <div class="menu-link tab" [ngClass]="{active: brand === 'nvidia'}" (click)="onGroupSelect('nvidia')">{{'oc.nvidia' | translate}}</div>
      </div>
    </div>
    <div class="container mt-0 w-100 p-0">
      <div class="card mt-02 card-content">
          <ng-container *ngIf="!ocLoading; else appLoading">
            <ng-container *ngIf="ocProfiles[brand].length > 0; else noProfiles">
              <div class="flex-container align-center flex-grow-1 mb-01">
                <div>
                  <input type="checkbox" class="custom-checkbox" id="checkAll" value="1" [checked]="checkAll === true" (change)="onCheckAll()">
                  <label for="checkAll"></label>
                </div>
                <div class="flex-grow-1 flex-container align-center">
                  <div class="flex-grow-1">
                    <input type="text" placeholder="{{'manifests.findProfile' | translate}}"
                      (change)="nullifyCheckboxes()" [(ngModel)]="searchStr">
                  </div>
                  <div *ngIf="selectedCheckBoxes.length > 0" class="pinned-right pt-02">
                    <button class="btn btn-default mr-0 ml-02"
                      title="{{'manifests.deleteSelectedProfiles' | translate}}"
                      (click)="deleteProfiles()"
                    >
                      <fa-icon [icon]="this.sharedModule.faTrash"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
              <form [formGroup]="form">
                <div *ngFor="let oc of ocProfiles[brand] | filterOc:searchStr" class="flex-container align-center">
                  <div>
                    <input type="checkbox" class="custom-checkbox" [formControlName]="oc.id" [id]="oc.id" value="1"
                      (change)="onChangeCheckBox()">
                    <label [for]="oc.id"></label>
                  </div>
                  <app-oc-row class="flex-grow-1" [oc]="oc" [brand]="brand"></app-oc-row>
                </div>
              </form>
            </ng-container>

            <ng-template #noProfiles>
              {{'oc.noProfiles' | translate}}
            </ng-template>
          </ng-container>
      </div>
    </div>
  </div>

</div>
<ng-template #appLoading>
  <app-loading></app-loading>
</ng-template>
