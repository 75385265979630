import { Component } from '@angular/core';
import {Observable} from 'rxjs';
import {Rig, RigCommand} from '../../../shared/interfaces';
import {RigComponent} from '../../rig.component';
import {first, map, switchMap} from 'rxjs/operators';
import {SharedModule} from '../../../shared/shared.module';

@Component({
  selector: 'app-rig-actions',
  templateUrl: './rig-actions.component.html',
  styleUrls: ['./rig-actions.component.scss']
})
export class RigActionsComponent {
  data$: Observable<Rig>
  subMethod = 'commands'
  subMethods = [
    'commands',
    'amdBios',
    'nvidiaBios'
  ]
  biosUploaded = false

  constructor(
    public rigComponent: RigComponent,
    private sharedModule: SharedModule
    // private rigService: RigsService
  ) {
  }

  onClick(subMethod: string) {
    if (subMethod !== this.subMethod) {
      this.subMethod = subMethod
      if (subMethod !== 'commands' && this.subMethods.indexOf(subMethod) > -1) {
        let requestType = 'amdBios' // default

        if (subMethod === 'nvidiaBios') {
          requestType = 'nvidiaBios'
        }

        this.data$ = this.rigComponent.route.params
          .pipe(
            first(),
            switchMap((params) => {
              // console.log ('switch, switch baby')
              return this.rigComponent.rigsService.get(params.id, requestType)
            })
          )
      }
    }
  }


  sendCommand(data: RigCommand) {
    if (data) {
     this.rigComponent.route.params
        .pipe(
          first(),
          map((params) => {
            // console.log ('commands', params.id, data)
            return this.rigComponent.rigsService.command(params.id, data)
          })
        )
       .toPromise()
    }
  }

  biosUpload(data: FormData) {
    this.biosUploaded = true
    // console.log ('After BIOS upload', data)
    if (data) {
      this.rigComponent.route.params
        .pipe(
          first(),
          switchMap((params) => {
            return this.rigComponent.rigsService.upload(params.id, 'bios', data)
          })
        )
        .toPromise()
        .finally(() => {
          this.biosUploaded = false
        })
        .catch((error) => {
        this.sharedModule.pushAlert(`Error: ${error.error.message}`, 'warning')
      })
    }
  }
}
