import { Component, OnInit } from '@angular/core';
import {AccountService} from '../shared/services/account.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {Account} from '../shared/interfaces';
import {map, switchMap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {ChangePasswordComponent} from '../shared/components/change-password/change-password.component';
import {of} from 'rxjs';
import {TwofaSetupComponent} from '../shared/components/twofa-setup/twofa-setup.component';
import {TwofaConfirmDeleteComponent} from '../shared/components/twofa-confirm-delete/twofa-confirm-delete.component';

@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html'
})
export class AccountPageComponent implements OnInit {
  form: FormGroup
  accountData: Account
  commandSent = false

  constructor(
    private accountService: AccountService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public sharedModule: SharedModule
  ) { }

  ngOnInit(): void {
    this.sharedModule.setTitle('links.account', true)

    this.form = this.fb.group({
      login: [{
        value: '',
        disabled: true
      }],
      regdate: [{
        value: '',
        disabled: true
      }],
      mail: ['', [
        Validators.required,
        Validators.email
      ]],
      name: ['', [
        // Validators.minLength(1),
        // Validators.pattern('[_0-9a-zA-Z]*')
      ]],
      telegram: [''],
      twofa_enabled: [false],
    })

    this.accountService.get()
      .pipe(
        map((data) => {
          this.accountData = data
          this.form.patchValue(data)
        })
      )
      .toPromise()
  }

  submit() {
    this.commandSent = true
    console.log(this)
  }

  onToggle() {

  }

  // showForm() {
  //   console.log(this.form)
  // }

  confirmMail() {
    this.commandSent = true
    this.accountService.sendMailConfirmation()
      .pipe(
        map(data => {
          if (data === 'OK') {
            this.sharedModule.pushAlert(`Confirmation link sent to email`, 'success')
            this.commandSent = false
          } else {
            this.sharedModule.pushAlert(`Error: ${data}`, 'warning')
            this.commandSent = false
          }
        })
      ).toPromise().catch((error) => {
        this.sharedModule.pushAlert(`Error: ${error.error.message}`, 'warning')
        this.commandSent = false
      })
  }

  changePassword() {
    let dialogRef = this.dialog.open(ChangePasswordComponent, {
      panelClass: 'custom-dialog-container',
      maxWidth: undefined,
    })

    dialogRef.afterClosed()
      .pipe(
        switchMap((data) => {
          if (data) {
            return this.accountService.changePassword(data)
              .pipe(
                map((result) => {
                  if (result === 'OK') {
                    this.sharedModule.pushAlert(`Password changed`, 'success')
                  } else {
                    this.sharedModule.pushAlert(`Error: ${result}`, 'warning')
                  }
                })
              )
          } else {
            return of()
          }
      })).toPromise().catch((error) => {
      // console.log ('Error while insert', error.error.message)
      this.sharedModule.pushAlert(`Error: ${error.error.message}`, 'warning')
    })
  }

  twoFaClicked(event) {
    event.preventDefault()
    const oldValue = this.form.get('twofa_enabled').value
    if (oldValue === false) {
      let dialogRef = this.dialog.open(TwofaSetupComponent, {
        panelClass: 'custom-dialog-container',
        maxWidth: undefined,
        data: this.accountService.getTwoFA()
      })

      dialogRef.afterClosed()
        .pipe(
          switchMap((data) => {
            if (data) {
              return this.accountService.setupTwoFa(data)
                .pipe(
                  map((result) => {
                    if (result === 'OK') {
                      this.form.get('twofa_enabled').setValue(true)
                      this.sharedModule.pushAlert(`2FA enabled`, 'success')
                    } else {
                      this.sharedModule.pushAlert(`Error: ${result}`, 'warning')
                    }
                  })
                )
            } else {
              return of()
            }
          })).toPromise().catch((error) => {
        // console.log ('Error while insert', error.error.message)
        this.sharedModule.pushAlert(`Error: ${error.error.message}`, 'warning')
      })
    } else {
      let dialogRef = this.dialog.open(TwofaConfirmDeleteComponent, {
        panelClass: 'custom-dialog-container',
        maxWidth: undefined,
        data: this.accountService.getTwoFA()
      })

      dialogRef.afterClosed()
        .pipe(
          switchMap((data) => {
            if (data) {
              return this.accountService.disableTwoFa(data)
                .pipe(
                  map((result) => {
                    if (! result) {
                      this.form.get('twofa_enabled').setValue(false)
                      this.sharedModule.pushAlert(`2FA disabled`, 'success')
                    } else {
                      this.sharedModule.pushAlert(`Error: ${result}`, 'warning')
                    }
                  })
                )
            } else {
              return of()
            }
          })).toPromise().catch((error) => {
        // console.log ('Error while insert', error.error.message)
        this.sharedModule.pushAlert(`Error: ${error.error.message}`, 'warning')
      })
    }
  }
}
