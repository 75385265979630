<div class="container">
  <div *ngIf="!loading && invitations" class=" invitations-container">
    <div *ngFor="let invitation of invitations" class="card card-content invitation" style="flex-basis: unset !important;">
      <div class="flex-container vertical">
        <div>
          {{'groups.inviteToGroup' | translate}}
          <b>{{invitation.name}}</b>
        <div>
          {{'groups.role' | translate}}
          <b>{{'farmAccessLevels.names.' + invitation.level | translate}}</b>
          ({{'farmAccessLevels.descriptions.' + invitation.level | translate}})
        </div>
        </div>
        <div class="pinned-right mr-0">
          <button class="btn btn-default" (click)="declineInvitation(invitation.id)">{{'cancel' | translate}}</button>
          <button class="btn btn-default mr-0" (click)="acceptInvitation(invitation.id)">{{'accept' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!loading; else appLoading" class="flex-container" id="group-container">
    <div class="card active-element" *ngFor="let group of groups" (click)="openGroup(group.id)">
      <div class="card-label">
        {{group.name}}
        <ng-template [ngIf]="group.home">
          <fa-icon [icon]="this.sharedModule.faHome" class="pinned-right gold"
             title="{{'groups.homeDescr' | translate}}"
          ></fa-icon>
        </ng-template>
      </div>

      <div class="card-content flex-container vertical" style="height: 97%; padding-bottom: 25px; margin-bottom: 25px">
        <div>
          <i>{{group.comment}}</i>
        </div>
        <div style="margin-top: auto; margin-bottom: 5px">
          <div class="flex-container">
            <div [ngSwitch]="group.type">
              <fa-icon *ngSwitchCase="0" [icon]="this.sharedModule.faShieldDog" class="gold" size="lg"
                title="{{'groups.types.free' | translate}}"
              ></fa-icon>
              <span *ngSwitchCase="1" class="note note-default">
                <fa-icon *ngIf="group.home" [icon]="this.sharedModule.faPercent"
                         title="{{'groups.types.feeHome' | translate}}"
                ></fa-icon>
                <fa-icon *ngIf="!group.home" [icon]="this.sharedModule.faPercent"
                         title="{{'groups.types.fee' | translate}}"
                ></fa-icon>
              </span>

              <span *ngSwitchCase="2" class="note note-default">
                <fa-icon *ngIf="group.home" [icon]="this.sharedModule.faDollar"
                          title="{{'groups.types.chargeHome' | translate}}"
                ></fa-icon>
                <fa-icon *ngIf="!group.home" [icon]="this.sharedModule.faDollar"
                          title="{{'groups.types.charge' | translate}}"
                ></fa-icon>
              </span>
            </div>
            <div *ngIf="group.type === 2" class="pinned-right">
              {{group.balance / 1000 | number: '1.2-2'}}$
            </div>
<!--            <div *ngIf="group.rigs$ | async as rigs" title="{{ 'groups.rigsCount' | translate }}">-->
<!--              {{rigs.length}} <fa-icon [icon]="this.sharedModule.faCube"></fa-icon>-->
<!--            </div>-->
<!--            <div class="pinned-right" title="{{ 'groups.usersCount' | translate }}">-->
<!--              {{group.users.length}} <fa-icon [icon]="this.sharedModule.faUsers"></fa-icon>-->
<!--            </div>-->
          </div>
        </div>
        <div style="margin-bottom: 5px">
          <div class="flex-container">
            <div *ngIf="group.rigs$ | async as rigs" title="{{ 'groups.rigsCount' | translate }}">
              {{rigs.length}} <fa-icon [icon]="this.sharedModule.faCube"></fa-icon>
            </div>
            <div class="pinned-right" title="{{ 'groups.usersCount' | translate }}">
              {{group.users.length}} <fa-icon [icon]="this.sharedModule.faUsers"></fa-icon>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
  <div class="flex-container">
    <button class="btn btn-default mr-0"
      [routerLink]="['/groups', 'new']"
    >
      <fa-icon [icon]="sharedModule.faPlusSquare" class="active-element"
        title="{{'groups.createGroup' | translate}}"
      ></fa-icon>
    </button>
  </div>

  <ng-template #appLoading>
    <app-loading></app-loading>
  </ng-template>
</div>
