import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Group} from '../../../shared/interfaces';
import {EMPTY, from, Observable, Subscription} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FilterRigsPipe} from '../../../shared/pipes/filter-rigs';
import {SharedModule} from '../../../shared/shared.module';
import {MatDialog} from '@angular/material/dialog';
import {CreateRigComponent} from '../create-rig/create-rig.component';
import {GroupsService} from '../../../shared/services/groups.service';
import {DeleteConfirmationComponent} from '../../../shared/components/delete-confirmation/delete-confirmation.component';
import {mergeMap, switchMap} from 'rxjs/operators';
import {RigsService} from '../../../shared/services/rigs.service';
import {MoveEntitiesComponent} from '../../../shared/components/move-entities/move-entities.component';

@Component({
  selector: 'app-group-rigs',
  templateUrl: './group-rigs.component.html',
  styleUrls: ['./group-rigs.component.scss']
})
export class GroupRigsComponent implements OnInit, OnDestroy {
  @Input() groupId: number
  @Input() group: Group
  searchStr = ''
  form: FormGroup
  selectedCheckBoxes = []
  checkAll = false
  subs: Subscription[] = []
  constructor(
    private fb: FormBuilder,
    private filterRigs: FilterRigsPipe,
    private changeDetection: ChangeDetectorRef,
    private dialog: MatDialog,
    private groupsService: GroupsService,
    private rigsService: RigsService,
    public sharedModule: SharedModule
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({})
    this.group.rigs.forEach((profile) => {
      this.form.addControl(String(profile.id), this.fb.control(false))
    })
    this.checkAll = false
  }

  onChangeCheckBox() {
    this.selectedCheckBoxes = Object.keys(this.form.value).filter((id) => {
      return (this.form.value[id] === true)
    }).map((id) => +id)

    // working
    // if (this.selectedCheckBoxes.length < this.filterRigs.transform(this.group.rigs, this.searchStr).length) {
    //   this.checkAll = false
    // } else {
    //   this.checkAll = true
    // }
    this.checkAll = this.selectedCheckBoxes.length >= this.filterRigs.transform(this.group.rigs, this.searchStr).length
    this.changeDetection.detectChanges()
  }

  onCheckAll() {
    this.checkAll = !this.checkAll
    Object.keys(this.form.value).forEach((id) => {
      this.form.get(id).setValue(false)
    })
    if (this.checkAll === true) {
      this.filterRigs.transform(this.group.rigs, this.searchStr).forEach((profile) => {
        this.form.get('' + profile.id).setValue(true)
      })
    }
    this.onChangeCheckBox()
  }

  nullifyCheckboxes() {
    this.checkAll = false
    this.selectedCheckBoxes = []
    Object.keys(this.form.value).forEach((id) => {
      this.form.get(id).setValue(false)
    })
    this.onChangeCheckBox()
  }

  async createRig() {
    let dialogRef = this.dialog.open(CreateRigComponent, {
      panelClass: 'custom-dialog-container',
      maxWidth: undefined
    })

    this.subs.push(dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.subs.push(this.groupsService.createRig(this.groupId, data).subscribe((result) => {
          if (result && result.id && +result.id > 0) {
            let rigId = +result.id
            data.id = rigId
            data.gpuCount = 0 // default
            data.refresh = 0 // default
            this.group.rigs.push(data)
            this.form.addControl(String(rigId), this.fb.control(false))
            this.onChangeCheckBox()
            this.sharedModule.pushAlert('Rig created', 'success')
          } else {
            this.sharedModule.pushAlert(`Error: ${result.statusText}`, 'warning')
          }
        }))
      }
    }))
  }

  async openMoveWindow() {
    if (this.selectedCheckBoxes.length > 0) {
      let groups = await this.groupsService.get('short').toPromise()
      let dialogRef = this.dialog.open(MoveEntitiesComponent, {
        panelClass: 'custom-dialog-container',
        data: {
          groups: groups.filter(group => group.id !== this.groupId)
        }
      })

      let sub = dialogRef.afterClosed()
        .pipe(
          switchMap((groupId) => {
            let returnVal: Observable<any>
            if (groupId) {
              let data = {
                group: groupId,
                rigs: this.selectedCheckBoxes
              }
              returnVal = this.groupsService.transferRigs(this.groupId, data)
            } else {
              returnVal = EMPTY
            }
            return returnVal
          })
        ).subscribe((result) => {
          if (result === 'OK') {
            this.selectedCheckBoxes.forEach((id) => {
              this.form.removeControl(id)
              let index = this.group.rigs.findIndex(el => el.id === id)

              if (index !== -1) {
                this.group.rigs.splice(index, 1)
              }
            })
            this.sharedModule.pushAlert('Rigs transferred', 'success')
            this.nullifyCheckboxes()
          } else {
            this.sharedModule.pushAlert(`Error: ${result.statusText}`, 'warning')
          }
          sub.unsubscribe()
        }, result => this.sharedModule.pushAlert(`Error: ${result.statusText}`, 'warning')
      )
    }
  }

  deleteRigs() {
    let count = this.selectedCheckBoxes.length
    if (count > 0) {
      let dialogRef = this.dialog.open(DeleteConfirmationComponent, {
        data: {
          entity: 'rig'
        },
        panelClass: 'custom-dialog-container'
      })

      this.subs.push(dialogRef.afterClosed()
        .pipe(
          switchMap((result) => {
            let returnVal: Observable<any>
            if (result === true) {
              returnVal = from(this.selectedCheckBoxes).pipe(
                mergeMap(rigId => this.rigsService.delete(rigId))
              )
            } else {
              returnVal = EMPTY
            }
            return returnVal
          })
        ).subscribe((result) => {
          if (!result) {
            this.sharedModule.pushAlert('Rig deleted', 'success')
            this.selectedCheckBoxes.forEach((id) => {
              this.form.removeControl(id)
              let index = this.group.rigs.findIndex(el => el.id === id)

              if (index !== -1) {
                this.group.rigs.splice(index, 1)
              }
            })

          } else { // don`t know what should be to trigger that
            this.sharedModule.pushAlert(result, 'warning')
          }
        }, (result) => {
          this.sharedModule.pushAlert(`Error: ${result.statusText}`, 'warning')
        })
      )
    }
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
     sub.unsubscribe()
    })
  }
}
