<div id="main">
  <form [formGroup]="form" (ngSubmit)="submit()" style="padding: 0.5rem">
    <div class="flex-container">
      <div class="form-control flex-container vertical">
        <mat-slide-toggle *ngFor="let controlName of boolControls" [formControlName]="controlName">
          <ng-container *ngIf="!(controlName === 'enabled' && ! form.get(controlName).value); else disabled">
            {{ 'tuning.' + section + '.keys.' + controlName  | translate }}
          </ng-container>
          <ng-template #disabled>
            {{ 'tuning.' + section + '.keys.' + 'disabled'  | translate }}
          </ng-template>
        </mat-slide-toggle>
      </div>
    </div>

    <div *ngFor="let controlName of simpleControls" class="flex-container w-50">
      <label [for]="controlName">{{ 'tuning.' + section + '.keys.' + controlName | translate }}</label>
      <div class="form-control w-100">
        <input [id]="controlName" [formControlName]="controlName" type="number">
      </div>
    </div>

    <div class="flex-container w-100">
      <i>{{ 'tuning.' + section + '.description' | translate }}</i>
    </div>
    <div class="flex-container form-buttons">
      <button type="submit" class="btn btn-success pinned-right mr-0">{{'save' | translate}}</button>
    </div>
  </form>
</div>
