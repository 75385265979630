<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="flex-container vertical">
    <div>
      <label for="farm">
        {{'manifests.selectGroupToCopy' | translate}}
      </label>
      <select formControlName="farm" id="farm" class="mt-05">
        <option *ngFor="let farm of data.farms" [value]="+farm.id">{{farm.name}}</option>
      </select>
    </div>

    <div class="flex-container mt-05">
      <button class="btn btn-default ml-0" mat-dialog-close>{{ 'cancel' | translate }}</button>
      <button class="btn btn-default pinned-right mr-0"
        type="submit"
        title="{{'manifests.copySelectedProfiles' | translate}}"
      >
        <fa-icon [icon]="this.sharedModule.faCopy"></fa-icon>
      </button>
    </div>
  </div>
</form>
