<div *ngIf="rig$ | async as rig; else appLoading" class="card card-content">
  <div *ngIf="!rig.offline && rig.minerStats" class="bordered mt-02">
    <ng-container *ngFor="let miner of rig.minerStats; let minerIndex = index">
<!--      {{miner | json}}-->
      <hr *ngIf="minerIndex > 0 && minerIndex <= rig.minerStats.length - 1">

      <div class="flex-container">
        <ng-template [ngIf]="miner.hr">
          <div *ngFor="let hr of miner.hr; let i = index" class="col-hr flex-container vertical
            {{ (miner.temp && miner.temp[i]) && +miner.temp[i] | tempColorClass: tempLimit }}"
          >
            <div *ngIf="miner.temp && miner.temp[i]">
              {{miner.temp[i]}}°
            </div>
            <div *ngIf="miner.fan && +miner.fan[i] !== undefined" class="note {{ +miner.fan[i] | fanColorClass }}">
              {{miner.fan[i] | displayFan}}
            </div>
<!--            <div>-->
<!--              <span *ngIf="miner.temp && miner.temp[i]">-->
<!--                {{miner.temp[i]}}°-->
<!--              </span>-->
<!--              <span *ngIf="miner.fan && +miner.fan[i] !== undefined">-->
<!--                {{+miner.fan[i]}}%-->
<!--              </span>-->
<!--            </div>-->
            <div>
              {{hr | displayHr:false:true}}
              <ng-container *ngIf="miner.dual && sharedModule.isArray(miner.dual)">
                <ng-container *ngFor="let dualStats of miner.dual">
                  <ng-container *ngIf="dualStats.hr && dualStats.hr[i]">
                    <br>
                    <i style="color: teal">{{dualStats.hr[i] | displayHr:false:true}}</i>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
            <div *ngIf="miner.share && miner.share[i]" class="flex-container center">
              <i>{{miner.share[i]}}</i>
              <fa-icon [icon]="this.sharedModule.faCheckCircle" class="ml-02"
                title="{{miner.share[i]}} {{miner.share[i] | conjugateShares}}"
              ></fa-icon>
            </div>
            <div *ngIf="miner.badshare && miner.badshare[i]" class="flex-container center">
              <i>{{miner.badshare[i]}}</i>
              <fa-icon [icon]="this.sharedModule.faTimesCircle" class="ml-02 color-danger"
                       title="{{miner.badshare[i]}} {{miner.badshare[i] | conjugateShares: 'bad'}}"
              ></fa-icon>
            </div>
            <div *ngIf="miner.invshare && miner.invshare[i]" class="flex-container center">
              <i>{{miner.invshare[i]}}</i>
              <fa-icon [icon]="this.sharedModule.faTimesCircle" class="ml-02 color-warning"
                       title="{{miner.invshare[i]}} {{miner.invshare[i] | conjugateShares: 'invalid'}}"
              ></fa-icon>
            </div>
          </div>
        </ng-template>

        <ng-template [ngIf]="miner.threads_hr">
          <div *ngFor="let hr of miner.threads_hr; let i = index" class="col-hr thread">
            <!--                      <span *ngIf="rig.miner1"></span>-->
            {{hr | displayHr:false:true}}
          </div>
        </ng-template>
      </div>

      <div class="flex-container">
        <div id="miner-name-shares" class="flex-container">
          <div id="miner-name">
            <span *ngIf="miner.miner" class="miner-name note note-default ml-02">
              {{getMinerDescription(miner.miner)}}
              <ng-template [ngIf]="miner.ver">
                {{miner.ver}}
              </ng-template>
            </span>
          </div>

          <div id="miner-share">
            <span *ngIf="miner.total_share" class="note note-success active-element"
                  title="{{miner.algo}}: {{miner.total_share}} {{miner.total_share | conjugateShares}}">
              {{miner.total_share}}
              <fa-icon [icon]="this.sharedModule.faCheckCircle"></fa-icon>
            </span>
          </div>

          <div *ngIf="miner.dual" id="dual-miner-share">
            <ng-container *ngFor="let dualStats of miner.dual">
                <span *ngIf="dualStats.total_share" class="note note-success-light active-element"
                      title="{{dualStats.algo}}: {{dualStats.total_share}} {{dualStats.total_share | conjugateShares}}">
                  {{dualStats.total_share}}
                  <fa-icon [icon]="this.sharedModule.faCheckCircle"></fa-icon>
                </span>
            </ng-container>
          </div>

          <div *ngIf="miner.total_badshare > 0" id="miner-badshare">
            <span class="note note-danger active-element"
                  title="{{miner.algo}}: {{miner.total_badshare}} {{miner.total_badshare | conjugateShares: 'bad'}}">
              {{miner.total_badshare}}
              <fa-icon [icon]="this.sharedModule.faTimesCircle"></fa-icon>
            </span>
          </div>

          <div *ngIf="miner.total_invshare" id="miner-invshare  active-element">
            <span class="note note-warning"
                  title="{{miner.algo}}: {{miner.total_invshare}} {{miner.total_invshare | conjugateShares: 'invalid'}}">
              {{miner.total_invshare}}
              <fa-icon [icon]="this.sharedModule.faTimesCircle" ></fa-icon>
            </span>
          </div>


        </div>
        <div id="miner-hr-online" class="pinned-right flex-container">
          <div id="miner-hr">
            <ng-container *ngIf="miner.total_hr">
              <ng-container *ngIf="miner.coin && sharedModule.coinsLogo[miner.coin]">
                <span class="note note-default active-element" title="{{miner.coin}} ({{miner.algo}})">
                  <img [src]="'assets/img/coins/' + sharedModule.coinsLogo[miner.coin]"
                       [alt]="miner.coin" class="coin-logo mr-02">{{miner.total_hr | displayHr:true: true}}
                </span>
              </ng-container>
              <ng-container *ngIf="!miner.coin || !sharedModule.coinsLogo[miner.coin]">
                <span class="note note-default active-element" [title]="miner.algo">
                  {{miner.algo}} {{miner.total_hr | displayHr:true: true}}
                </span>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="miner.dual ">
              <ng-container *ngFor="let dualStats of miner.dual">
                <span *ngIf="dualStats.total_hr" class="note note-default">
                  {{dualStats.algo}} {{dualStats.total_hr | displayHr:true: true}}
                </span>
              </ng-container>
            </ng-container>
          </div>

          <div id="miner-online" class="pinned-right">
            <span *ngIf="miner.online" class="note note-success">
              {{ miner.online | dateAgo:rigComponent.currentTime }}
            </span>
          </div>
        </div>
      </div>
  </ng-container>
  </div>

  <div class="bordered mt-02" id="gpu-table">
    <table>
    <tr>
      <th class="text-align-left">
        <fa-icon [icon]="this.sharedModule.faHashtag"
           title="{{ 'rig.iDescr' | translate }}"
        ></fa-icon>
      </th>
      <th class="text-align-left">
        <fa-icon [icon]="this.sharedModule.faName"
           title="{{ 'rig.nameDescr' | translate }}"
        ></fa-icon>
      </th>
      <th class="text-align-center">
        <fa-icon [icon]="this.sharedModule.faHr"
          title="{{ 'rig.hrDescr' | translate }}"
        ></fa-icon>
      </th>
      <th class="text-align-center">
      <fa-icon [icon]="this.sharedModule.faSpinner"
          title="{{ 'rig.loadDescr' | translate }}"
      ></fa-icon>
      </th>
      <th class="text-align-center">
      <fa-icon [icon]="this.sharedModule.faTemp"
          title="{{ 'rig.tempDescr' | translate }}"
      ></fa-icon>
      </th>
      <th class="text-align-center">
      <fa-icon [icon]="this.sharedModule.faFan"
          title="{{ 'rig.fanDescr' | translate }}"
      ></fa-icon>
      </th>
      <th class="text-align-center">
      <fa-icon [icon]="this.sharedModule.faCore"
          title="{{ 'rig.coreDescr' | translate }}"
      ></fa-icon>
      </th>
      <th class="text-align-center">
      <fa-icon [icon]="this.sharedModule.faMem"
          title="{{ 'rig.memDescr' | translate }}"
      ></fa-icon>
      </th>
      <th class="text-align-center">
      <fa-icon [icon]="this.sharedModule.faPower"
          title="{{ 'rig.gpuPowerDescr' | translate }}"
      ></fa-icon>
      </th>
    </tr>
    <tr>
      <td colspan="9">
        <hr>
      </td>
    </tr>
    <tbody>
      <ng-container *ngFor="let gpu of rig.gpu_busid; let i = index">
        <tr>
          <td>
            <span title="{{ 'rig.gpu' | translate }} {{i}}">
              <fa-icon [icon]="this.sharedModule.faHashtag"></fa-icon> {{i}}
            </span>
            <br>
            {{rig.gpu_busid[i]}}:00.0
          </td>
          <td>
            <div class="flex-container">
              <div>
                <fa-icon [icon]="this.sharedModule.faDownload" class="mr-02 active-element"
                  title="{{ 'rig.getBios' | translate }}" (click)="getBios(rig.gpu_busid[i], rig.gpu_brand[i])"
                ></fa-icon>
<!--                size="1x" class="mr-02 active-element"-->
                <fa-layers class="mr-05 active-element"
                   title="{{ 'rig.stopFan' | translate }}" (click)="stopFan(rig.gpu_busid[i], rig.gpu_brand[i])">
                  <fa-icon [icon]="this.sharedModule.faFan" stackItemSize="1x"></fa-icon>
                  <fa-icon [icon]="this.sharedModule.faSlash" stackItemSize="1x"></fa-icon>
                </fa-layers>
                <b>
                  <span class="gpu-vendor">{{rig.gpu_vendor[i]}}</span>
                  <span class="ml-02" [ngClass]="rig.gpu_brand[i]">{{rig.gpu_name[i]}}</span>
                </b>
              </div>
              <div class="memsize">
                <i>{{rig.gpu_mem[i]}} Mb</i>
              </div>

            </div>

            <div>
              <span *ngIf="rig.memVendor[i]" class="note note-default ml-0 note-memVendor">
                {{rig.memVendor[i]}}
              </span>
              <i *ngIf="rig.gpu_info[i]">{{rig.gpu_info[i]}}</i>
              <i *ngIf="rig.pl[i]" class="ml-02 secondary-info">{{rig.pl[i]}}</i>
            </div>
          </td>

          <td class="text-align-center vertical-align-top">
            <div *ngIf="rig.hr && rig.hr[i] !== undefined">
              {{rig.hr[i] | displayHr:false:true}}
            </div>
          </td>

          <td class="text-align-center vertical-align-top">
            {{rig.utilisation[i]}}
          </td>

          <td class="text-align-center vertical-align-top">
            <span *ngIf="rig.gpu_temp[i]" title="{{ 'rig.tempCore' | translate }}">{{rig.gpu_temp[i]}}°</span>
            <ng-container *ngIf="rig.gpu_temp3[i]">
              <span class="active-element" title="{{ 'rig.tempMem' | translate }}"><br>{{rig.gpu_temp3[i]}}°</span>
            </ng-container>
            <ng-container *ngIf="!rig.gpu_temp3[i] && rig.gpu_temp2 && rig.gpu_temp2[i]">
              <span class="active-element" title="{{ 'rig.tempJunction' | translate }}"><br>{{rig.gpu_temp2[i]}}°</span>
            </ng-container>
          </td>

          <td class="text-align-center vertical-align-top">
            {{rig.gpu_fan[i]}}
          </td>

          <td class="text-align-center vertical-align-top">
            <div class="flex-container vertical">
              <div>
                <span *ngIf="rig.core_clock[i]">{{rig.core_clock[i]}}</span>
                <span *ngIf="rig.core_state[i]" class="ml-02">{{rig.core_state[i]}}</span>
              </div>
              <div *ngIf="rig.core_voltage[i]" class="active-element">
                <i [title]="rig.core_voltage[i]">{{rig.core_voltage[i] | number : '1.0-0'}}</i>
              </div>
            </div>
          </td>

          <td class="text-align-center vertical-align-top">
            <span *ngIf="rig.mem_clock[i]">{{rig.mem_clock[i]}}</span>
            <span *ngIf="rig.mem_state[i]" class="ml-02">{{rig.mem_state[i]}}</span>
          </td>

          <td class="text-align-center vertical-align-top">
            {{ isNumber(rig.gpu_power[i]) ? (rig.gpu_power[i] | number : '1.2') : rig.gpu_power[i] }}
          </td>
        </tr>

        <tr *ngIf="i !== rig.gpu_busid.length -1">
          <td colspan="9">
            <hr>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  </div>
  <ng-container *ngIf="!rig.offline">
    <div class="flex-container">
      <div>{{ 'rig.totalPower' | translate }}
        <fa-icon class="active-element" [icon]="this.sharedModule.faInfoCircle"
                 title="{{ 'rig.totalPowerDescr' | translate }} : {{rig.power}} {{ 'watt' | translate }}"></fa-icon></div>
      <div class="pinned-right">{{rig.totalPower}} {{ 'watt' | translate }}</div>
    </div>
  </ng-container>

  <div *ngIf="!rig.offline && rig.devices as devices" class="bordered mt-02">
    <div *ngFor="let deviceName of Object.keys(devices)">
      <ng-container [ngSwitch]="deviceName">
        <ng-template ngSwitchCase="autofan8mk">
          <app-autofan-hardware [data]="devices[deviceName]" [device]="deviceName"></app-autofan-hardware>
        </ng-template>
        <ng-template ngSwitchCase="autofan_coolbox">
          <app-autofan-hardware [data]="devices[deviceName]" [device]="deviceName"></app-autofan-hardware>
        </ng-template>
        <ng-template ngSwitchCase="autofan_napalm">
          <app-autofan-napalm [data]="devices[deviceName]"></app-autofan-napalm>
        </ng-template>
        <ng-template ngSwitchDefault>
          {{ 'rig.devices.unknownDevice' | translate }} <b>{{deviceName}}</b>
        </ng-template>
      </ng-container>
    </div>
  </div>

  <div id="wallets-sysinfo-container" class="flex-container " style="justify-content: flex-end;" >
    <div id="wallets" class="flex-grow-1 bordered">
      <h4>{{ 'manifests.manifests' | translate }}</h4>
      <app-wallets-block [farmId]="rig.owner" [rigId]="rig.id" [profiles]="rig.profiles" [manifestsId]="rig.profiles"></app-wallets-block>
    </div>
    <div id="sysinfo" class="bordered">
      <div class="flex-container">
        <div>
          <h4>{{ 'rig.sysInfoTitle' | translate }}</h4>
        </div>
        <div class="pinned-right">
          <fa-icon *ngIf="!showAdditionalSysInfo" [icon]="this.sharedModule.faEye" (click)="toggleAdditionalSysInfo()" class="active-element"
             title="{{ 'rig.showAdditionalSysInfo' | translate }}"
          ></fa-icon>
          <fa-icon *ngIf="showAdditionalSysInfo" [icon]="this.sharedModule.faEyeSlash" (click)="toggleAdditionalSysInfo()" class="active-element"
             title="{{ 'rig.dontShowAdditionalSysInfo' | translate }}"
          ></fa-icon>
        </div>
      </div>

      <div *ngIf="rig.sysinfo.osver">
        <b>{{ 'rig.sysInfo.osVer' | translate }}</b> {{rig.sysinfo.osver}}
        <ng-container *ngIf="osData$ | async as data">
          <ng-container *ngIf="rig.sysinfo.osver < sharedModule.getValByKey(data, 'osdog').current_ver">
            <fa-icon [icon]="this.sharedModule.faArrowCircleUp" (click)="sendCommand('exec', 'upgrade')" class="active-element"
              title="{{ 'rig.upgrade' | translate }}"
            ></fa-icon>
          </ng-container>
        </ng-container>
      </div>
      <div *ngIf="rig.sysinfo.iso">
        <b>{{ 'rig.sysInfo.iso' | translate }}</b> {{rig.sysinfo.iso}}
      </div>
      <div *ngIf="rig.sysinfo.hdd_free && rig.sysinfo.hdd_total">
        <b>{{ 'rig.sysInfo.diskUsage' | translate }}</b> {{rig.sysinfo.hdd_free}} / {{rig.sysinfo.hdd_total}}
      </div>
      <div *ngIf="rig.cpu_avg">
        <b>{{ 'rig.sysInfo.la' | translate }}</b> {{rig.cpu_avg}}
      </div>

      <div *ngIf="rig.temp.CPU_TEMP || rig.temp.HDD_TEMP">
        <b>{{ 'rig.sysInfo.temp' | translate }}</b>
        <span *ngIf="rig.temp.HDD_TEMP" class="ml-02" title="{{ 'rig.sysInfo.hddTempDescr' | translate }}">
          <fa-icon [icon]="this.sharedModule.faHdd" class="info-element"></fa-icon>
          {{rig.temp.HDD_TEMP}}°
        </span>
        <span *ngIf="rig.temp.CPU_TEMP" class="ml-02" title="{{ 'rig.sysInfo.cpuTempDescr' | translate }}">
          <fa-icon [icon]="this.sharedModule.faCore" class="info-element"></fa-icon>
          {{rig.temp.CPU_TEMP}}°
        </span>
      </div>
      <div *ngIf="rig.ip.length > 0">
        <div *ngFor="let ip of rig.ip">
          <b>{{ 'rig.sysInfo.ip' | translate }}</b>
          <span class="ml-02" appHideContent="{{ip}}">***.***.***.***</span>
          <fa-icon [icon]="this.sharedModule.faCopy" class="active-element ml-02"
            appCopyOnClick="{{ip}}" title="{{ 'copyOnClick' | translate }}">
          </fa-icon>
        </div>
      </div>
      <ng-container *ngIf="showAdditionalSysInfo">
        <div *ngIf="rig.sysinfo.system">
          <b>{{ 'rig.sysInfo.system' | translate }}</b> {{rig.sysinfo.system}}
        </div>
        <div *ngIf="rig.sysinfo.kernel">
          <b>{{ 'rig.sysInfo.kernel' | translate }}</b> {{rig.sysinfo.kernel}}
        </div>
        <div *ngIf="rig.sysinfo.driver_amd">
          <b>{{ 'rig.sysInfo.driverAmd' | translate }}</b> {{rig.sysinfo.driver_amd}}
        </div>
        <div *ngIf="rig.sysinfo.driver_nvidia">
          <b>{{ 'rig.sysInfo.driverNvidia' | translate }}</b> {{rig.sysinfo.driver_nvidia}}
        </div>
        <div *ngIf="rig.sysinfo.mb">
          <b>{{ 'rig.sysInfo.motherboard' | translate }}</b> {{rig.sysinfo.mb.manufacturer}} {{rig.sysinfo.mb.product_name}}
          <fa-icon [icon]="this.sharedModule.faInfoCircle" class="info-element"
            title="{{ 'rig.sysInfo.motherboardInfo.sn' | translate }} {{rig.sysinfo.mb.sn}}, {{ 'rig.sysInfo.motherboardInfo.bios' | translate }} {{rig.sysinfo.mb.bios}}"
          ></fa-icon>
        </div>
        <div *ngIf="rig.sysinfo.cpu">
          <b>{{ 'rig.sysInfo.cpu' | translate }}</b> {{rig.sysinfo.cpu.version}}
          <fa-icon [icon]="this.sharedModule.faInfoCircle" class="info-element"
            title="{{ 'rig.sysInfo.cpuInfo.cores' | translate }} {{rig.sysinfo.cpu.core}}, {{ 'rig.sysInfo.cpuInfo.clock' | translate }} {{rig.sysinfo.cpu.cur_speed}}"
          ></fa-icon>
        </div>
        <div *ngIf="rig.sysinfo.internal">
          <b>{{ 'rig.sysInfo.internalGpu' | translate }}</b> {{rig.sysinfo.internal}}
        </div>
        <div *ngIf="rig.sysinfo.ram">
          <b>{{ 'rig.sysInfo.ram' | translate }}</b> {{rig.sysinfo.ram}}
        </div>
        <div *ngIf="rig.sysinfo.hdd_model">
          <b>{{ 'rig.sysInfo.diskName' | translate }}</b> {{rig.sysinfo.hdd_model}}
        </div>

        <div *ngIf="rig.remote_ip">
          <b>{{ 'rig.sysInfo.externalIp' | translate }}</b>
          <span class="ml-02" appHideContent="{{rig.remote_ip}}">***.***.***.***</span>
          <fa-icon [icon]="this.sharedModule.faCopy" class="active-element ml-02"
             appCopyOnClick="{{rig.remote_ip}}"
             title="{{ 'copyOnClick' | translate }}"></fa-icon>
        </div>
        <div *ngIf="rig.mac.length > 0">
          <div *ngFor="let mac of rig.mac">
            <b>{{ 'rig.sysInfo.mac' | translate }}</b> {{mac}}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="rig.lastUpdate" class="flex-container" style="margin-top: auto; margin-bottom: 0">
    <div class="pinned-right">
      {{'rig.lastUpdate_1' | translate}}:
      {{rig.lastUpdate}}</div>
    {{'rig.lastUpdate_2' | translate}}
  </div>
</div>
<ng-template #appLoading>
  <app-loading></app-loading>
</ng-template>
