import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Rig} from '../../interfaces';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SharedModule} from '../../shared.module';

@Component({
  selector: 'app-edit-rig',
  templateUrl: './edit-rig.component.html'
})
export class EditRigComponent implements OnInit {
  @Input() rig: Rig
  @Output() formSubmit = new EventEmitter()
  newRig = true
  form: FormGroup
  serviceMode = false
  drivers = true

  constructor(
    public sharedModule: SharedModule,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      id: [{
        value: '',
        disabled: true
      }],
      name: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.pattern('[_0-9a-zA-Z]*')
      ]],
      comment: [''],
      password: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.pattern('[_0-9a-zA-Z]*')
      ]],
      // allowAccess: [''], // moved to groups
      active: [false],
      notify_on_boot: [false],
      use_graphic: [true],
      force_p0: [false],
      wd_kern: [false],
      power: [0],
      // miner2_wait: [''],
      service_mode: [''],
      rigonlineru: this.fb.group({
        enabled: [''],
        email: [''],
        rigid: [''],
        secret: ['']
      })
    })
    if (Object.keys(this.rig).length > 0) {
      this.newRig = false
      this.form.patchValue(this.rig)
      if (this.rig.service_mode > 0) {
        this.serviceMode = true

        if (this.rig.service_mode >= 2) {
          this.drivers = false
        }
      }
    }

    if (!this.rig.password) {
      this.generateRandomPassword()
    }
  }

  setServiceMode() {
    let servMode = 0
    if (this.serviceMode) {
      if (!this.drivers) {
        servMode = 2
      } else {
        servMode = 1
      }
    }
    this.form.controls.service_mode.setValue(servMode)
    this.form.controls.service_mode.markAsTouched()
  }

  onToggleServiceMode() {
    this.serviceMode = !this.serviceMode
    this.setServiceMode()
  }
  onToggleDrivers() {
    this.drivers = !this.drivers
    this.setServiceMode()
  }

  submit() {
    if (this.form.touched) {
      let data: Rig = {}
      Object.keys(this.form.value).map((key) => {
        if (this.form.get(key).touched) {
          data[key] = this.form.get(key).value
        }
      })
      if (this.newRig) { // all new rigs are active
        delete data.active
      }
      // console.log ('data', data)
      this.formSubmit.emit(data)
    }
  }

  generateRandomPassword() {
    const allowedChars = '_0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const length = 8
    let result = ''

    for (let i = length; i > 0; --i) {
      result += allowedChars[Math.floor(Math.random() * allowedChars.length)];
    }
    this.form.get('password').setValue(result)
    this.form.get('password').markAsTouched()
    // console.log (result)
    // var rString = randomString(40, );
  }
}

