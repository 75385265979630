import { NgModule } from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {MainLayoutComponent} from './shared/main-layout/main-layout.component';
import {MainPageComponent} from './main-page/main-page.component';
import {MonitorPageComponent} from './monitor-page/monitor-page.component';
import {GroupsPageComponent} from './groups-page/groups-page.component';
import {ProfilesPageComponent} from './profiles-page/profiles-page.component';
import {RigComponent} from './rig-page/rig.component';
import {AccountPageComponent} from './account-page/account-page.component';
import {AuthGuard} from './shared/services/auth.guard';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {RigStatsComponent} from './rig-page/components/rig-stats/rig-stats.component';
import {RigOcComponent} from './rig-page/components/rig-oc/rig-oc.component';
import {RigSettingsComponent} from './rig-page/components/rig-settings/rig-settings.component';
import {RigTuningComponent} from './rig-page/components/rig-tuning/rig-tuning.component';
import {RigActionsComponent} from './rig-page/components/rig-actions/rig-actions.component';
import {RigConnectionsComponent} from './rig-page/components/rig-connections/rig-connections.component';
import {OcProfilesPageComponent} from './oc-profiles-page/oc-profiles-page.component';
import {ChangelogComponent} from './changelog/changelog.component';
import {RigGraphComponent} from './rig-page/components/rig-graph/rig-graph.component';
import {GroupComponent} from './groups-page/components/group/group.component';
import {DownloadsPageComponent} from './downloads-page/downloads-page.component';
import {ConfirmMailComponent} from './shared/components/confirm-mail/confirm-mail.component';
import {RestorePasswordComponent} from './shared/components/restore-password/restore-password.component';
import {EnForwarderComponent} from './en-forwarder/en-forwarder.component'

const routes: Routes = [
  {
    path: '', component: MainLayoutComponent, children: [
      {path: '', component: MainPageComponent},

      {path: 'en', component: EnForwarderComponent},

      {path: 'account', component: AccountPageComponent, canActivate: [AuthGuard]},
      {path: 'monitor', component: MonitorPageComponent, canActivate: [AuthGuard]},
      {path: 'groups', component: GroupsPageComponent, canActivate: [AuthGuard]},
      {path: 'groups/:id', component: GroupComponent, canActivate: [AuthGuard], children: [
      ]},
      {path: 'profiles', component: ProfilesPageComponent, canActivate: [AuthGuard]},
      {path: 'profiles/:farmId', component: ProfilesPageComponent, canActivate: [AuthGuard]},
      {path: 'oc', component: OcProfilesPageComponent, canActivate: [AuthGuard]},
      {path: 'oc/:farmId', component: OcProfilesPageComponent, canActivate: [AuthGuard]},
      {path: 'rig/:id', component: RigComponent, canActivate: [AuthGuard], children: [
        {path: '', component: RigStatsComponent, canActivate: [AuthGuard]},
        {path: 'stats', redirectTo: '', pathMatch: 'full'},
        {path: 'graph', component: RigGraphComponent, canActivate: [AuthGuard]},
        {path: 'settings', component: RigSettingsComponent, canActivate: [AuthGuard]},
        {path: 'oc', component: RigOcComponent, canActivate: [AuthGuard]},
        {path: 'tuning', component: RigTuningComponent, canActivate: [AuthGuard]},
        {path: 'connections', component: RigConnectionsComponent, canActivate: [AuthGuard]},
        {path: 'actions', component: RigActionsComponent, canActivate: [AuthGuard]},
      ]},
      {path: 'downloads', component: DownloadsPageComponent},
      {path: 'changelog', component: ChangelogComponent},
      {path: 'confirm-mail/:token', component: ConfirmMailComponent},
      {path: 'restore-pass/:token', component: RestorePasswordComponent},
      {path: '**', component: PageNotFoundComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
