<fa-icon [icon]="this.sharedModule.faGlobe" [matMenuTriggerFor]="themeMenu" class="menu-link"></fa-icon>
<mat-menu #themeMenu="matMenu">
  <button
    *ngFor="let language of languages"
    mat-menu-item
    (click)="changeLanguage(language.locale)">

    <span>{{language.description}}</span>
  </button>
</mat-menu>

