import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {Group, Hr, Message, Rig} from '../interfaces'
import {GroupsService} from './groups.service'
import {map} from 'rxjs/operators'

@Injectable({providedIn: 'root'})
export class DataPreparationService {
  constructor(private groupsService: GroupsService) {
  }

  prepareRigData(rig: Rig, timeStampInit?): Rig {
    if (! timeStampInit) {
      timeStampInit = Math.floor(Date.now() / 1000)
    }
    const timeStamp = timeStampInit
    // rig.gpuCount = rig.gpu_brand && Array.isArray(rig.gpu_brand) ? rig.gpu_brand.filter(item => item !== 'I').length : 0
    rig.gpuCount = rig.gpu_busid ? rig.gpu_busid.length : 0

    if (rig.msg && rig.msg.length > 0) {
      rig.msg = this.prepareMsgData(rig.msg)
      let msg2: {type: string, count: number}[] = []
      rig.msg.forEach((el) => {
        const index = msg2.findIndex(element => el.type === element.type)

        if (index >= 0) {
          msg2[index].count++
        } else {
          msg2.push(
            {type: el.type, count: 1}
          )
        }
      })
      rig.msg = msg2
      // console.log ('got rig msg', rig.msg, msg2)
    }
    rig.lastUpdate = timeStamp - rig.refresh
    if (timeStamp - rig.refresh > 120) {
      rig.offline = true
      if (timeStamp - rig.refresh > 60 * 60 * 24) {
        rig.nonActive = true
      }
    } else {
      rig.offline = false
      rig.nonActive = false

      rig.totalPower = +rig.power

      if (rig.gpu_power) {
        rig.gpu_power.forEach(gpuPower => {
          if (!isNaN(gpuPower)) {
            rig.totalPower += +gpuPower
          }
        })
      }
      const devicesPower = rig.devices?.autofan_napalm?.power_consumption ? rig.devices.autofan_napalm.power_consumption : 0;
      rig.totalPower += +devicesPower

      rig.totalPower = Math.round(rig.totalPower)

      rig.totalHr = []
      // hr sorting
      rig.hr = []
      if (rig.gpu_busid) {
        rig.gpu_busid.forEach((gpuBusid, index) => {
          if (rig.minerStats) {
            rig.minerStats.forEach((miner) => {
              if (miner.hr && miner.busid) {
                let minerIndex = -1
                for (let minerBusid of miner.busid) {
                  minerIndex++
                  // console.log (`Check if ${minerBusid} === ${gpuBusid}`)
                  if (minerBusid === gpuBusid) {
                    rig.hr[index] = miner.hr[minerIndex]
                    break
                  }
                }
              }
            })
          }
        })
      }
      // hr sorting end
      if (rig.minerStats) {
        rig.minerStats.forEach((miner) => {
          if (miner.algo && miner.total_hr) {
            let hrElement: Hr
            let hrIndex = rig.totalHr.findIndex(el => el.coin && miner.coin === el.coin && miner.algo === el.algo)
            if (hrIndex === -1) {
              hrIndex = rig.totalHr.findIndex(el => !el.coin && miner.algo === el.algo)
            }

            if (hrIndex >= 0) {
              rig.totalHr[hrIndex].hr += +miner.total_hr
            } else {
              hrElement = {
                algo: miner.algo,
                hr: +miner.total_hr
              }
              if (miner.coin) {
                hrElement.coin = miner.coin
              }

              rig.totalHr.push(hrElement)
            }
          }
          if (miner.dual && miner.dual.length > 0) {
            miner.dual.forEach((dualStats) => {
              if (dualStats.algo && dualStats.total_hr) {
                let hrElement: Hr
                const hrIndex = rig.totalHr.findIndex(element => dualStats.algo === element.algo)

                if (hrIndex >= 0) {
                  rig.totalHr[hrIndex].hr += +dualStats.total_hr
                } else {
                  hrElement = {
                    algo: dualStats.algo,
                    hr: +dualStats.total_hr
                  }

                  rig.totalHr.push(hrElement)
                }
              }
            })
          }
          // get temp from GPU if miner doesn`t do it
          if (!miner.temp) {
            miner.temp = []
          }
          if (!miner.fan) {
            miner.fan = []
          }
          if (miner.busid) {
            miner.busid.forEach((busid, index) => {
              if (!miner.temp[index] || miner.temp[index] === 'null') {
                const idx = rig.gpu_busid.findIndex(el => el === busid)
                if (idx >= 0 && rig.gpu_temp.hasOwnProperty(idx)) {
                  miner.temp[index] = rig.gpu_temp[idx]
                }
              }
              if (!miner.fan[index] || miner.fan[index] === 'null') {
                const idx = rig.gpu_busid.findIndex(el => el === busid)
                if (idx >= 0 && rig.gpu_fan.hasOwnProperty(idx)) {
                  miner.fan[index] = rig.gpu_fan[idx]
                }
              }
            })
          }
            // END get temp
          // }

          if (! miner.total_invshare && Array.isArray(miner.invshare)) {
            miner.total_invshare = miner.invshare.reduce(((previousValue, currentValue) => previousValue + currentValue))
          }
          miner.allBadShares = 0
          if (miner.total_badshare) {
            miner.allBadShares += miner.total_badshare
          }
          if (miner.total_invshare) {
            miner.allBadShares += miner.total_invshare
          }
        })
      }
    }
    return rig
  }

  prepareMsgData(messages: Message[]): Message[] {
    return messages.map((el) => {
      el.date = new Date(el.timestamp * 1000)
      delete el.timestamp
      if (['success', 'warning', 'danger', 'info', 'file'].indexOf(el.type) > -1) {
        el.type = `note-${el.type}`
      } else {
        el.type = 'note-default'
      }
      return {
        ...el
      }
    })
  }

  sortRigs(rigs: Rig[], timeStamp?: number): Rig[] {
    if (! timeStamp) {
      timeStamp = Math.floor(Date.now() / 1000)
    }

    return rigs.sort((f1, f2) => {
      const offline = 60 * 60 * 24
      const diff1 = timeStamp - f1.refresh
      const diff2 = timeStamp - f2.refresh

      if (diff1 >= offline && diff2 < offline) { return 1 }
      if (diff1 < offline && diff2 >= offline) {return -1 }

      // if (diff1 >= offline && diff2 >= offline) { return 0 }
      return 0;
    });
  }

  getFarmRigsData(farmId: number, view: string|null = null): Observable<Group> {
    return this.groupsService.getRigs(farmId, view)
      .pipe(map((rigs) => {
        let onlineGpu = 0
        let offlineGpu = 0
        let onlineRigs = 0
        let offlineRigs = 0
        let totalPower = 0
        let totalHr: Hr[] = []

        if (rigs.length > 0) {
          const timeStamp = Math.floor(Date.now() / 1000)

          rigs = this.sortRigs(rigs)

          // console.log (rigs)
          rigs.forEach((rig: Rig) => {
            rig = this.prepareRigData (rig, timeStamp)
            if (! rig.offline) {
              onlineGpu += rig.gpuCount
              totalPower += rig.totalPower
            } else {
              offlineGpu += rig.gpuCount
              offlineRigs++
            }
            // console.log(rig.name, 'rig.totalHr', rig.totalHr)
            if (rig.totalHr) {
              rig.totalHr.forEach((hr) => {
                let hrIndex = totalHr.findIndex(el => el.coin && hr.coin === el.coin && hr.algo === el.algo)

                if (hrIndex === -1) {
                  hrIndex = totalHr.findIndex(el => !el.coin && hr.algo === el.algo)
                }

                if (hrIndex >= 0) {
                  totalHr[hrIndex].hr += +hr.hr
                } else {
                  totalHr.push(hr)
                }
              })
            }
          })
          // console.log('totalHr', totalHr)
          onlineRigs = rigs.length - offlineRigs
        }
        return {
          onlineGpu, offlineGpu, onlineRigs, offlineRigs, totalPower, totalHr, rigs
        }
      }))
    }
}

