import {Injectable} from '@angular/core'
import {HttpClient, HttpParams} from '@angular/common/http'
import {Observable} from 'rxjs'
import {Currency, Group, GroupInvitation, GroupUser, Rig} from '../interfaces';
import {SharedModule} from '../shared.module'

@Injectable({providedIn: 'root'})
export class GroupsService {
  constructor(
    private http: HttpClient,
    private sharedModule: SharedModule
  ) {}

  get(data?: string): Observable<Group[]> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/farms`
    if (data) {
      const params = new HttpParams().set('view', data)
      return this.http.get<Group[]>(url, {params})
    } else {
      return this.http.get<Group[]>(url)
    }
  }

  getGroup(farmId: number, data?: string): Observable<Group> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/farms/${farmId}`
    if (data) {
      const params = new HttpParams().set('view', data)
      return this.http.get<Group>(url, {params})
    } else {
      return this.http.get<Group>(url)
    }
  }

  transferRigs(groupId: number, data: {group: number, rigs: any}): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/farms/${groupId}/rigs/transfer`
    return this.http.post(url, data)
  }

  getRigs(farmId: number, view?: string): Observable<Rig[]> {
    const apiUrl = this.sharedModule.apiUrl
    let url = `${apiUrl}/farms/${farmId}/rigs`
    if (view) {
      const params = new HttpParams().set('view', view)
      return this.http.get<any>(url, {params})
    } else {
      return this.http.get<any>(url)
    }
  }

  patch(farmId: number, data: Group): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/farms/${farmId}`
    return this.http.patch(url, data)
  }

  create(data: Group): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/farms`
    return this.http.put(url, data)
  }

  delete(groupId: number): Observable<string> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/farms/${groupId}`
    return this.http.delete<string>(url)
  }

  createRig(groupId: number, data: Group): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/farms/${groupId}/rigs`
    return this.http.put(url, data)
  }

  getInvitations(): Observable<GroupInvitation[]> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/farms/invitations`
    return this.http.get<GroupInvitation[]>(url)
  }

  addInvitation(groupId: number, data: GroupUser): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/farms/${groupId}/invitations`
    return this.http.put(url, data)
  }

  declineInvitation(groupId: number): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/farms/${groupId}/invitations`
    return this.http.delete(url)
  }

  acceptInvitation(groupId: number): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/farms/${groupId}/invitations/accept`
    return this.http.get(url)
  }

  removeUser(groupId: number, data: {id: number}): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/farms/${groupId}/users/remove`
    return this.http.post(url, data)
  }

  // delete all messages from selected rigs
  deleteMessages(groupId: number, selectedRigs: any): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/farms/${groupId}/messages/delete`
    const data = {
      rigs: selectedRigs
    }
    return this.http.post(url, data)
  }

  getCurrencies(groupId: number): Observable<Currency[]> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/farms/${groupId}/finances/currencies`
    return this.http.get<Currency[]>(url)
  }

  getPaymentLink(groupId: number, data: {currency: string, amount: number}): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/farms/${groupId}/finances/get_payment_link`
    return this.http.post(url, data)
  }
}
