<!--<form [formGroup]="form" (ngSubmit)="submit()">-->
<!--  <div class="flex-container vertical">-->
<!--    <div>-->
<!--      <label for="farm">-->
<!--        {{'manifests.selectGroupToCopy' | translate}}-->
<!--      </label>-->
<!--      <select formControlName="farm" id="farm" class="mt-05">-->
<!--        <option *ngFor="let farm of data.farms" [value]="+farm.id">{{farm.name}}</option>-->
<!--      </select>-->
<!--    </div>-->

<!--    <div class="flex-container mt-05">-->
<!--      <button class="btn btn-default ml-0" mat-dialog-close>{{ 'cancel' | translate }}</button>-->
<!--      <button class="btn btn-default pinned-right mr-0"-->
<!--        type="submit"-->
<!--        title="{{'manifests.copySelectedProfiles' | translate}}"-->
<!--      >-->
<!--        <fa-icon [icon]="this.sharedModule.faCopy"></fa-icon>-->
<!--      </button>-->
<!--    </div>-->
<!--  </div>-->
<!--</form>-->

<form [formGroup]="form" (ngSubmit)="submit()" class="login-form">
  <h2 mat-dialog-title>{{ 'changePassword.title' | translate }}</h2>
  <mat-dialog-content>
    <div class="form-control" [ngClass]="{invalid: form.get('password').touched && form.get('password').invalid}">
      <label for="password">{{ 'changePassword.password' | translate }}</label>
      <app-input-validation [component]="form.get('password')"></app-input-validation>
      <input id="password" type="password" formControlName="password">
    </div>
    <div class="form-control" [ngClass]="{invalid: form.get('password').touched && form.get('password').invalid}">
      <label for="newPassword">{{ 'changePassword.newPassword' | translate }}</label>
      <app-input-validation [component]="form.get('newPassword')"></app-input-validation>
      <input id="newPassword" type="password" formControlName="newPassword">
    </div>
    <div class="form-control" [ngClass]="{invalid: form.get('password').touched && form.get('password').invalid}">
      <label for="newPassword2">{{ 'changePassword.newPassword2' | translate }}</label>
      <app-input-validation [component]="form.get('newPassword2')"></app-input-validation>
      <input id="newPassword2" type="password" formControlName="newPassword2">
    </div>
    <div class="form-control" [ngClass]="{invalid: form.get('twofa').touched && form.get('twofa').invalid}">
      <label for="twofa">{{ 'login.twofa' | translate }}</label>
      <app-input-validation [component]="form.get('twofa')"></app-input-validation>
      <input id="twofa" type="text" formControlName="twofa" appOnlyNumbers>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="flex-container pinned-right">
      <button class="btn btn-default" [mat-dialog-close]="true">{{ 'cancel' | translate }}</button>
      <button
        type="submit"
        class="btn btn-default"
        [disabled]="form.invalid"
      >{{ 'save' | translate }}</button>
    </div>
  </mat-dialog-actions>
</form>
