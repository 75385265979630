import {Injectable} from '@angular/core'
import {HttpClient, HttpParams} from '@angular/common/http'
import {Observable, of} from 'rxjs'
import {Alert, Profile, Rig, RigCommand} from '../interfaces'
import {AlertsService} from './alerts.service'
import {SharedModule} from '../shared.module'
import {switchMap} from 'rxjs/operators'

@Injectable({providedIn: 'root'})
export class RigsService {
  constructor(
    private http: HttpClient,
    private sharedModule: SharedModule,
    private alertsService: AlertsService
  ) {}

  get(rigId: number, data?: string): Observable<Rig>{
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/rigs/${rigId}`
    if (data) {
      const params = new HttpParams().set('request', data)
      return this.http.get<any>(url, {params})
    } else {
      return this.http.get<any>(url)
    }
  }

  // patch some rig properties: oc_amd, oc_nvidia, af, etc
  patch(rigId: number, data: Rig, patchSettings: boolean = false): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = patchSettings ? `${apiUrl}/rigs/${rigId}/settings` : `${apiUrl}/rigs/${rigId}`
    return this.http.patch(url, data)
  }

  delete(rigId: number): Observable<string> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/rigs/${rigId}`
    return this.http.delete<string>(url)
  }

  // all commands
  deleteCommands(rigId: number): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/rigs/${rigId}/commands`
    return this.http.delete(url)
  }

  // all messages
  deleteMessages(rigId: number): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/rigs/${rigId}/messages`
    return this.http.delete(url)
  }

  // one message
  getMessage(rigId: number, msgId: number): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/rigs/${rigId}/message/${msgId}`
    return this.http.get(url)
  }
  deleteMessage(rigId: number, msgId: number): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/rigs/${rigId}/message/${msgId}`
    return this.http.delete(url)
  }

  changeProfile(rigId: number, data: Profile[]): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/rigs/${rigId}/profile`
    // console.log ('changeProfile', data)
    return this.http.patch(url, data)
  }

  setProfiles(rigId: number, data: Profile[]): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/rigs/${rigId}/profile`
    // console.log ('setProfiles', data)
    return this.http.post(url, data)
  }

  getGraphData(rigId: number, data?: string): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/rigs/${rigId}/graph`
    if (data) {
      const params = new HttpParams().set('request', data)
      return this.http.get<any>(url, {params})
    } else {
      return this.http.get<any>(url)
    }
  }

  getGraphData2(rigId: number, data?: string): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/rigs/${rigId}/graphGpuOnline`
    if (data) {
      const params = new HttpParams().set('request', data)
      return this.http.get<any>(url, {params})
    } else {
      return this.http.get<any>(url)
    }
  }

  upload(rigId: number, method: string, data: FormData): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/rigs/${rigId}/upload/${method}`
    let title = 'unknown'

    if (data.has('command')) {
      // console.log ('command', data.get('command'))
      title = data.get('command').toString()
    }

    return this.http.post<any>(url, data)
      .pipe(
        switchMap(result => {
          // console.log ('result', result)
          if (result === 'OK') {
            this.sharedModule.pushAlert(`Command "${title}" applied`, 'success')
          } else {
            this.sharedModule.pushAlert(result, 'warning')
          }
          return of(result)
        })
      )
  }
  command(rigId: number, data: RigCommand): void { // just a wrapper for pushCommand with alerts
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/rigs/${rigId}/command`
    if (rigId) {
      let sub = this.http.post<any>(url, data)
        .subscribe(() => {
            let alert: Alert = {
              type: 'success',
              text: `Command ${data.command} applied`
            }
            this.alertsService.pushAlert(alert)
            sub.unsubscribe()
          },
          () => {
            let alert: Alert = {
              type: 'error',
              text: `Error while applying command ${data.command}`
            }
            // console.log(error)
            this.alertsService.pushAlert(alert)
            sub.unsubscribe()
          })
    }
  }
}
