import {Component, Input, OnInit} from '@angular/core';
import {OsDataMiner, Rig} from '../../interfaces';
import {SharedModule} from '../../shared.module';

@Component({
  selector: 'app-display-rig-short',
  templateUrl: './display-rig-short.component.html',
  styleUrls: ['./display-rig-short.component.scss']
})
export class DisplayRigShortComponent implements OnInit {
  @Input() rig: Rig
  @Input() tempLimit: number
  @Input() currentTime: number
  @Input() osDataMiners: OsDataMiner[]
  constructor(
    public sharedModule: SharedModule
  ) { }

  ngOnInit(): void {
  }

  getMinerDescription(minerName: string): string {
    const index = this.osDataMiners.findIndex(el => el.name === minerName)
    return index !== -1 ? this.osDataMiners[index].description : minerName
  }
}
