<div id="main">
<!--  {{data | json}}-->
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="flex-container w-100">
        <mat-slide-toggle formControlName="enabled">{{ 'connections.openvpn.keys.enabled' | translate }}</mat-slide-toggle>
      </div>

      <div class="form-control flex-container w-100 vertical">
        <label>{{ 'connections.openvpn.keys.conf' | translate }}</label>
        <ng-container *ngIf="settings.conf">
          <div>
            <input formControlName="action" type="radio" value="keep">{{ 'connections.openvpn.keys.useExisting' | translate }}
            <fa-icon  [icon]="this.sharedModule.faFile" class="active-element mr-02"
              (click)="sharedModule.download(data.openvpn_conf, 'ovpn.conf')"
            ></fa-icon>
          </div>
          <div>
            <input formControlName="action" type="radio" value="remove">{{ 'connections.openvpn.keys.deleteExisting' | translate }}
          </div>
          <div>
            <input formControlName="action" type="radio" value="new">{{ 'connections.openvpn.keys.uploadNew' | translate }}
          </div>
        </ng-container>

        <ng-container *ngIf="form.get('action').value === 'new'">
          <div class="form-control w-100">
            <app-input-validation [component]="form.get('conf')"></app-input-validation>
            <input type="file" style="display: none" (change)="onFileSelected($event)" #fileUpload>
            <div class="file-upload">
              <button type="button" class="btn btn-default" (click)="fileUpload.click()">
                <fa-icon [icon]="sharedModule.faUpload" class="active-element"></fa-icon>
              </button>
              {{fileName || 'fileNotSelected' | translate }}
            </div>
          </div>
        </ng-container>

      </div>

<!--      <button type=button (click)="show()" class="btn">show</button>-->
      <div class="flex-container form-buttons">
        <button type="submit"  class="btn btn-success pinned-right mr-0"
          [disabled]="!form.valid">
          {{'save' | translate}}
        </button>
      </div>
    </form>

</div>
