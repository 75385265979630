<div id="main">
  <form [formGroup]="form" (ngSubmit)="submit()" style="padding: 0.5rem">
    <div class="flex-container">
      <div class="form-control flex-container vertical">
<!--        <mat-slide-toggle *ngFor="let controlName of boolControls" [formControlName]="controlName">-->
<!--          {{ 'tuning.wd_temp.keys.' + controlName  | translate }}-->
<!--        </mat-slide-toggle>-->
        <mat-slide-toggle *ngFor="let controlName of boolControls" [formControlName]="controlName">
          <ng-container *ngIf="!(controlName === 'enabled' && ! form.get(controlName).value); else disabled">
            {{ 'tuning.' + section + '.keys.' + controlName  | translate }}
          </ng-container>
          <ng-template #disabled>
            {{ 'tuning.' + section + '.keys.' + 'disabled'  | translate }}
          </ng-template>
        </mat-slide-toggle>
      </div>
    </div>

    <div *ngFor="let controlName of simpleControls" class="flex-container w-50">
      <label [for]="controlName">{{ 'tuning.' + section + '.keys.' + controlName | translate }}</label>
      <div class="form-control w-100">
        <input [id]="controlName" [formControlName]="controlName" type="number" appOnlyNumbers>
        <app-input-validation [component]="form.get(controlName)"></app-input-validation>
      </div>
    </div>

    <div class="flex-container w-50">
      <label for="action">{{ 'tuning.' + section + '.keys.actions.name' | translate }}</label>
      <div class="form-control w-100">
        <select formControlName="action" id="action">
          <option *ngFor="let action of actions" [ngValue]="action">{{ 'tuning.' + section + '.keys.actions.keys.' + action | translate }}</option>
        </select>
      </div>
    </div>

    <div *ngIf="form.get('action').value === 'pause'" class="flex-container w-50">
      <label for="resumeTemp">{{ 'tuning.' + section + '.keys.resumeTemp' | translate }}</label>
      <div class="form-control w-100">
        <input id="resumeTemp" formControlName="resumeTemp" type="number" appOnlyNumbers>
        <app-input-validation [component]="form.get('resumeTemp')"></app-input-validation>
      </div>
    </div>

    <div class="flex-container form-buttons">
      <button type="submit" class="btn btn-success pinned-right mr-0"
        [disabled]="!form.valid"
      >{{'save' | translate}}</button>
    </div>
  </form>
</div>
