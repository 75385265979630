<div class="rig-row" [ngClass]="{offline: rig.nonActive}">
  <div class="rig-name-online flex-container no-wrap">
    <div class="rig-name flex-container w-100">
      <div>
        <a [routerLink]="['/rig', rig.id]">
          <strong>{{rig.name}}</strong>
        </a>
      </div>
      <div class="messages">
      <span *ngFor="let msgCont of rig.msg" class="note ml-02" [ngClass]="msgCont.type">
        {{msgCont.count}}
      </span>
      </div>
      <ng-container *ngIf="!rig.offline && rig.minerStats as minerStats">
        <ng-container *ngIf="minerStats.length > 0">
          <div class="flex-container pinned-right">
            <ng-container *ngFor="let miner of minerStats">
              <div *ngIf="miner.total_badshare">
                <span class="note note-danger note-center-text active-element"
                      title="{{miner.algo}}: {{miner.total_badshare}} {{miner.total_badshare | conjugateShares : 'bad'}}">
                  <small>{{miner.total_badshare}}</small>
                  <fa-icon [icon]="this.sharedModule.faTimesCircle" size="xs" class="ml-02"></fa-icon>
                </span>
              </div>

<!--              <div *ngIf="miner.total_badshare">-->
<!--                <span class="note note-danger active-element" style="display: flex; align-items: center; padding-top: 0.1rem"-->
<!--                      title="{{miner.algo}}: {{miner.total_badshare}} {{miner.total_badshare | conjugateShares : 'bad'}}">-->
<!--                  <small>{{miner.total_badshare}}</small>-->
<!--                  <fa-icon [icon]="this.sharedModule.faTimesCircle" size="xs" class="ml-02 fa-icon-center"></fa-icon>-->
<!--                </span>-->
<!--              </div>-->

              <div *ngIf="miner.total_invshare">
                <span class="note note-danger note-center-text active-element"
                      title="{{miner.algo}}: {{miner.total_invshare}} {{miner.total_invshare | conjugateShares : 'invalid'}}">
                  <small>{{miner.total_invshare}}</small>
                  <fa-icon [icon]="this.sharedModule.faTimesCircle" size="xs" class="ml-02"></fa-icon>
                </span>
              </div>

<!--              <div *ngIf="miner.total_badshare" class="mr-02 active-element"-->
<!--                   title="{{miner.algo}}: {{miner.total_badshare}} {{miner.total_badshare | conjugateShares : 'bad'}}">-->
<!--                <small>{{miner.total_badshare}}</small>-->
<!--                <fa-icon [icon]="this.sharedModule.faTimesCircle" size="sm" class="ml-02 color-danger"></fa-icon>-->
<!--              </div>-->

<!--              <div *ngIf="miner.total_invshare" class="mr-02 active-element"-->
<!--                   title="{{miner.algo}}: {{miner.total_invshare}} {{miner.total_invshare | conjugateShares: 'invalid'}}">-->
<!--                <small>{{miner.total_invshare}}</small>-->
<!--                <fa-icon [icon]="this.sharedModule.faTimesCircle" size="sm" class="ml-02 color-warning"></fa-icon>-->
<!--              </div>-->
            </ng-container>
          </div>
        </ng-container>
      </ng-container>

    </div>

    <div class="online" *ngIf="!rig.nonActive">
      <small *ngIf="!rig.offline">{{ rig.online | dateAgo:currentTime }}</small>
      <small *ngIf="rig.offline" class="red">{{ rig.refresh | dateAgo:currentTime }}</small>
    </div>
  </div>
  <div class="miners" *ngIf="!rig.offline">
    <ng-container *ngFor="let miner of rig.minerStats">
      <div *ngIf="miner" class="miner-row" style="min-height: 1.6rem">
        <div class="miner-total-hr ">
          <div class="flex-container">
            <div *ngIf="miner.miner" class="mr-02">
              <small>{{getMinerDescription(miner.miner)}}</small>
            </div>
            <div *ngIf="miner.total_hr" class="miner-hr note note-default pinned-right">{{miner.total_hr | displayHr:true}}</div>
            <ng-container *ngIf="miner.dual && sharedModule.isArray(miner.dual)">
              <ng-container *ngFor="let dualStats of miner.dual">
                <div *ngIf="dualStats.total_hr" class="miner-hr note note-default">{{dualStats.total_hr | displayHr:true}}</div>
              </ng-container>
            </ng-container>
          </div>
<!--          <div *ngIf="miner.allBadShares" class="pinned-right" title="{{miner.allBadShares}} {{miner.allBadShares | conjugateShares: 'bad'}}">-->
<!--            {{miner.allBadShares}}-->
<!--            <fa-icon [icon]="this.sharedModule.faTimesCircle" class="ml-02 color-danger"></fa-icon>-->
<!--          </div>-->
        </div>
        <div class="miner-cells">
          <ng-template [ngIf]="miner.hr">
            <div *ngFor="let hr of miner.hr; let i = index">
              <fa-icon [icon]="this.sharedModule.faSquare" size="lg" class="ml-02
                {{ (miner.temp && miner.temp[i]) && +miner.temp[i] | tempColorClass: tempLimit:true }}"
                [title]="miner.temp[i] + '°'"
              ></fa-icon>
            </div>
          </ng-template>

          <ng-template [ngIf]="miner.threads_hr">
            <fa-icon [icon]="this.sharedModule.faSquare" size="lg" class="ml-02 thread-color"></fa-icon>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </div>
</div>
