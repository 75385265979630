<div class="container">
<ng-container *ngIf="this.data$ | async as data; else appLoading">
<!--  <pre>-->
<!--  {{data | json}}-->
<!--    </pre>-->
  <h3>
    {{'groups.financesKeys.balance' | translate}}     {{data.balance / 1000 | number: '1.2-2'}}$
  </h3>
  <hr>
  <table>
    <tr>
      <th>{{'groups.financesKeys.date' | translate}}</th>
      <th class="align-end">{{'groups.financesKeys.comment' | translate}}</th>
      <th>{{'groups.financesKeys.amount' | translate}}</th>
    </tr>
    <tr *ngFor="let row of data.finances">
      <td>{{row.date}}</td>
      <td>
        <ng-container *ngIf="row.credit > 0; else comment">
          {{row.gpuCount}}
          <fa-icon [icon]="this.sharedModule.faCube" class="info-element"
             title="{{ 'groups.financesKeys.chargedGpus' | translate }}">
          </fa-icon>
        </ng-container>
        <ng-template #comment>
          {{row.comment}}
        </ng-template>
      </td>
      <td class="align-center">
        <ng-container *ngIf="row.credit > 0; else debit">
          <span class="red">
            -{{row.credit / 1000 | number: '1.2-2'}}$
          </span>
        </ng-container>
        <ng-template #debit>
          <span class="green">
            {{row.debit / 1000 | number: '1.2-2'}}$
          </span>
        </ng-template>
      </td>
    </tr>
  </table>
</ng-container>

<!--  <style>-->
<!--    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap" rel="stylesheet');-->
<!--    .alfa-pay-button {-->
<!--      display: inline-block;-->
<!--      box-sizing: border-box;-->
<!--      min-height: 36px;-->
<!--      letter-spacing: 0.5px;-->
<!--      text-decoration: none;-->
<!--      font-family: 'Open Sans', sans-serif;-->
<!--      border-radius: 0px;-->
<!--      background-color: #0088cc;-->
<!--      border: 1px solid #0088cc;-->
<!--      color: #ffffff;-->
<!--      font-size: 15px;-->
<!--      background-position: 20px center;-->
<!--      background-size: 20px 20px;-->
<!--      padding: 0px 16px;-->
<!--      background-image: none;-->
<!--      background-repeat: no-repeat;-->
<!--      transition: all ease 0.5s;-->
<!--      font-size: 15px;-->
<!--      font-weight: 600;-->
<!--      text-align: center;-->
<!--      width: 240px;-->
<!--      height: 36px;-->
<!--      line-height: 34px;-->
<!--    }-->

<!--    .alfa-pay-button:hover {-->
<!--      opacity: .75;-->
<!--    }-->

<!--    .alfa-pay-button i {-->
<!--      display: inline-block;-->
<!--      vertical-align: sub;-->
<!--      position: relative;-->
<!--      margin-right: 8px;-->
<!--      height: 20px;-->
<!--      width: 20px;-->
<!--    }-->

<!--    .alfa-pay-button i > svg {-->
<!--      height: 100%;-->
<!--      width: 100%;-->
<!--    } </style>-->
<!--  <a href="https://www.alfacoins.com/checkout?button_id=627fca3ee26cb" class="alfa-pay-button">-->
<!--    <i>-->
<!--      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="k-gray">-->
<!--        <title>Icon/pack/logo-g</title>-->
<!--        <path d="M14.286 5l-2.785 1.604-1.058.61.003-2.122-.709-.004-.709-.005V8.03l-.4.231L6.79 9.322 5 10.366l4.028 2.337v3.059h1.4l.003-2.017v-.228l3.805 2.209.352-.615.352-.616-2.782-1.61-1.723-.995.001-.266.005-2.781L15 6.224l-.357-.612L14.286 5zM9.028 9.654v1.423l-1.235-.713 1.235-.71z" fill="#9B9B9B"></path>-->
<!--      </svg>-->
<!--    </i>-->
<!--    <span>Pay 10</span>-->
<!--  </a>-->

<!--  <a href="https://www.alfacoins.com/checkout?button_id=627fca3ee26cb" target="_blank" class="btn btn-default">Pay 10$</a>-->
<!--  <button class="btn-default" (click)="getPaymentLink()">TIC</button>-->
  <div class="bordered" *ngIf="currencies$ | async as currencies">
    <form [formGroup]="form" (submit)="getPaymentLink()">
      <div class="flex-container">
        <div class="form-control">
          <label for="amount">{{ 'finances.amount' | translate }}</label>
          <input id="amount" formControlName="amount" type="text">
        </div>
        <div class="form-control">
          <label for="currency">{{ 'finances.currency' | translate }}</label>
          <select formControlName="currency" id="currency">
            <option *ngFor="let currency of currencies | filterCurrenciesByMinAmount:form.get('amount').value" [value]="currency.currency">{{currency.currency | titlecase}}</option>
          </select>
        </div>
      </div>
      <div class="flex-container">
        <div>
          <i>
            {{ 'finances.help' | translate }}
          </i>
        </div>
      </div>
      <div class="flex-container">
        <div class="form-control">
          <button class="btn btn-default" [disabled]="!form.valid || paymentButtonPushed">{{ 'finances.deposit' | translate }}</button>
        </div>
      </div>
    </form>
  </div>
<ng-template #appLoading>
  <app-loading></app-loading>
</ng-template>
</div>
<!--<div class="container">-->
<!--  <div class="card " *ngIf="group.rigs.length > 0; else noRigs">-->
<!--      <div class="flex-container align-center">-->
<!--        <div>-->
<!--          <input type="checkbox" class="custom-checkbox" id="checkAll" value="1" [checked]="checkAll === true" (change)="onCheckAll()">-->
<!--          <label for="checkAll"></label>-->
<!--        </div>-->
<!--        <div class="flex-grow-1 flex-container align-center">-->
<!--          <div class="flex-grow-1">-->
<!--            <input type="text" placeholder="{{'groups.findRig' | translate}}" (change)="nullifyCheckboxes()" [(ngModel)]="searchStr" style="margin-top: 0.3rem">-->
<!--          </div>-->
<!--          <div *ngIf="selectedCheckBoxes.length > 0" class="pinned-right pt-02">-->
<!--            <button class="btn btn-default mr-0 ml-02"-->
<!--              title="{{'groups.moveRigs' | translate}}"-->
<!--              (click)="openMoveWindow()"-->
<!--            >-->
<!--              <fa-icon [icon]="this.sharedModule.faExchangeAlt"></fa-icon>-->
<!--            </button>-->

<!--            <button class="btn btn-default mr-0 ml-02"-->
<!--              title="{{'groups.deleteRigs' | translate}}"-->
<!--              (click)="deleteRigs()"-->
<!--            >-->
<!--              <fa-icon [icon]="this.sharedModule.faTrash"></fa-icon>-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <form [formGroup]="form" >-->
<!--        <div *ngFor="let rig of group.rigs | filterRigs:searchStr" class="flex-container align-center">-->
<!--          <div>-->
<!--            <input type="checkbox" class="custom-checkbox" [formControlName]="rig.id" [id]="rig.id" value="1"-->
<!--              (change)="onChangeCheckBox()">-->
<!--            <label [for]="rig.id"></label>-->
<!--          </div>-->
<!--          <app-rig-row [rig]="rig" class="flex-grow-1"></app-rig-row>-->
<!--        </div>-->
<!--      </form>-->
<!--  </div>-->
<!--  <ng-template #noRigs>-->
<!--    {{'groups.noRigs' | translate}}-->
<!--  </ng-template>-->

<!--  <div class="flex-container">-->
<!--    <button class="btn btn-default pinned-right" (click)="createRig()"-->
<!--      title="{{'groups.createRig' | translate}}"-->
<!--    >-->
<!--      <fa-icon [icon]="sharedModule.faPlusSquare" class="active-element"></fa-icon>-->
<!--    </button>-->
<!--  </div>-->
<!--</div>-->



