<div id="main">
  <form [formGroup]="form" (ngSubmit)="formSubmit.emit(form.value)" style="padding: 0.5rem">
    <div class="flex-container">
      <div class="form-control flex-container vertical">
        <mat-slide-toggle formControlName="enabled">
          <span *ngIf="form.get('enabled').value; else disabled">
              {{ 'oc.afKeys.enabled' | translate }}
            </span>
          <ng-template #disabled>
            {{ 'oc.afKeys.disabled' | translate }}
          </ng-template>
        </mat-slide-toggle>
      </div>
    </div>
    <div *ngFor="let controlName of simpleControls" class="flex-container">
      <label [for]="controlName">{{ 'oc.afKeys.' + controlName | translate }}</label>
      <div class="form-control w-100">
        <input [id]="controlName" [formControlName]="controlName" type="text">
      </div>
    </div>

    <div class="flex-container form-buttons">
      <button type="submit" class="btn btn-success pinned-right mr-0">{{'save' | translate}}</button>
    </div>
  </form>
</div>
