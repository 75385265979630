import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {LocalStorageService} from '../../services/local-storage.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-edit-crit-temp',
  templateUrl: './edit-temp-limit.component.html'
})
export class EditTempLimitComponent implements OnInit {
  @Output() tempLimitEmit = new EventEmitter()
  form: FormGroup

  constructor(
    private localStorageService: LocalStorageService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EditTempLimitComponent>
  ) { }

  ngOnInit(): void {
    let tempLimit = this.localStorageService.tempLimit
    this.form = this.fb.group({
      tempLimit: [tempLimit],
    })
  }

  submit() {
    this.dialogRef.close(this.form.value.tempLimit)
  }
}
