import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WdTemp} from '../../interfaces';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-edit-wd-temp',
  templateUrl: './edit-wd-temp.component.html'
})
export class EditWdTempComponent implements OnInit {
  @Input() data: WdTemp
  @Output() formSubmit = new EventEmitter()
  section = 'wd_temp'
  form: FormGroup
  simpleControls = [
    'critTemp',
    'critTempMem',
    'critTempCpu'
  ]
  boolControls = [
    'enabled',
    'notifications'
  ]
  actions = [
    'pause',
    'reboot',
    'shutdown'
  ]
  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    console.log('this WD TEMP', this.data)

    this.form = this.fb.group({
      enabled: [''],
      notifications: [''],
      critTemp: [''
        // [
        //   Validators.required,
        //   Validators.min(0),
        //   Validators.max(100)
        // ]
      ],
      critTempMem: [''],
      critTempCpu: [''],
      resumeTemp: ['',
        [
          Validators.min(0),
          Validators.max(100)
        ]
      ],
      action: ['', [
        Validators.required,
      ]],
    })

    this.form.patchValue(this.data)
  }

  submit() {
    let data = {
      wd_temp: this.form.value
    }
    this.formSubmit.emit(data)
  }
}
