<div id="main" class="bordered active-element card-color">
<!--  {{manifest | json}}-->
  <div *ngIf="!addNew" class="flex-container no-wrap">
    <div class="flex-grow-1 profile-body">
      <div class="flex-container">
        <div>
          <fa-icon *ngIf="manifest.favorite" [icon]="this.sharedModule.faStar" class="gold" title="{{'manifests.favoriteToggleOff' | translate}}"
          ></fa-icon>
          <fa-icon *ngIf="!manifest.favorite" [icon]="this.sharedModule.farStar" title="{{'manifests.favoriteToggleOn' | translate}}"
          ></fa-icon>
          {{manifest.name}}
        </div>
        <div *ngIf="showUsing" class="pinned-right">
          <span *ngIf="manifest.usingOnline && manifest.usingOnline.length > 0" class="note note-success" title="{{'manifests.using' | translate}}" (click)="showRigs($event, manifest.usingOnline, manifest.usingOffline)">{{manifest.usingOnline.length}}</span>
          <span *ngIf="manifest.usingOffline && manifest.usingOffline.length > 0" class="note note-danger" title="{{'manifests.using' | translate}}" (click)="showRigs($event, manifest.usingOnline, manifest.usingOffline)">{{manifest.usingOffline.length}}</span>
        </div>
      </div>

      <div class="flex-container">
        <div class="manifest-info">{{manifest.wallet | cutText: walletLength}}</div>
        <div class="manifest-info pinned-right">{{manifest.pool | cutText: poolLength}}</div>
      </div>
      <div class="flex-container">
        <div class="manifest-info">{{manifest.algo}}</div>
        <div class="manifest-info pinned-right">{{getMinerDescription(manifest.miner)}}</div>
      </div>
    </div>

    <div *ngIf="controls && !loading" class="flex-container vertical pinned-right controls">
      <div (click)="controlOnClick($event, 'removeManifest')">
        <fa-icon [icon]="this.sharedModule.faTimesCircle" title="{{ 'manifests.removeManifest' | translate }}" class="active-element"></fa-icon>
      </div>
      <div (click)="controlOnClick($event, 'restartManifest')">
        <fa-icon [icon]="this.sharedModule.faRedo" title="{{ 'manifests.restartManifest' | translate }}" class="active-element"></fa-icon>
      </div>
      <div (click)="controlOnClick($event, 'editProfile')">
        <fa-icon [icon]="this.sharedModule.faEdit" title="{{ 'manifests.editProfile' | translate }}" class="active-element"></fa-icon>
      </div>
    </div>
    <div *ngIf="loading">
      <fa-icon [icon]="this.sharedModule.faSpinner" [spin]="true"></fa-icon>
    </div>
  </div>

  <div *ngIf="addNew">
    <div class="flex-container">
      <div>{{ 'manifests.selectManifest' | translate }}</div>
      <div *ngIf="loading" class="pinned-right">
        <fa-icon [icon]="this.sharedModule.faSpinner" [spin]="true"></fa-icon>
      </div>
    </div>
  </div>
</div>
