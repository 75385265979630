<form [formGroup]="form" (ngSubmit)="submit()" class="login-form">
  <h2 mat-dialog-title>{{ 'login.loginTitle' | translate }}</h2>
  <mat-dialog-content>
      <div class="alert alert-danger" *ngIf="this.auth.error$ | async as error">
        {{ error }}
      </div>

      <div class="form-control" [ngClass]="{invalid: form.get('login').touched && form.get('login').invalid}">
        <label for="login">{{ 'login.login' | translate }}</label>
        <app-input-validation [component]="form.get('login')"></app-input-validation>
        <input id="login" type="text" formControlName="login">
      </div>

    <div class="form-control" [ngClass]="{invalid: form.get('password').touched && form.get('password').invalid}">
      <label for="password">{{ 'login.password' | translate }}</label>
      <app-input-validation [component]="form.get('password')"></app-input-validation>
      <input id="password" type="password" formControlName="password">
    </div>

    <div class="form-control" [ngClass]="{invalid: form.get('twofa').touched && form.get('twofa').invalid}">
      <label for="twofa">{{ 'login.twofa' | translate }}</label>
      <app-input-validation [component]="form.get('twofa')"></app-input-validation>
      <input id="twofa" type="text" formControlName="twofa" appOnlyNumbers>
    </div>

    <mat-slide-toggle formControlName="remember">{{ 'login.remember' | translate }}</mat-slide-toggle>

<!--    <mat-slide-toggle (click)="onToggle()">{{ 'login.remember' | translate }}</mat-slide-toggle>-->
  </mat-dialog-content>
  <mat-dialog-actions>
    <div>
      <i class="active-element" (click)="openForgotPasswordDialog()">{{'login.forgotPassword' | translate}}</i>
    </div>
    <div class="flex-container pinned-right">
      <button class="btn btn-default" [mat-dialog-close]="true">{{ 'cancel' | translate }}</button>
      <button
        type="submit"
        class="btn btn-default"
        [disabled]="form.invalid || formSubmitted"
      >{{ 'login.loginButton' | translate }}</button>
    </div>
  </mat-dialog-actions>
</form>
