import {Component, OnInit} from '@angular/core'
import {RigComponent} from '../../rig.component'
import {OsDataMiner, OsVersion, Rig} from '../../../shared/interfaces'
import {Observable, timer} from 'rxjs'
import {first, map, switchMap} from 'rxjs/operators'
import {SharedModule} from '../../../shared/shared.module'
import {LocalStorageService} from '../../../shared/services/local-storage.service'
import {OsService} from '../../../shared/services/os.service'

@Component({
  selector: 'app-rig-stats',
  templateUrl: './rig-stats.component.html',
  styleUrls: ['./rig-stats.component.scss']
})
export class RigStatsComponent implements OnInit {
  rig$: Observable<Rig>
  osData$: Observable<OsVersion[]>
  osDataMiners: OsDataMiner[]

  showAdditionalSysInfo: boolean

  tempLimit: number
  delay: number
  Object = Object

  constructor(
    public rigComponent: RigComponent,
    public sharedModule: SharedModule,
    private localStorageService: LocalStorageService,
    private osService: OsService
  ) { }

  async ngOnInit() {
    this.tempLimit = this.localStorageService.tempLimit
    this.osDataMiners = await this.osService.getMiners().toPromise()
    this.rig$ = this.rigComponent.route.params
      .pipe(
        switchMap((params) => timer(0, this.rigComponent.refreshTime)
          .pipe(
            switchMap (() => {
              return this.rigComponent.rigsService.get(params.id, 'stats')
                .pipe(
                  map((rigData) => {
                    // console.log ('rigData', rigData)
                    return this.rigComponent.dataPreparationService.prepareRigData(rigData)
                  })
                )
            })
          )
        )
      )
    this.osData$ = this.osService.getSystem()
    this.showAdditionalSysInfo = this.localStorageService.additionalSysInfo
  }
  toggleAdditionalSysInfo() {
    this.showAdditionalSysInfo = !this.showAdditionalSysInfo
    this.localStorageService.setShowAdditonalSysInfo(this.showAdditionalSysInfo)
  }



  sendCommand(command: string, args: any) {
    let commandData = {
        command,
        args
    }
    this.rigComponent.route.params
      .pipe(
        first(),
        map((params) => {
          // console.log('send command', +params.id, commandData)
          return this.rigComponent.rigsService.command(+params.id, commandData)
          })
        ).toPromise()
  }

  getBios(bus: string, brand: string) {
    if (brand === 'N') {
      this.sendCommand('get_nvidia_bios', bus)
    } else if (brand === 'A') {
      this.sendCommand('get_amd_bios', bus)
    }
  }

  stopFan(bus: string, brand: string) {
    this.sendCommand('exec', `fanstop --brand ${brand} --bus ${bus}`)
  }

  getMinerDescription(minerName: string): string {
    const index = this.osDataMiners.findIndex(el => el.name === minerName)
    return index !== -1 ? this.osDataMiners[index].description : minerName
  }

  isNumber(value: any) {
    return !isNaN(+value)
  }
}
