<div class="container-wide flex-container ml-0 section">
  <div class="ml-0 mt-0 p-0 sub-nav-panel-wrapper">
    <div class="nav-panel sub-nav-panel">
      <div class="menu-link tab" [ngClass]="{active: subMethod === 'amd'}" (click)="onClick('amd')">{{'oc.amd' | translate}}</div>
      <div class="menu-link tab" [ngClass]="{active: subMethod === 'nvidia'}" (click)="onClick('nvidia')">{{'oc.nvidia' | translate}}</div>
      <div class="menu-link tab" [ngClass]="{active: subMethod === 'af'}" (click)="onClick('af')">{{'oc.af' | translate}}</div>
    </div>
  </div>
  <div class="container mt-0 w-100">
    <div *ngIf="!loading; else appLoading" class="card mt-0">
      <div class="card-content">
        <ng-template [ngIf]="subMethod === 'amd'">
          <div class="flex-container">
            <label class="visible-xl">{{'oc.selectedProfile' | translate}}</label>
            <app-oc-row class="pinned-right" [oc]="oc.amd" brand="amd" (click)="openSelect(oc, 'amd')"></app-oc-row>
          </div>
          <hr>
          <app-edit-oc-amd [owner]="oc.owner" [oc]="oc.amd" (formSubmit)="ocFormSubmit($event)"></app-edit-oc-amd>
        </ng-template>

        <ng-template [ngIf]="subMethod === 'nvidia'">
          <div class="flex-container">
            <label class="visible-xl">{{'oc.selectedProfile' | translate}}</label>
            <app-oc-row class="w-50 pinned-right" [oc]="oc.nvidia" brand="nvidia" (click)="openSelect(oc, 'nvidia')"></app-oc-row>
          </div>
          <hr>
          <app-edit-oc-nvidia [owner]="oc.owner" [oc]="oc.nvidia" (formSubmit)="ocFormSubmit($event)"></app-edit-oc-nvidia>
        </ng-template>

        <ng-template [ngIf]="subMethod === 'af'">
          <app-edit-oc-af [af]="oc.af" (formSubmit)="afFormSubmit($event)"></app-edit-oc-af>
        </ng-template>
      </div>
    </div>
    <ng-template #appLoading>
      <app-loading></app-loading>
    </ng-template>
  </div>

</div>
