<ng-container *ngIf="group$ | async as group; else somethingWrong">
  <div class="container-wide flex-container pb-02 mb-0">
    <div>
      <h4>
        <a [routerLink]="['/groups']">{{ 'links.groups' | translate}}</a>
        >
        <ng-template [ngIf]="!newGroup">
          {{group.name}}
        </ng-template>
        <ng-template [ngIf]="newGroup">
          {{'groups.creation' | translate}}
        </ng-template>
      </h4>
    </div>
  </div>

  <div class="container-wide flex-container pt-02 mt-0 section">
    <div class="mt-0 sub-nav-panel-wrapper">
      <div *ngIf="!newGroup" class="nav-panel sub-nav-panel">
        <div class="menu-link tab" [ngClass]="{active: subMethod === 'rigs'}" (click)="selectSubMethod('rigs')">{{'groups.rigs' | translate}}</div>
        <div class="menu-link tab" [ngClass]="{active: subMethod === 'settings'}" (click)="selectSubMethod('settings')">{{'groups.settings' | translate}}</div>
        <div class="menu-link tab" [ngClass]="{active: subMethod === 'finances'}" (click)="selectSubMethod('finances')">{{'groups.finances' | translate}}</div>
      </div>
    </div>
    <div class="container mt-0 w-100">
      <div *ngIf="group$ | async as group; else somethingWrong" class="card mt-0">
        <div class="card-content">
          <ng-template [ngIf]="subMethod === 'rigs'">
            <ng-container *ngIf="groupData$ | async as groupData; else somethingWrong">
              <app-group-rigs [groupId]="group.id" [group]="groupData"></app-group-rigs>
            </ng-container>
          </ng-template>

          <ng-template [ngIf]="subMethod === 'settings'">
            <app-group-settings [group]="group" [newGroup]="newGroup"></app-group-settings>
          </ng-template>

          <ng-template [ngIf]="subMethod === 'finances'">
            <ng-container *ngIf="groupData$ | async as groupData; else somethingWrong">
              <app-group-finances [groupId]="group.id"></app-group-finances>
            </ng-container>
          </ng-template>
        </div>
      </div>

    </div>

  </div>
</ng-container>

<ng-template #somethingWrong>
  <ng-template [ngIf]="(group$ | async) !== null">
    <app-loading></app-loading>
  </ng-template>
  <ng-template [ngIf]="(group$ | async) === null">
    <div class="container flex-container center">
      <div>
        {{'groups.noGroupFound' | translate}}
        <br>
        <a [routerLink]="['/groups']"><span class="note note-default">{{'groups.toGroupsLink' | translate}}</span></a>
      </div>
    </div>
  </ng-template>
</ng-template>
