import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {SharedModule} from '../../shared.module';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Group} from '../../interfaces';

@Component({
  selector: 'app-move-entities',
  templateUrl: './move-entities.component.html'
})
export class MoveEntitiesComponent implements OnInit {
  form: FormGroup
  @Output() formSubmit = new EventEmitter()
  constructor(
    public sharedModule: SharedModule,
    private dialogRef: MatDialogRef<MoveEntitiesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      groups: Group[]
    }
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      group: new FormControl ()
    })
  }

  submit() {
    this.dialogRef.close(+this.form.value.group);
  }
}
