import {Pipe, PipeTransform} from '@angular/core';
import {Currency} from '../interfaces';

@Pipe({
  name: 'filterCurrenciesByMinAmount'
})
export class FilterCurrenciesByMinAmountPipe implements PipeTransform {
  transform(currencies: Currency[], amount = 0): Currency[] {
    if (amount <= 0) {
      return currencies
    }
    return currencies.filter(currency => {
      if (currency.minAmountUSD > amount) {
        return false
      }
      return true
    })
  }
}
