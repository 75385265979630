import {Component, OnInit} from '@angular/core'
import {SharedModule} from '../shared.module'
import {environment} from '../../../environments/environment'
import {TranslateService} from '@ngx-translate/core'
import {Link, ThemeOption} from '../interfaces'
import {Observable} from 'rxjs'
import {ThemeService} from '../services/theme.service'
import {AuthService} from '../services/auth.service'
import {Event, NavigationStart, Router} from '@angular/router'
import {MatDialog} from '@angular/material/dialog'
import {LoginFormComponent} from '../components/login-form/login-form.component'
import {OsService} from '../services/os.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit{
  menuState: any;
  imgUrl = 'assets/img/256.png'
  chatlink = environment.chatLink
  themeOptions$: Observable<ThemeOption[]> = this.themeService.getThemeOptions()

  links: Link[] = [
    {
      name: 'monitor',
      icon: this.sharedModule.faTv,
      link: '/monitor'
    },
    {
      name: 'groups',
      icon: this.sharedModule.faSitemap,
      link: '/groups'
    },
    {
      name: 'profiles',
      icon: this.sharedModule.faToolbox,
      link: '/profiles'
    },
    {
      name: 'oc',
      icon: this.sharedModule.faFan,
      link: '/oc'
    }
  ]
  constructor(
    public sharedModule: SharedModule,
    private translateService: TranslateService,
    private themeService: ThemeService,
    public authService: AuthService,
    public router: Router,
    private dialog: MatDialog,
    private osService: OsService


  ) { }
  ngOnInit() {
    this.osService.getSystem()
      .pipe(
        map(data => {
          const index = data.findIndex(el => el.section === 'frontend')
          if (index !== -1 && environment.version < data[index].current_ver) {
              this.sharedModule.pushAlert('We have updated the frontend. Force reload is required')
              console.log ('current ver:', environment.version)
              console.log ('latest  ver:', data[index].current_ver)
          }
        })
      ).toPromise().then()

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.menuState = false
      }
      // if (event instanceof NavigationEnd) {
      //   // console.log ('change route STOP')
      // }
      //
      // if (event instanceof NavigationError) {
      //   console.log('Navigation error', event.error);
      // }
    })
  }

  themeChangeHandler(themeToSet): void {
    this.themeService.setTheme(themeToSet);
  }

  openDialog(): void {
    // const dialogRef =
    this.dialog.open(LoginFormComponent, {
      panelClass: 'custom-dialog-container',
      maxWidth: undefined
    })
  }

  logout(event) {
    event.preventDefault();
    // console.log ('logout')
    this.authService.logout()
    this.router.navigate([''])
  }
}
