<fa-icon mat-button [icon]="this.sharedModule.faPallete" [matMenuTriggerFor]="themeMenu" class="menu-link"></fa-icon>
<mat-menu #themeMenu="matMenu">
  <button
    *ngFor="let option of options"
    mat-menu-item
    (click)="changeTheme(option.value)">

    <span>{{ 'theme.' + option.label | translate }}</span>
  </button>
</mat-menu>
