import { Component, OnInit } from '@angular/core';
import {RigsService} from '../../../shared/services/rigs.service';
import {map, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {RigComponent} from '../../rig.component';
import {DisplayHrPipe} from '../../../shared/pipes/display-hr';
import {TranslateService} from '@ngx-translate/core';
import {SharedModule} from '../../../shared/shared.module';

@Component({
  selector: 'app-rig-graph',
  templateUrl: './rig-graph.component.html'
})
export class RigGraphComponent implements OnInit {
  constructor(
    private rigComponent: RigComponent,
    private rigsService: RigsService,
    private displayHrPipe: DisplayHrPipe,
    public translateService: TranslateService,
    public sharedModule: SharedModule
  ) {
  }
  data$: Observable<any>
  noGraphData = false
  loading = true

  // Graph options
  // view: any[] = [700, 300]
  showLabels = true
  animations = false
  showGridLines = true
  timeline = false;
  xScaleMin: number
  xScaleMax: number
  yScaleMaxRaw = 100 // default
  yScaleMax = 120 // default
  // xAxisTicks = []
  // legend = false;
  // showYAxisLabel = true;
  // showXAxisLabel = true;
  // xAxisLabel = 'Hashrate';
  // yAxisLabel = 'Date';
  colorScheme = {
    domain: ['rgb(51, 102, 102)', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  }

  localChartColours: any = [
    {
      backgroundColor: 'rgb(78,180,189)',
      pointBackgroundColor: 'rgba(78, 180, 189, 1)',
      pointHoverBackgroundColor: 'rgba(151,187,205,1)',
      borderColor: 'rgba(0,0,0,0)',
      pointBorderColor: '#fff',
      pointHoverBorderColor: 'rgba(151,187,205,1)'
    }
  ]

  forcePush(object, algo, data) {
    let index = object.findIndex((el) => el.name === algo)
    if (index === -1) {
      index = object.length
      object.push({name: algo, series: []})
    }

    object[index].series.push(data)
    return object
  }

  transformTime(val: any) {
    const date = new Date(+val * 1000)
    const options: any = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      formatMatcher: 'best fit'
    }

    return new Intl.DateTimeFormat(undefined, options).format(date)
  }

  ngOnInit(): void {
    this.data$ = this.rigComponent.route.params
      .pipe(
        switchMap((params) => {
          return this.rigComponent.rigsService.getGraphData(params.id)
            .pipe(
              map((data) => {
                if (! data) {
                  // console.log('ASSSSSSSSSSSSSS') ;
                  // this.loading = false
                  this.noGraphData = true
                  return null
                }
                let graphData = []
                // console.log ('datadata', data)
                this.xScaleMin = Math.floor(Date.now() / 1000 - 60 * 60 * 24)
                this.xScaleMax = Math.floor(Date.now() / 1000)
                data.forEach((item) => {
                  if (item.hr.length !== 0) {
                    item.hr.forEach((hrItem) => {
                      let val = +this.displayHrPipe.transform(hrItem.hr, false, true)
                      if (val > this.yScaleMaxRaw) {
                        this.yScaleMaxRaw =  val
                      }
                      let obj = {
                        name: +item.time,
                        value: val
                      }
                      graphData = this.forcePush (graphData, hrItem.algo, obj)
                    })
                  }
                })
                // console.log ('graphData: ', graphData)
                this.yScaleMax = this.yScaleMaxRaw * 1.2
                // this.loading = false
                return graphData
              })
            )
        })
      )

    // this.rigComponent.route.params
    //   .pipe(
    //     switchMap((params) => {
    //       return this.rigComponent.rigsService.getGraphData2(params.id)
    //
    //     })
    //   ).subscribe((data) => {
    //     console.log ('graph', data)
    //   })
  }
}
