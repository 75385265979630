import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogWrapperComponent} from '../dialog-wrapper/dialog-wrapper.component';
import {ControlPanelService} from '../../services/control-panel.service';
import {SharedModule} from '../../shared.module';
import {RigsService} from '../../services/rigs.service';
import {LocalStorageService} from '../../services/local-storage.service';
import {EditTempLimitComponent} from '../edit-temp-limit/edit-temp-limit.component';
import {ApplyMultiProfilesComponent} from '../apply-multi-profiles/apply-multi-profiles.component';
import {ControlPanelCommand, Profile} from '../../interfaces';
import {GroupsService} from '../../services/groups.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.scss']
})
export class ControlPanelComponent implements OnInit {
  commandFrequency = 5000
  commandLocked = false
  viewMode: string

  constructor(
    public controlPanelService: ControlPanelService,
    public sharedModule: SharedModule,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private rigsService: RigsService,
    private groupsService: GroupsService
  ) { }

  ngOnInit() {
    this.viewMode = this.localStorageService.viewMode
  }

  openActions() {
    let dialogRef = this.dialog.open(DialogWrapperComponent, {
      panelClass: 'custom-dialog-container',
      data: 'app-rig-actions-commands',
      maxWidth: undefined
    })

    let sub = dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.controlPanelService.rigIds.forEach((rigId) => {
          this.rigsService.command(rigId, data)
        })
        // console.log ('After dialog close', data)
      }
    })
  }

  openProfiles() {
    let dialogRef = this.dialog.open(ApplyMultiProfilesComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        groupId: this.controlPanelService.groupId
      },
      maxWidth: undefined
    })

    let sub = dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        // console.log ('got data', data)

        if (data.command === 'stop') {
          this.controlPanelService.rigIds.forEach((rigId) => {
            this.changeManifest(rigId, data.profileNumber, null)
          })
        } else if (data.command === 'apply' && data.profile) {
          this.controlPanelService.rigIds.forEach((rigId) => {
            this.changeManifest(rigId, data.profileNumber, data.profile)
          })
        }
        // this.controlPanelService.rigIds.forEach((rigId) => {
        //   this.rigsService.command(rigId, data)
        // })
      }
    })
  }

  deleteMessages() {
    if (this.controlPanelService.rigIds.length === 0) {
      return
    }

    this.groupsService.deleteMessages(this.controlPanelService.groupId, this.controlPanelService.rigIds)
      .pipe(
        map(result => {
          if (!result) {
            const command: ControlPanelCommand = {
              command: 'deleteMessages',
              arguments: {
                rigIds: this.controlPanelService.rigIds,
                groupId: this.controlPanelService.groupId
              }
            }
            this.emitToMonitor(command)
            this.sharedModule.pushAlert('Messages deleted', 'success')
          } else { // don`t know what should be to trigger that
            this.sharedModule.pushAlert(result, 'warning')
          }
        })
      ).toPromise().catch(error => {
        this.sharedModule.pushAlert(error.statusText, 'warning')
    })
    // this.groupsService.deleteMessages()
    // let dialogRef = this.dialog.open(DialogWrapperComponent, {
    //   panelClass: 'custom-dialog-container',
    //   data: 'app-rig-actions-commands',
    //   maxWidth: undefined
    // })
    //
    // let sub = dialogRef.afterClosed().subscribe((data) => {
    //   if (data) {
    //     this.controlPanelService.rigIds.forEach((rigId) => {
    //       this.rigsService.command(rigId, data)
    //     })
    //     // console.log ('After dialog close', data)
    //   }
    // })
  }

  editTempLimit() {
    let dialogRef = this.dialog.open(EditTempLimitComponent, {
      panelClass: 'custom-dialog-container',
      maxWidth: undefined
    })

    let sub = dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.localStorageService.setTempLimit(data)
        const command: ControlPanelCommand = {
          command: 'tempLimit'
        }
        this.emitToMonitor(command)
      }
      sub.unsubscribe()
    })
  }

  emitToMonitor(command: ControlPanelCommand) {
    this.commandLocked = true
    this.controlPanelService.monitorCommands$.next(command)
    setInterval(() => {
      this.commandLocked = false
    }, this.commandFrequency)
  }

  setViewMode(viewMode: string) {
    this.viewMode = viewMode
    this.localStorageService.setViewMode(viewMode)
    const command: ControlPanelCommand = {
      command: 'viewMode'
    }
    this.emitToMonitor(command)
  }

  changeManifest(rigId: number, num: number, manifest: Profile|null) {
    let manifestId = null
    if (manifest) {
      manifestId = manifest.id
    }
    let data: Profile[] = [{num, id: manifestId}]
    let sub = this.rigsService.changeProfile(rigId, data)
      .subscribe((result) => {

        let alertText: string
        if (! manifest) { // stop profile
          alertText = 'Profile removed'
        } else if (result === 'OK') {
          alertText = 'Profile applied'
        } else {
          alertText = result
        }
        this.sharedModule.pushAlert(alertText, 'success')
        sub.unsubscribe()
      }, (result) => {
        this.sharedModule.pushAlert(`Error: ${result.statusText}`, 'warning')
        sub.unsubscribe()
      })
  }
}
