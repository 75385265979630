import {Component, Input, OnInit} from '@angular/core';
import {AutofanNapalmData} from '../../interfaces'
import {SharedModule} from '../../shared.module'
import {RigStatsComponent} from '../../../rig-page/components/rig-stats/rig-stats.component';

@Component({
  selector: 'app-autofan-napalm',
  templateUrl: './autofan-napalm.component.html',
  styleUrls: ['./autofan-napalm.component.scss']
})
export class AutofanNapalmComponent implements OnInit {
  @Input() data: AutofanNapalmData
  calibratePushed = false

  constructor(
    // private localStorageService: LocalStorageService,
    private rigStats: RigStatsComponent,
    public sharedModule: SharedModule
  ) {
  }

  ngOnInit(): void {
    // this.autofanNapalmShowZeroFan = this.localStorageService.autofanNapalmShowZeroFan
  }

  Calibrate() {
    this.calibratePushed = true
    this.rigStats.sendCommand('exec', 'autofan-napalm set calibrate_request 0')
    setTimeout(function() {
      this.calibratePushed = false
      // console.log('Delayed for 2 second.')
      // console.log(this.calibratePushed)
    }, 2000);
  }
}
