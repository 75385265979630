import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Rig} from '../../../shared/interfaces';

@Component({
  selector: 'app-create-rig',
  templateUrl: './create-rig.component.html',
  styleUrls: ['./create-rig.component.scss']
})
export class CreateRigComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<CreateRigComponent>,
  ) { }

  ngOnInit(): void {
  }

  formSubmit(data: Rig) {
    if (Object.keys(data).length) {
      this.dialogRef.close(data)
    }
  }

}
