<div class="container">
  <div class="card">
    <div class="card-label">
      <h2>
        {{ 'login.restorePassword' | translate }}
      </h2>
    </div>
    <div class="card-content">
      <ng-container *ngIf="token; else appLoading">
        <ng-container *ngIf="token.length > 130; else wrongToken">

          <form [formGroup]="form" (ngSubmit)="submit()" class="login-form">
            <div class="form-control">
              <label for="password">{{ 'signUp.password' | translate }}</label>
              <app-input-validation [component]="form.get('password')"></app-input-validation>
              <input id="password" formControlName="password" type="password" autocomplete="new-password">
            </div>
            <div class="form-control">
              <label for="passwordConfirmation">{{ 'signUp.passwordConfirmation' | translate }}</label>
              <app-input-validation [component]="form.get('passwordConfirmation')"></app-input-validation>
              <input id="passwordConfirmation" formControlName="passwordConfirmation" type="password" autocomplete="new-password">
            </div>
            <button class="btn btn-default" [disabled]="!form.valid" type="submit">{{ 'save' | translate }}</button>

          </form>

        </ng-container>
        <ng-template #wrongToken>
          {{ 'confirmMail.wrongToken' | translate }}
        </ng-template>
      </ng-container>
      <ng-template #appLoading>
        <app-loading></app-loading>
      </ng-template>
    </div>
  </div>
</div>
