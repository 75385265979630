import { Component, OnInit } from '@angular/core';
import {switchMap} from 'rxjs/operators';
import {Observable, of, Subscription} from 'rxjs';
import {Group} from '../../../shared/interfaces';
import {ActivatedRoute, Router} from '@angular/router';
import {GroupsService} from '../../../shared/services/groups.service';
import {DataPreparationService} from '../../../shared/services/data-preparation.service';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {
  subMethod = 'rigs'
  loading = true
  subs: Subscription[] = []
  group$: Observable<Group>
  groupData$: Observable<Group> // with rigs data
  newGroup = false
  finances: any

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private groupsService: GroupsService,
    private dataPreparationService: DataPreparationService
  ) { }

  ngOnInit() {
    this.fetchData()
    if (this.subMethod !== 'settings') { // group data already fetched
      this.selectSubMethod(this.subMethod)
    }
  }
  private fetchData(data?: string): void {
    this.group$ = this.route.params
      .pipe(
        switchMap((params) => {
          let result: Observable<Group>
          if (params && params.id && +params.id > 0) {
            result = this.groupsService.getGroup(+params.id, data)
          } else {
            if (params.id === 'new') {
              this.newGroup = true
              this.subMethod = 'settings'
            }
            result = of({})
          }
          return result
        })
      )
  }
  async selectSubMethod(subMethod: string) {
    this.subMethod = subMethod

    if (subMethod === 'settings') {
      this.fetchData()
    } else if (subMethod === 'rigs') { // rigs
      this.groupData$ = this.route.params
        .pipe(
          switchMap((params) => {
            let result: Observable<Group>
            if (params && params.id && +params.id > 0) {
              result = this.dataPreparationService.getFarmRigsData(+params.id, 'groupData')
            } else {
              if (params.id === 'new') {
                this.newGroup = true
              }
              result = of({})
            }
            return result
          })
        )
    } else if (subMethod === 'finances') { // rigs
      // this.route.params
      //   .pipe(
      //     switchMap((params) => {
      //       return this.groupsService.getGroup(+params.id, 'finances')
      //     })
      //   ).subscribe(kia => {
      //     console.log ('kia', kia)
      // })
      // console.log ('this.finances', this.finances)
      // this.finances = await this.route.params
      //   .pipe(
      //     switchMap((params) => {
      //       return this.groupsService.getGroup(+params.id, 'finances')
      //     })
      //   ).toPromise()
      //
      // console.log ('this.finances 2', this.finances)
    }
  }
}
