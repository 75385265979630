import {Component, Inject, OnInit} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog'
import {Profile, OsDataMiner, OsDataAlgo} from '../../interfaces'
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import {environment} from '../../../../environments/environment'
import {SharedModule} from '../../shared.module'
import {Observable} from 'rxjs'
import {Clipboard} from '@angular/cdk/clipboard'
import {PasteProfileComponent} from '../paste-profile/paste-profile.component'
import {map} from 'rxjs/operators'
import {OsService} from '../../services/os.service'
import {CustomSelectComponent} from '../custom-select/custom-select.component'

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  environment = environment
  form: FormGroup
  manifest: Profile
  osDataMiners: OsDataMiner[]
  osDataAlgos: OsDataAlgo[]
  osDataMiner: OsDataMiner
  miner: string

  constructor(
    public sharedModule: SharedModule,
    private clipboard: Clipboard,
    private dialogRef: MatDialogRef<EditProfileComponent>,
    private fb: FormBuilder,
    private osService: OsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {
      manifest$: Observable<Profile>,
      osDataMiners: OsDataMiner[],
      osDataAlgos: OsDataAlgo[]
    }
  ) { }

  async ngOnInit() {
    this.manifest = await this.data.manifest$.toPromise()
    this.osDataMiners = this.data.osDataMiners
    this.osDataAlgos = this.data.osDataAlgos

    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(1)]],
      favorite: [false],
      coin: [''],
      customCoin: [''],
      wallet: [''],
      template: ['$ADDRESS.$WORKER'],
      pool: [''],
      pass: [''],
      miner: [''],
      addition: [''],
      algo: [''],
      fork: ['latest'],
      config: [''],
      config_pools: [''],
      config_nvidia: [''],
      config_amd: [''],
      config_cpu: [''],
      epools: [''],
      epools_type: [2],
      dual_wallet: [''],
      dual_pool: [''],
      dual_template: [''],
      dual_pass: [''],
      no_stats: [false],
      usingOnline: [''],
      usingOffline: [''],
      custom_url: [''],
    })
    if (this.manifest) {
      // console.log(this.manifest)
      this.form.patchValue(this.manifest)
      this.miner = this.manifest.miner
      let index = this.osDataMiners.findIndex((el) => el.name === this.miner)
      this.osDataMiner = this.osDataMiners[index]
    }

    if (! environment.defaultCoins.includes(this.manifest.coin)){
      this.form.controls.coin.setValue('custom');
      this.form.controls.customCoin.setValue(this.manifest.coin);
    }
  }

  changeMiner() {
    this.miner = this.form.get('miner').value
    let index = this.osDataMiners.findIndex((el) => el.name === this.miner)
    this.osDataMiner = this.osDataMiners[index]
  }

  toggleFavorite() {
    const value = this.form.get('favorite').value
    this.form.controls.favorite.setValue(!value)
  }

  submit() {
    if (this.form.controls.coin.value === 'custom') {
      this.form.controls.coin.setValue(this.form.controls.customCoin.value)
    }
    this.form.removeControl('customCoin')
    let data = {}
    Object.keys(this.form.value).forEach((key) => {
      if (this.form.value[key] || (!this.form.value[key] && this.manifest[key])) {
        data[key] = this.form.value[key]
      }
    })
    this.dialogRef.close(data)
  }

  copyProfile() {
    let profile = Object.assign({}, this.form.value)
    delete profile.usingOffline
    delete profile.usingOnline
    delete profile.favorite
    if (! profile.epools) {
      delete profile.epools_type
    }
    let data = {}
    Object.keys(profile).forEach((key) => {
      if (profile[key]) {
        data[key] = profile[key]
      }
    })
    this.clipboard.copy(JSON.stringify(data))
    this.sharedModule.pushAlert('Copied to clipboard', 'success')
    // console.log (data)
  }

  pasteProfile() {
    let dialogRef = this.dialog.open(PasteProfileComponent, {
      panelClass: 'custom-dialog-container',
      maxWidth: undefined
    })

    dialogRef.afterClosed()
      .pipe(
        map(data => {
          if (data) {
            // console.log(data)
            this.form.reset()
            this.form.patchValue(data)
            this.normalize()
          }
        })
      ).toPromise()
  }

  normalize() {
    this.changeMiner()
    if (this.form.controls.algo.value === null) {
      this.form.controls.algo.setValue('')
    }
    const coin = this.form.controls.coin.value
    if (! environment.defaultCoins.includes(coin)){
      this.form.controls.customCoin.setValue(coin)
      this.form.controls.coin.setValue('custom')
    }
  }

  async openTemplates() {
    this.osDataMiners = await this.osService.getMiners().toPromise()

    await this.osService.getProfilesExamples()
      .pipe(
        map(data => {
          let dialogRef = this.dialog.open(CustomSelectComponent, {
            data: {
              manifests: data,
              osDataMiners: this.osDataMiners
            }
          })

          dialogRef.afterClosed()
            .pipe(
              map(profile => {
                if (profile) {
                  // console.log('got profile', profile)
                  this.form.reset()
                  this.form.patchValue(profile)
                  this.normalize()
                }
              })
            ).toPromise()
        })
      ).toPromise()

  }

  getGlobalAlgoDescription(algo: string): string {
    const index = this.osDataAlgos.findIndex((el) => el.algo === algo)
    if (index !== -1 && this.osDataAlgos[index].description) {
      return this.osDataAlgos[index].description
    }

    return algo
  }
}
