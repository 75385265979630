import { BrowserModule } from '@angular/platform-browser';
import {NgModule, Provider} from '@angular/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { MainLayoutComponent } from './shared/main-layout/main-layout.component';
import { MainPageComponent } from './main-page/main-page.component';
import { MonitorPageComponent } from './monitor-page/monitor-page.component';
import { GroupsPageComponent } from './groups-page/groups-page.component';
import { ProfilesPageComponent } from './profiles-page/profiles-page.component';
import {ThemeMenuComponent} from './shared/components/theme-menu/theme-menu.component';
import {MatMenuModule} from '@angular/material/menu';
import {LoginFormComponent} from './shared/components/login-form/login-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms'; // FormsModule
import { AccountPageComponent } from './account-page/account-page.component';
import {AuthInterceptor} from './shared/auth.interceptor';
import {LanguageSwitcherComponent} from './shared/components/language-switcher/language-switcher.component'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import {DisplayHrPipe} from './shared/pipes/display-hr';
import {DateAgoPipe} from './shared/pipes/date-ago.pipe';
import {SetTempColorClassPipe} from './shared/pipes/set-temp-color-class.pipe';
import {SetFanColorClassPipe} from './shared/pipes/set-fan-color-class.pipe';
import { RigComponent } from './rig-page/rig.component';
import { RigStatsComponent } from './rig-page/components/rig-stats/rig-stats.component';
import { RigOcComponent } from './rig-page/components/rig-oc/rig-oc.component';
import { RigSettingsComponent } from './rig-page/components/rig-settings/rig-settings.component';
import { RigTuningComponent } from './rig-page/components/rig-tuning/rig-tuning.component';
import { RigActionsComponent } from './rig-page/components/rig-actions/rig-actions.component';
import { RigConnectionsComponent } from './rig-page/components/rig-connections/rig-connections.component';
import {WalletsBlockComponent} from './shared/components/wallets-block/wallets-block.component';
import { CustomSelectComponent } from './shared/components/custom-select/custom-select.component';
import {CutTextPipe} from './shared/pipes/cut-text-pipe';
import {HideContentDirective} from './shared/directives/hide-content.directive';
import {ManifestRowComponent} from './shared/components/manifest-row/manifest-row.component';
import {ConjugateShares} from './shared/pipes/conjugate-shares';
import {CopyOnClickDirective} from './shared/directives/copy-on-click.directive';
import {LoadingComponent} from './shared/components/loading/loading.component';
import {EditProfileComponent} from './shared/components/edit-profile/edit-profile.component';
import { OcProfilesPageComponent } from './oc-profiles-page/oc-profiles-page.component';
import {OcRowComponent} from './shared/components/oc-row/oc-row.component';
import {TruncateTextPipe} from './shared/pipes/truncate-text.pipe';
import { ChangelogComponent } from './changelog/changelog.component';
import {EditOcAmdComponent} from './shared/components/edit-oc-amd/edit-oc-amd.component';
import {EditOcNvidiaComponent} from './shared/components/edit-oc-nvidia/edit-oc-nvidia.component';
import {EditOcAfComponent} from './shared/components/edit-oc-af/edit-oc-af.component';
import {OcSelectComponent} from './shared/components/oc-select/oc-select.component';
import {EditTuningComponent} from './shared/components/edit-tuning/edit-tuning.component';
import {EditWdTempComponent} from './shared/components/edit-wd-temp/edit-wd-temp.component';
import {EditWdLoadComponent} from './shared/components/edit-wd-load/edit-wd-load.component';
import {EditWdLaComponent} from './shared/components/edit-wd-la/edit-wd-la.component';
import {EditWdMbAfComponent} from './shared/components/edit-wd-mb-af/edit-wd-mb-af.component';
import {OnlyNumbersDirective} from './shared/directives/only-numbers.directive';
import {InputValidationComponent} from './shared/components/input-validation/input-validation.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import { RigGraphComponent } from './rig-page/components/rig-graph/rig-graph.component';
import {DatePipe} from '@angular/common';
import {AlertsComponent} from './shared/components/alerts/alerts.component';
import {EditRemotesshComponent} from './shared/components/edit-remotessh/edit-remotessh.component';
import {EditOpenvpnComponent} from './shared/components/edit-openvpn/edit-openvpn.component';
import { RigActionsCommandsComponent } from './shared/components/rig-actions-commands/rig-actions-commands.component';
import {BiosUploadComponent} from './shared/components/bios-upload/bios-upload.component';
import {FilterProfilesPipe} from './shared/pipes/filter-profiles';
import {FilterOverclocksPipe} from './shared/pipes/filter-oc';
import {EditWdAlgoComponent} from './shared/components/edit-wd-algo/edit-wd-algo-component';
import {CopyEntitiesComponent} from './shared/components/copy-entities/copy-entities.component';
import {GroupSettingsComponent} from './groups-page/components/group-settings/group-settings.component';
import {DeleteConfirmationComponent} from './shared/components/delete-confirmation/delete-confirmation.component';
import {FilterRigsPipe} from './shared/pipes/filter-rigs';
import {RigRowComponent} from './shared/components/rig-row/rig-row.component';
import {GroupComponent} from './groups-page/components/group/group.component';
import {GroupRigsComponent} from './groups-page/components/group-rigs/group-rigs.component';
import {EditRigComponent} from './shared/components/edit-rig/edit-rig.component';
import {CreateRigComponent} from './groups-page/components/create-rig/create-rig.component';
import {MoveEntitiesComponent} from './shared/components/move-entities/move-entities.component';
import {ShowUsingRigsComponent} from './shared/components/show-using-rigs/show-using-rigs.component';
import {ControlPanelComponent} from './shared/components/control-panel/control-panel.component';
import {DialogWrapperComponent} from './shared/components/dialog-wrapper/dialog-wrapper.component';
import {ConjugateRigs} from './shared/pipes/conjugate-rigs';
import {DisplayRigFullComponent} from './shared/components/display-rig-full/display-rig-full.component';
import {DisplayRigShortComponent} from './shared/components/display-rig-short/display-rig-short.component';
import {EditTempLimitComponent} from './shared/components/edit-temp-limit/edit-temp-limit.component';
import {ApplyMultiProfilesComponent} from './shared/components/apply-multi-profiles/apply-multi-profiles.component';
import {AuthService} from './shared/services/auth.service';
import {SignUpComponent} from './shared/components/sign-up/sign-up.component';
import { DownloadsPageComponent } from './downloads-page/downloads-page.component';
import {DisplayFilesizePipe} from './shared/pipes/display-filesize';
import {ConfirmMailComponent} from './shared/components/confirm-mail/confirm-mail.component';
import {ForgotPasswordComponent} from './shared/components/forgot-password/forgot-password.component';
import {RestorePasswordComponent} from './shared/components/restore-password/restore-password.component';
import {PasteProfileComponent} from './shared/components/paste-profile/paste-profile.component';
import {GroupFinancesComponent} from './groups-page/components/group-finances/group-finances.component';
import {FilterCurrenciesByMinAmountPipe} from './shared/pipes/filter-currencies';
import {ChangePasswordComponent} from './shared/components/change-password/change-password.component';
import {TwofaSetupComponent} from './shared/components/twofa-setup/twofa-setup.component';
import {TwofaConfirmDeleteComponent} from './shared/components/twofa-confirm-delete/twofa-confirm-delete.component';
import {DisplayFanPipe} from './shared/pipes/display-fan'
import {EditTuningAutofan8mkComponent} from './shared/components/edit-tuning-autofan8mk/edit-tuning-autofan8mk.component';
import { EditTuningAutofanCoolboxComponent } from './shared/components/edit-tuning-autofan-coolbox/edit-tuning-autofan-coolbox.component';
import { EditTuningAutofanNapalmComponent } from './shared/components/edit-tuning-autofan-napalm/edit-tuning-autofan-napalm.component'
import {AutofanHardwareComponent} from './shared/components/autofan-hardware/autofan-hardware.component';
import {AutofanNapalmComponent} from './shared/components/autofan-napalm/autofan-napalm.component';
import { EnForwarderComponent } from './en-forwarder/en-forwarder.component';

const INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  multi: true,
  useClass: AuthInterceptor
}

@NgModule({
  declarations: [
    AppComponent,
    AutofanHardwareComponent,
    AutofanNapalmComponent,
    ControlPanelComponent,
    DialogWrapperComponent,
    InputValidationComponent,
    MainLayoutComponent,
    MainPageComponent,
    MonitorPageComponent,
    GroupsPageComponent,
    ProfilesPageComponent,
    LoginFormComponent,
    ThemeMenuComponent,
    AccountPageComponent,
    LanguageSwitcherComponent,
    PageNotFoundComponent,
    DateAgoPipe,
    DisplayFanPipe,
    DisplayHrPipe,
    DisplayFilesizePipe,
    CutTextPipe,
    TruncateTextPipe,
    SetFanColorClassPipe,
    SetTempColorClassPipe,
    FilterCurrenciesByMinAmountPipe,
    FilterProfilesPipe,
    FilterOverclocksPipe,
    FilterRigsPipe,
    RigComponent,
    RigStatsComponent,
    RigOcComponent,
    RigSettingsComponent,
    RigTuningComponent,
    RigActionsComponent,
    RigConnectionsComponent,
    WalletsBlockComponent,
    CustomSelectComponent,
    HideContentDirective,
    CopyOnClickDirective,
    OnlyNumbersDirective,
    ManifestRowComponent,
    OcRowComponent,
    ConjugateRigs,
    ConjugateShares,
    LoadingComponent,
    EditProfileComponent,
    GroupSettingsComponent,
    OcProfilesPageComponent,
    ChangelogComponent,
    EditOcAmdComponent,
    EditOcNvidiaComponent,
    EditOcAfComponent,
    OcSelectComponent,
    EditTuningComponent,
    EditWdAlgoComponent,
    EditWdTempComponent,
    EditWdLoadComponent,
    EditWdLaComponent,
    EditWdMbAfComponent,
    EditTuningAutofan8mkComponent,
    EditTuningAutofanCoolboxComponent,
    EditTuningAutofanNapalmComponent,
    RigGraphComponent,
    AlertsComponent,
    EditRemotesshComponent,
    EditOpenvpnComponent,
    RigActionsCommandsComponent,
    BiosUploadComponent,
    ChangePasswordComponent,
    CopyEntitiesComponent,
    DeleteConfirmationComponent,
    RigRowComponent,
    GroupComponent,
    GroupRigsComponent,
    GroupFinancesComponent,
    EditRigComponent,
    CreateRigComponent,
    MoveEntitiesComponent,
    ShowUsingRigsComponent,
    DisplayRigFullComponent,
    DisplayRigShortComponent,
    EditTempLimitComponent,
    ApplyMultiProfilesComponent,
    SignUpComponent,
    TwofaConfirmDeleteComponent,
    TwofaSetupComponent,
    DownloadsPageComponent,
    ConfirmMailComponent,
    ForgotPasswordComponent,
    RestorePasswordComponent,
    PasteProfileComponent,
    EnForwarderComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      useDefaultLang: false,
    }),
    MatMenuModule,
    FormsModule,
    NgxChartsModule
  ],
  providers: [
    INTERCEPTOR_PROVIDER,
    DisplayHrPipe,
    DatePipe,
    FilterProfilesPipe,
    FilterOverclocksPipe,
    FilterRigsPipe,
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, './assets/locale/', '.json');
}
