import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {AuthService} from './services/auth.service';
import {AlertsService} from './services/alerts.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private auth: AuthService,
    private alertsService: AlertsService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('./assets/') === 0 || req.url.indexOf('assets/') === 0) {
      return next.handle(req)
    }

    if (this.auth.isAuthenticated()) {
      req = req.clone({
        headers: req.headers.set('authToken', this.auth.token)
      })
    }
    // console.log (req.url)

    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          // console.log ('[Interceptor Error]:', error)
          if (error.status === 401) {
            // console.log ('removeCredentials')
            this.auth.removeCredentials()
            this.router.navigate([''])
            // this.router.navigate([''], {
            //   queryParams: {
            //     authFailed: true
            //   }
            // })
            // this.alertsService.pushAlert({
            //   type: 'warning',
            //   text: 'Login error'}
            // )
          } else if (error.status === 406) {
            // console.log ('406')
            this.router.navigate(['/monitor'])
          }
          return throwError(error)
        })
      )
  }
}
