import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidateObject} from '../../validators/object-validator';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-paste-profile',
  templateUrl: './paste-profile.component.html',
  styleUrls: ['./paste-profile.component.scss']
})
export class PasteProfileComponent implements OnInit {
  form: FormGroup
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<PasteProfileComponent>
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      json: ['', [Validators.required, Validators.minLength(2), ValidateObject]]
    })
  }

  submit() {
    let data = JSON.parse(this.form.value.json)
    this.dialogRef.close(data)
  }
}
