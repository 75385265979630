import {Component, Input, OnInit} from '@angular/core';
import {SharedModule} from '../../shared.module';
import {OcAmd, OcNvidia} from '../../interfaces';

@Component({
  selector: 'app-oc-row',
  templateUrl: './oc-row.component.html',
  styleUrls: ['./oc-row.component.scss']
})
export class OcRowComponent implements OnInit {
  @Input() oc: OcNvidia | OcAmd | null
  @Input() brand: string
  url: string

  constructor(public sharedModule: SharedModule) { }

  ngOnInit(): void {
    // console.log ('this oc ', this.oc)
    this.url = `assets/img/${this.brand}-30.png`
    // console.log (this.oc)
  }
}
