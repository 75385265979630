import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SharedModule} from '../../../shared/shared.module';
import {Group, GroupUser} from '../../../shared/interfaces';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { environment } from 'src/environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {DeleteConfirmationComponent} from '../../../shared/components/delete-confirmation/delete-confirmation.component';
import {EMPTY, Observable, Subscription} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {GroupsService} from '../../../shared/services/groups.service';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../shared/services/local-storage.service';

@Component({
  selector: 'app-group-settings',
  templateUrl: './group-settings.component.html',
  styleUrls: ['./group-settings.component.scss']
})
export class GroupSettingsComponent implements OnInit, OnDestroy {
  @Input() group: Group
  @Input() newGroup = false
  environment = environment
  form: FormGroup
  accessLevels: number[]
  showHelp = false
  subs: Subscription[] = []
  accessLevel = 0
  login = this.localStorage.login
  // tempData = {
  //   t_not_hour: undefined,
  //   t_not_online: undefined,
  //   t_not_offline: undefined
  // }
  constructor(
    public sharedModule: SharedModule,
    private localStorage: LocalStorageService,
    private groupsService: GroupsService,
    private dialog: MatDialog,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.accessLevels = environment.farmAccessLevels
    this.fetchData()
  }

  fetchData() {
    this.form = this.fb.group({
      name: ['', [
        Validators.required,
        Validators.minLength(1)
      ]],
      comment: [''],
      allowAccess: [false],
      language: [environment.defaults.language],
      t_not_on: [false],
      t_not_hour: [false],
      t_not_online: [false],
      t_not_offline: [false]
    })
    this.form.addControl('users', new FormArray([]))

    if (this.group) {
      this.form.patchValue(this.group)
      // this.tempData = {
      //   t_not_hour: this.form.get('t_not_hour').value,
      //   t_not_online: this.form.get('t_not_online').value,
      //   t_not_offline: this.form.get('t_not_offline').value
      // }

      if (!this.group.t_not_on) {
        this.form.get('t_not_hour').disable()
        this.form.get('t_not_online').disable()
        this.form.get('t_not_offline').disable()
      }
    }

    const usersFormArray = this.form.get('users') as FormArray
    this.accessLevel = 0
    if (this.group.users) {
      this.group.users.forEach((groupUser) => {
        usersFormArray.push(new FormGroup({
          login: new FormControl(groupUser.login),
          id: new FormControl(groupUser.id),
          level: new FormControl(groupUser.level)
        }))
        if (groupUser.login.toString() === this.login.toString()) {
          this.accessLevel = groupUser.level
        }
      })
    }
  }

  toggleShowHelp() {
    this.showHelp = !this.showHelp
  }

  onToggle() {
    if (this.form.controls.t_not_on.value) {
      // this.tempData = {
      //   t_not_hour: this.form.get('t_not_hour').value,
      //   t_not_online: this.form.get('t_not_online').value,
      //   t_not_offline: this.form.get('t_not_offline').value
      // }
      this.form.controls.t_not_hour.disable()
      this.form.controls.t_not_online.disable()
      this.form.controls.t_not_offline.disable()
    } else {
      this.form.controls.t_not_hour.enable()
      this.form.controls.t_not_online.enable()
      this.form.controls.t_not_offline.enable()
    }
  }

  removeUser(id: number) {
    const userData = {
      id
    }
    this.groupsService.removeUser(this.group.id, userData)
      .pipe(
        map((result) => {
          if (!result) {
            this.sharedModule.pushAlert('User removed', 'success')
          } else { // don`t know what should be to trigger that
            this.sharedModule.pushAlert(result, 'warning')
          }
          this.router.navigate(['/groups'])
          // this.fetchData()
        })
    ).toPromise().catch((error) => {
      this.sharedModule.pushAlert(`Error: ${error.error.message}`, 'warning')
    })
  }

  addNewUserInputs() {
    this.form.addControl('newLogin', new FormControl('', [
      Validators.minLength(environment.patterns.login.minLength),
      Validators.maxLength(environment.patterns.login.maxLength),
      Validators.pattern(environment.patterns.login.pattern)
    ]))
    this.form.addControl('newLevel', new FormControl(0))
  }

  removeNewUserInputs() {
    this.form.removeControl('newLogin')
    this.form.removeControl('newLevel')
  }

  addUser() {
    const login = this.form.get('newLogin').value
    const level = this.form.get('newLevel').value

    if (! this.form.get('newLogin').valid) {
      this.sharedModule.pushAlert(`Incorrect login`, 'warning')
      return
    }

    if (!login || level < 0) {
      this.sharedModule.pushAlert(`Incorrect data`, 'warning')
      return
    }

    const groupUser: GroupUser = {login, level}
    this.groupsService.addInvitation(this.group.id, groupUser)
      .pipe(
        map((result) => {
          if (result === 'OK') {
            this.sharedModule.pushAlert('The invitation has been sent', 'success')
          } else {
            this.sharedModule.pushAlert(result.statusText, 'warning')
          }
        })
      ).toPromise().catch((error) => {
        this.sharedModule.pushAlert(`Error: ${error.error.message}`, 'warning')
      })
  }

  submit() {
    const allowedFarmFields = [
      't_not_on', 't_not_online', 't_not_offline', 't_not_hour', 'language',
      'name', 'comment', 'allowAccess'
    ]
    // const notificationsFields = [
    //   't_not_online', 't_not_offline', 't_not_hour'
    // ]
    let data = this.form.value
    if (data) {
      let diff: Group = {}
      allowedFarmFields.forEach(key => {
        if (data[key] !== this.group[key]) {
          // if (notificationsFields.indexOf(key) !== -1 && !data[key]) {
          //   if (this.tempData[key] !== this.group[key]) {
          //     diff[key] = this.tempData[key]
          //     this.group[key] = this.tempData[key]
          //   }
          // } else {
          //   diff[key] = data[key]
          //   this.group[key] = data[key]
          // }
          diff[key] = data[key]
          this.group[key] = data[key]
        }
      })
      if (Object.keys(diff).length !== 0) {
        if (this.newGroup === true) {
          this.groupsService.create(diff)
            .pipe(
              map((result) => {
                if (result && result.id && +result.id > 0) {
                  this.sharedModule.pushAlert('Group created', 'success')
                } else {
                  this.sharedModule.pushAlert(result.statusText, 'warning')
                }
                this.router.navigate(['/groups'])
              })
            ).toPromise().catch((error) => {
            this.sharedModule.pushAlert(`Error: ${error.error.message}`, 'warning')
          })
        } else { // This is EXISTING group
          this.groupsService.patch(this.group.id, diff)
            .pipe(
              map((result) => {
                if (result === 'OK') {
                  this.sharedModule.pushAlert('Farm settings changed', 'success')
                } else { // don`t know what should be to trigger that
                  this.sharedModule.pushAlert(result, 'warning')
                }
                // this.router.navigate(['/groups'])
              })
            ).toPromise().catch((error) => {
            this.sharedModule.pushAlert(`Error: ${error.error.message}`, 'warning')
          })
        }
      } else {
        this.sharedModule.pushAlert(`Nothing changed`)
        // this.router.navigate(['/groups'])
      }// nothing to do
    }

  }

  deleteGroup() {
    let dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      panelClass: 'custom-dialog-container'
    })

    this.subs.push(dialogRef.afterClosed()
      .pipe(
        switchMap((result) => {
          let returnVal: Observable<any>
          if (result === true) {
            returnVal = this.groupsService.delete(this.group.id)
          } else {
            returnVal = EMPTY
          }
          return returnVal
        })
      ).subscribe((result) => {
        if (!result) {
          this.sharedModule.pushAlert('Group deleted', 'success')
          this.router.navigate(['/groups'])
        } else { // don`t know what should be to trigger that
          this.sharedModule.pushAlert(result, 'warning')
        }
      }, (result) => {
        const err = result.error?.message ? result.error?.message : result.statusText
        this.sharedModule.pushAlert(`Error: ${err}`, 'warning')
      })

    )
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe()
    })
  }
}
