<div id="main">
  <form [formGroup]="form" (ngSubmit)="submit()" style="padding: 0.5rem">
    <div class="flex-container">
      <div class="form-control flex-container vertical">
        <mat-slide-toggle *ngFor="let controlName of boolControls" [formControlName]="controlName">
          {{ 'connections.remotessh.keys.' + controlName  | translate }}
        </mat-slide-toggle>
      </div>
    </div>

    <div class="flex-container w-50">
      <label for="login">{{ 'connections.remotessh.keys.login' | translate }}</label>
      <div class="form-control w-100">
        <input id="login" formControlName="login" type="text">
        <app-input-validation [component]="form.get('login')"></app-input-validation>
      </div>
    </div>

    <div class="flex-container w-50">
      <label for="server">{{ 'connections.remotessh.keys.server' | translate }}</label>
      <div class="form-control w-100">
        <input id="server" formControlName="server" type="text">
        <app-input-validation [component]="form.get('server')"></app-input-validation>
      </div>
    </div>

    <div class="flex-container w-25">
      <label for="port">{{ 'connections.remotessh.keys.port' | translate }}</label>
      <div class="form-control w-100">
        <input id="port" formControlName="port" type="text">
        <app-input-validation [component]="form.get('port')"></app-input-validation>
      </div>
    </div>

    <div class="flex-container w-25">
      <label for="port_vnc">{{ 'connections.remotessh.keys.port_vnc' | translate }}</label>
      <div class="form-control w-100">
        <input id="port_vnc" formControlName="port_vnc" type="text">
        <app-input-validation [component]="form.get('port_vnc')"></app-input-validation>
      </div>
    </div>

    <div class="form-control w-100">
      <label for="port">{{ 'connections.remotessh.keys.private_key' | translate }}</label>
      <textarea id="private_key" formControlName="private_key" type="text" rows="10"></textarea>
    </div>

    <div class="flex-container form-buttons">
      <button type="submit" class="btn btn-success pinned-right mr-0"
              [disabled]="!form.valid"
      >{{'save' | translate}}</button>
    </div>
  </form>
</div>
