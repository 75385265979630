import {Pipe, PipeTransform} from '@angular/core';
import {OcAmd, OcNvidia} from '../interfaces';

@Pipe({
  name: 'filterOc'
})
export class FilterOverclocksPipe implements PipeTransform {
  transform(overclocks: (OcAmd|OcNvidia)[], search = ''): OcAmd[] | OcNvidia[] {
    if (!search.trim()) {
      return overclocks
    }

    return overclocks.filter(oc => {
      // if (oc.name.toLowerCase().includes(search.toLowerCase())) {
      //   return true
      // }
      // return false
      return oc.name.toLowerCase().includes(search.toLowerCase())
    })
  }
}
