import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Account, TwoFaData} from '../interfaces';
import {SharedModule} from '../shared.module';

@Injectable({providedIn: 'root'})
export class AccountService {
  constructor(
    private http: HttpClient,
    private sharedModule: SharedModule
) {}

  get(): Observable<Account> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/account`
    return this.http.get<Account>(url)
  }

  sendMailConfirmation(): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/account/send-mail-confirm`
    return this.http.get<Account>(url)
  }

  sendPasswordReset(data: {mail: string}): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/account/send-password-reset`
    return this.http.post<Account>(url, data)
  }

  confirmMail(data: {token: string}): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/account/confirm-mail`
    return this.http.post(url, data)
  }

  resetPassword(data: {password: string, passwordConfirmation: string}): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/account/reset-password`
    return this.http.post(url, data)
  }

  changePassword(data: {
    password: string,
    newPassword: string,
    newPassword2: string,
    twofa?: string
  }): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/account/change-password`
    return this.http.post(url, data)
  }

  getTwoFA(): Observable<TwoFaData> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/account/twofa`
    return this.http.get<TwoFaData>(url)
  }

  setupTwoFa(data: {twofa: string}): Observable<any> {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/account/twofa`
    return this.http.put(url, data)
  }

  disableTwoFa(data: any) {
    const apiUrl = this.sharedModule.apiUrl
    const url = `${apiUrl}/account/twofa/delete`
    return this.http.post(url, data)
  }
}
