<div>
  <form [formGroup]="form" (ngSubmit)="submit()" style="padding: 0.5rem">

    <div style="max-width: 200px">
      <label for="tempLimit">{{ 'controlPanel.tempLimit' | translate }}</label>
      <input id="tempLimit" formControlName="tempLimit" type="number" min="30" max="100">
    </div>
<!--    <div *ngFor="let controlName of simpleControls" class="flex-container w-50">-->
<!--      <label [for]="controlName">{{ 'tuning.wd_load.keys.' + controlName | translate }}</label>-->
<!--      <div class="form-control w-100">-->
<!--        <input [id]="controlName" [formControlName]="controlName" type="number">-->
<!--      </div>-->
<!--    </div>-->

    <div class="flex-container w-100">
      <i>{{ 'controlPanel.tempLimitDescription' | translate }}</i>
    </div>
    <div class="flex-container form-buttons">
      <button type="submit" class="btn btn-success pinned-right mr-0">{{'save' | translate}}</button>
    </div>
  </form>
</div>
