<div class="flex-container">
  <div>
    <h4>
      {{ 'rig.devices.autofan_napalm.title1' | translate }}
      {{data.device_model}}v{{data.firmware_version}}
      {{ 'rig.devices.autofan_napalm.title2' | translate }}
    </h4>
<!--    {{data | json}}-->
  </div>
  <div class="pinned-right">
    <fa-icon *ngIf="!calibratePushed" [icon]="this.sharedModule.faSlidersH" (click)="Calibrate()" class="active-element mr-02"
             title="{{ 'rig.devices.autofan_napalm.calibrate' | translate }}"
    ></fa-icon>
    <fa-icon [icon]="this.sharedModule.faCog" [routerLink]="['tuning']" fragment="autofan_napalm" class="active-element"
             title="{{ 'rig.devices.routeToConfig' | translate }}"
    ></fa-icon>
  </div>
</div>

<div  class="flex-container w-100">
  <div *ngIf="data.calibration_in_progress">
    Calibration in progress
  </div>
  <div *ngIf="data.hasOwnProperty('temperature_in')|| data.hasOwnProperty('temperature_out'); else noThermoSensors" class="bordered mr-02 flex-container vertical">
    <div *ngIf="data.hasOwnProperty('temperature_in')" class="active-element" title="{{ 'rig.devices.tempIn' | translate }}">
      <fa-icon [icon]="this.sharedModule.faTemperatureHigh"></fa-icon>
      {{data.temperature_in}}°
    </div>
    <div *ngIf="data.hasOwnProperty('temperature_out')" class="active-element" title="{{ 'rig.devices.tempOut' | translate }}">
      <fa-icon [icon]="this.sharedModule.faTemperatureHigh"></fa-icon>
      {{data.temperature_out}}°
    </div>
  </div>
  <ng-template #noThermoSensors>
    {{ 'rig.devices.noConnectedThermoSensors' | translate }}
  </ng-template>
<!--  <div *ngIf="'power_consumtion' in data" class="bordered flex-container vertical mr-02">-->
  <div *ngIf="data.hasOwnProperty('power_consumption')" class="bordered flex-container vertical mr-02">
    <div *ngIf="data.hasOwnProperty('power_consumption')" class="active-element" title="{{ 'rig.devices.voltageData' | translate }}">
      <fa-icon [icon]="this.sharedModule.faPower"></fa-icon>
      {{data.power_consumption}} {{ 'watt' | translate }}
    </div>
    <div class="pinned-right" *ngIf="data.hasOwnProperty('current')">
      {{data.current | number : '1.1-2'}} {{ 'ampere' | translate }}
    </div>
  </div>

  <div *ngIf="data.fan_data && data.fan_data.length > 0; else noConnectedFans" class="flex-container">
    <ng-container *ngFor="let fan of data.fan_data">
      <div class="outer-fan bordered flex-container vertical center active-element"
           [ngClass]="{'offline': fan.status !== 'ok'}"
           title="{{ 'rig.devices.fan' | translate }} {{fan.connector}}"
      >
        <div class="flex-container center">
          <fa-icon [icon]="this.sharedModule.faFan"></fa-icon>&nbsp;{{fan.speed}}%
        </div>
        <div class="flex-container center">
          <fa-icon [icon]="this.sharedModule.faHashtag"></fa-icon>&nbsp;{{fan.connector}}
        </div>
      </div>
    </ng-container>
  </div>


  <ng-template #noConnectedFans>
    {{ 'rig.devices.noConnectedFans' | translate }}
  </ng-template>

</div>
