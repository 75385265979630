<div id="main">
  <form [formGroup]="form" (ngSubmit)="submit()" style="padding: 0.5rem">
    <div class="flex-container" style="align-items: end">
      <div class="form-control w-50" [ngClass]="{invalid: form.get('name').touched && form.get('name').invalid}">
        <label for="name">
          {{ 'oc.nvidiaKeys.name' | translate }}
        </label>
        <input id="name" formControlName="name" type="text">
      </div>

      <div class="pinned-right" style="align-self: start">
        <ng-container *ngIf="form.get('favorite').value === true; else noFavorite">
          <fa-icon [icon]="sharedModule.faStar" class="active-element gold" size="lg" (click)="toggleFavorite()"
             title="{{'oc.favoriteToggleOff' | translate}}"
          ></fa-icon>
        </ng-container>
        <ng-template #noFavorite>
          <fa-icon [icon]="sharedModule.farStar" class="active-element" size="lg" (click)="toggleFavorite()"
             title="{{'oc.favoriteToggleOn' | translate}}"
          ></fa-icon>
        </ng-template>
      </div>
    </div>

    <div *ngFor="let controlName of simpleControls" class="flex-container">
      <label [for]="controlName">{{ 'oc.nvidiaKeys.' + controlName | translate }}</label>
      <div class="form-control w-100">
        <input [id]="controlName" [formControlName]="controlName" type="text">
      </div>
    </div>

    <div class="flex-container">
      <div class="form-control flex-container vertical">
        <mat-slide-toggle *ngFor="let controlName of boolControls" [formControlName]="controlName">
          {{ 'oc.nvidiaKeys.' + controlName  | translate }}
        </mat-slide-toggle>
      </div>
    </div>

    <div class="flex-container vertical">
      <div class="form-control w-100">
        <label for="pill_args">{{ 'oc.nvidiaKeys.pill_args' | translate }}</label>
        <input id="pill_args" formControlName="pill_args" type="text">
      </div>
    </div>


    <div class="form-control w-25 flex-container vertical" *ngIf="form.touched && !sharedModule.compareObjects(prepareFormData(), oc)">
      <label>{{ 'manifests.saveAsNew' | translate }}</label>
      <div>
        <input formControlName="saveAsNew" type="radio" [value]="false">{{ 'manifests.saveAsNew_type_0' | translate }}
      </div>
      <div>
        <input formControlName="saveAsNew" type="radio" [value]="true">{{ 'manifests.saveAsNew_type_1' | translate }}
      </div>
    </div>
    <div class="flex-container form-buttons">
      <div class="pinned-right">
        <button type="button" (click)="submit('saveAndReboot')" class="btn btn-success pinned-right mr-02">{{ 'saveAndReboot' | translate }}</button>
        <button type="button" (click)="submit('save')" class="btn btn-success pinned-right mr-0">{{ 'save' | translate }}</button>
      </div>
    </div>
  </form>
</div>
