import {Component, Input, OnInit} from '@angular/core';
import {RemotesshSettings} from '../../interfaces';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-edit-remotessh',
  templateUrl: './edit-remotessh.component.html'
})
export class EditRemotesshComponent implements OnInit {
  @Input() data: RemotesshSettings
  form: FormGroup
  // simpleControls = [
  //   'wd_temp_crit'
  // ]
  boolControls = [
    'use',
    'use_vnc'
  ]
  // actions = [
  //   'pause',
  //   'reboot',
  //   'shutdown'
  // ]
  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    // console.log('this is remotessh', this.data)

    this.form = this.fb.group({
      private_key: [''],
      server: [''],
      port: [''],
      login: [''],
      port_vnc: [''],
      use: [''],
      use_vnc: ['']
    })

    this.form.patchValue(this.data)
  }

  submit() {
    console.log ('submit', this.form.value)
  }
}
