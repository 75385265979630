<form *ngIf="manifest" #editManifest [formGroup]="form" (ngSubmit)="submit()">
    <div id="container-name-coin" class="flex-container">
      <div id="profile-name" class="form-control" [ngClass]="{invalid: form.get('name').touched && form.get('name').invalid}">
        <div class="flex-container">
          <div>
            <label for="name">
              {{ 'manifests.name' | translate }}
              <small *ngIf="form.get('name').touched && form.get('name').invalid" class="red">
                {{ 'manifests.nameRequired' | translate }}
              </small>
            </label>
          </div>

          <div class="visible-xs pinned-right">
            <ng-template [ngIf]="form.get('favorite').value">
              <fa-icon [icon]="sharedModule.faStar" class="active-element gold" (click)="toggleFavorite()"
                       title="{{'manifests.favoriteToggleOff' | translate}}"
              ></fa-icon>
            </ng-template>
            <ng-template [ngIf]="!form.get('favorite').value">
              <fa-icon [icon]="sharedModule.farStar" class="active-element" (click)="toggleFavorite()"
                       title="{{'manifests.favoriteToggleOn' | translate}}"
              ></fa-icon>
            </ng-template>
          </div>
        </div>

        <input id="name" formControlName="name" type="text">
      </div>

      <div id="profile-coin" class="form-control" [ngClass]="{invalid: form.get('coin').touched && form.get('coin').invalid}">
        <label for="coin">{{ 'manifests.coin' | translate }}</label>
        <select formControlName="coin" id="coin">
          <option *ngFor="let coin of environment.defaultCoins" [ngValue]="coin">{{coin | uppercase}}</option>
          <option value="custom">{{ 'manifests.custom' | translate }}</option>
        </select>
      </div>

      <ng-container *ngIf="form.get('coin').value !== 'custom' && form.get('coin').value as coin; else customCoin">
        <ng-container *ngIf="coin && sharedModule.coinsLogo[coin]">
          <div id="coin-logo" [title]="coin" style="flex-grow: 1; height: 2.5rem">
            <img [src]="'assets/img/coins/' + sharedModule.coinsLogo[coin]" [alt]="coin" class="coin-logo">
          </div>
        </ng-container>
      </ng-container>
      <ng-template #customCoin>
        <div id="profile-custom-coin" class="form-control" >
          <input id="customCoin" formControlName="customCoin" type="text">
        </div>
      </ng-template>

      <div class="form-control">
        <input type="hidden" id="favorite" formControlName="favorite">
      </div>

      <div class="visible-xl pinned-right" style="align-self: start">
        <ng-template [ngIf]="form.get('favorite').value">
          <fa-icon [icon]="sharedModule.faStar" class="active-element gold" size="lg" (click)="toggleFavorite()"
            title="{{'manifests.favoriteToggleOff' | translate}}"
          ></fa-icon>
        </ng-template>
        <ng-template [ngIf]="!form.get('favorite').value">
          <fa-icon [icon]="sharedModule.farStar" class="active-element" size="lg" (click)="toggleFavorite()"
            title="{{'manifests.favoriteToggleOn' | translate}}"
          ></fa-icon>
        </ng-template>
      </div>
    </div>

    <div id="container-wallet-template" class="flex-container">
      <div id="profile-wallet" class="form-control">
        <label for="wallet">{{ 'manifests.wallet' | translate }}</label>
        <input id="wallet" formControlName="wallet" type="text">
      </div>
      <div id="profile-template" class="form-control">
        <label for="template">{{ 'manifests.template' | translate }}</label>
        <input id="template" formControlName="template" type="text">
      </div>
    </div>

    <div id="container-pool-pass" class="flex-container">
      <div id="profile-pool" class="form-control">
        <label for="pool">{{ 'manifests.pool' | translate }}</label>
        <input id="pool" formControlName="pool" type="text">
      </div>
      <div id="profile-pass" class="form-control">
        <label for="pass">{{ 'manifests.pass' | translate }}</label>
        <input id="pass" formControlName="pass" type="text">
      </div>
    </div>
    <ng-container *ngIf="['phoenix', 'claymore'].includes(miner)">
      <div id="container-epools" class="flex-container">
        <div id="profile-epools" class="form-control">
          <label for="epools">{{ 'manifests.epools' | translate }}</label>
          <textarea id="epools" formControlName="epools" type="text"></textarea>
        </div>
        <div id="profile-epools-type" class="form-control w-25 flex-container vertical">
          <div><label>{{ 'manifests.epools_type' | translate }}</label></div>
          <div title="{{'manifests.epools_type_1_descr' | translate}}">
            <input formControlName="epools_type" type="radio" [value]="1">{{ 'manifests.epools_type_1' | translate }}
          </div>
          <div title="{{'manifests.epools_type_2_descr' | translate}}">
            <input formControlName="epools_type" type="radio" [value]="2">{{ 'manifests.epools_type_2' | translate }}
          </div>
        </div>
      </div>
    </ng-container>

    <div class="flex-container">
      <div class="form-control w-100">
        <mat-slide-toggle formControlName="no_stats">{{ 'manifests.no_stats' | translate }}</mat-slide-toggle>
      </div>
    </div>
    <div class="flex-container">
      <div class="form-control w-100">
        <label for="addition">{{ 'manifests.addition' | translate }}</label>
        <textarea id="addition" formControlName="addition" type="text"></textarea>
      </div>
    </div>
    <ng-container
      *ngIf="[
      'ethash_eaglesong', 'ethash_handshake',
      'ethash_blake2s', 'ethash_tensority',
      'ethash_trb', 'ethash_zil',
      'autolykos_zil', 'ethash_autolykos2'].includes(form.get('algo').value)">
      <div class="flex-container">
        <div class="form-control w-75">
          <label for="dual_wallet">{{ 'manifests.wallet' | translate }} <i>{{ 'manifests.dual' | translate}}</i></label>
          <input id="dual_wallet" formControlName="dual_wallet" type="text">
        </div>
        <div class="form-control w-25">
          <label for="dual_template">{{ 'manifests.template' | translate }} <i>{{ 'manifests.dual' | translate}}</i></label>
          <input id="dual_template" formControlName="dual_template" type="text">
        </div>
      </div>
      <div class="flex-container">
        <div class="form-control w-75">
          <label for="dual_pool">{{ 'manifests.pool' | translate }} <i>{{ 'manifests.dual' | translate}}</i></label>
          <input id="dual_pool" formControlName="dual_pool" type="text">
        </div>
        <div class="form-control w-25">
          <label for="dual_pass">{{ 'manifests.pass' | translate }} <i>{{ 'manifests.dual' | translate}}</i></label>
          <input id="dual_pass" formControlName="dual_pass" type="text">
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="['xmr_stak'].includes(miner)">
      <div class="flex-container">

        <div class="form-control w-100">
          <label for="config">{{ 'manifests.config' | translate }}</label>
            <textarea id="config" formControlName="config" type="text"></textarea>
          </div>
        </div>

        <div class="flex-container">
          <div class="form-control w-50">
            <label for="config_pools">{{ 'manifests.config_pools' | translate }}</label>
            <textarea id="config_pools" formControlName="config_pools" type="text"></textarea>
          </div>
          <div class="form-control w-50">
            <label for="config_cpu">{{ 'manifests.config_cpu' | translate }}</label>
            <textarea id="config_cpu" formControlName="config_cpu" type="text"></textarea>
          </div>
        </div>

        <div class="flex-container">
          <div class="form-control w-50">
            <label for="config_nvidia">{{ 'manifests.config_nvidia' | translate }}</label>
            <textarea id="config_nvidia" formControlName="config_nvidia" type="text"></textarea>
          </div>

          <div class="form-control w-50">
            <label for="config_amd">{{ 'manifests.config_amd' | translate }}</label>
            <textarea id="config_amd" formControlName="config_amd" type="text"></textarea>
          </div>
        </div>

    </ng-container>
  <div id="container-miner-algo-fork-customUrl" class="flex-container" style="flex-direction: row">
    <div id="profile-miner" class="form-control">
      <label for="miner">{{ 'manifests.miner' | translate }}</label>
      <select formControlName="miner" id="miner" (change)="changeMiner()">
        <option *ngFor="let miner of osDataMiners" [ngValue]="miner.name">{{miner.description}}</option>
      </select>
    </div>

    <div *ngIf="osDataMiner && miner !== 'custom'" id="container-algo-fork" class="flex-container">
      <div id="profile-algo" class="form-control">
        <label for="algo">{{ 'manifests.algo' | translate }}</label>
        <select formControlName="algo" id="algo">
          <option value="">{{ 'notSelected' | translate }}</option>
          <option *ngFor="let algo of osDataMiner.algos" [ngValue]="algo.g">{{getGlobalAlgoDescription(algo.g)}}</option>
        </select>
      </div>
      <div id="profile-fork" class="form-control">
        <label for="fork">{{ 'manifests.fork' | translate }}</label>
        <select formControlName="fork" id="fork">
          <option *ngFor="let fork of osDataMiner.forks" [ngValue]="fork">{{fork}}</option>
          <option value="latest">{{ 'manifests.lastFork' | translate }} ({{osDataMiner.forks[osDataMiner.forks.length - 1]}})</option>
        </select>
      </div>
    </div>

    <div *ngIf="miner === 'custom'" id="container-customUrl" class="flex-container">
      <div id="profile-customUrl" class="form-control">
        <label for="custom_url">{{ 'manifests.custom_url' | translate }}</label>
        <input id="custom_url" formControlName="custom_url" type="text">
      </div>
    </div>
  </div>

  <div class="flex-container pl pr">
    <button class="btn btn-default" [mat-dialog-close]="true">{{ 'cancel' | translate }}</button>
    <button class="btn btn-default" type="button" (click)="copyProfile()" title="{{'manifests.copyJson' | translate}}">
      <fa-icon [icon]="sharedModule.faCode"></fa-icon>
      <fa-icon [icon]="sharedModule.faCopy" class="ml-02"></fa-icon>
    </button>
    <button class="btn btn-default" type="button" (click)="pasteProfile()" title="{{'manifests.pasteJson' | translate}}">
      <fa-icon [icon]="sharedModule.faCode"></fa-icon>
      <fa-icon [icon]="sharedModule.faPaste" class="ml-02"></fa-icon>
    </button>
    <button class="btn btn-default" type="button" (click)="openTemplates()" title="{{'manifests.getFromTemplates' | translate}}">
      <fa-icon [icon]="sharedModule.faArchive"></fa-icon>
<!--      <fa-icon [icon]="sharedModule.faPaste" class="ml-02"></fa-icon>-->
    </button>

    <button [disabled]="!form.valid" type="submit" class="btn btn-success pinned-right mr-0">{{'save' | translate}}</button>
  </div>
</form>
