import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Rig} from '../../interfaces';
import {SharedModule} from '../../shared.module';

@Component({
  selector: 'app-rig-row',
  templateUrl: './rig-row.component.html',
  styles: ['']
})
export class RigRowComponent {
  @Input() rig: Rig
  constructor(public sharedModule: SharedModule) { }
}
