import {Component, Inject, Input} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {OsDataMiner, Profile} from '../../interfaces';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent {
  searchStr = ''
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      manifests: Profile[],
      osDataMiners: OsDataMiner[]
    },
  ) { }
}
