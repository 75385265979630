import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Autofan} from '../../interfaces';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SharedModule} from '../../shared.module';

@Component({
  selector: 'app-edit-oc-af',
  templateUrl: './edit-oc-af.component.html'
})
export class EditOcAfComponent implements OnInit {
  @Input() af: Autofan
  @Output() formSubmit = new EventEmitter()
  form: FormGroup
  simpleControls = [
    'delay',
    'tt',
    'ttmem',
    'fanmin',
    'fanmax',
    'shift',
    'hyst'
  ]
  constructor(
    private sharedModule: SharedModule,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      enabled: [''],
      delay: [''],
      tt: [''],
      ttmem: [''],
      fanmin: [''],
      fanmax: [''],
      shift: [''],
      hyst: ['']
    })
    if (this.af) {
      this.form.patchValue(this.af)
    }
  }
}
