import {Component, Input} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';

@Component({
  selector: 'app-input-validation',
  templateUrl: './input-validation.component.html'
})
export class InputValidationComponent {
  @Input() component: FormControl|AbstractControl
  constructor() { }
}
