import { HttpClient } from '@angular/common/http'
import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'

import {ThemeOption} from '../interfaces'
import {StyleManagerService} from './style-manager.service';
import {LocalStorageService} from './local-storage.service';

@Injectable()
export class ThemeService {
  constructor(
    private http: HttpClient,
    private styleManager: StyleManagerService,
    private localStorageService: LocalStorageService
  ) {}

  getThemeOptions(): Observable<Array<ThemeOption>> {
    return this.http.get<Array<ThemeOption>>('assets/theme-options.json');
  }

  setTheme(themeToSet): void {
    this.styleManager.setStyle(
      'theme',
      `assets/themes/${themeToSet}.css`
    )
    this.localStorageService.setTheme(themeToSet)
  }
}
