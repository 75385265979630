import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {AutofanCoolboxCfg} from '../../interfaces';
import {FormBuilder, FormGroup, Validators} from '@angular/forms'

@Component({
  selector: 'app-edit-tuning-autofan-coolbox',
  templateUrl: './edit-tuning-autofan-coolbox.component.html'
})
export class EditTuningAutofanCoolboxComponent implements OnInit {
  @Input() data: AutofanCoolboxCfg
  @Output() formSubmit = new EventEmitter()
  section = 'autofan_coolbox'
  form: FormGroup

  simpleControls = [
    'target_temp',
    'target_mtemp',
    'manual_fan_speed',
  ]
  // 'wd_reset_interval'  simple control, but need to be displayed only if watchdog is enabled

  // 'fan_mode'

  boolControls = [
    'watchdog'
  ]
  // radioControls = [ 'operating_mode' ]

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      target_temp: [50, [
          Validators.required,
          Validators.min(0),
          Validators.max(255)
      ]],
      target_mtemp: [90, [
          Validators.min(0),
          Validators.max(255)
      ]],
      manual_fan_speed: [50, [
        Validators.min(0),
        Validators.max(100)
      ]],
      fan_mode: [1, [
        Validators.min(1),
        Validators.max(2)
      ]],
      watchdog: [0, [
        Validators.min(0),
        Validators.max(1)
      ]],
      wd_reset_interval: [6, [
        Validators.min(1),
        Validators.max(100)
      ]],
    })

    if (this.data) {
      this.form.patchValue(this.data)
    }

    this.form.get('fan_mode').setValue(this.data.fan_mode === 2)
    this.form.get('watchdog').setValue(this.data.watchdog === 1)
  }

  submit() {
    let data = {
      autofan_coolbox: this.form.value
    }

    for (const key of this.boolControls) {
      if (data[this.section][key] === true) {
        data[this.section][key] = 1;
      } else if (data[this.section][key] === false) {
        data[this.section][key] = 0;
      }
    }

    if (data[this.section].fan_mode === true) {
      data[this.section].fan_mode = 2;
    } else {
      data[this.section].fan_mode = 1;
    }

    this.formSubmit.emit(data)
  }
}
