import { Component, OnInit } from '@angular/core';
import {Changelog} from '../shared/interfaces';
import {OsService} from '../shared/services/os.service';
import {LocalStorageService} from '../shared/services/local-storage.service';
import {SharedModule} from '../shared/shared.module';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html'
})
export class ChangelogComponent implements OnInit {
  changelog: Changelog[] = []
  id: number
  limit = 5
  locale: string
  method = 'changelog' // default
  count = 0

  constructor(
    private osService: OsService,
    private localStorageService: LocalStorageService,
    public sharedModule: SharedModule
  ) { }

  async fetchData(offset?: number) {
    let newData: Changelog[]
    if (this.method === 'changelog') {
      newData = await this.osService.getChangelog(this.locale, this.limit, offset).toPromise()
    } else if (this.method === 'os_changelog') {
      newData = await this.osService.getOsChangelog(this.locale, this.limit, offset).toPromise()
    }
    this.changelog.push(...newData)
  }

  onClick(method: string) {
    if (method !== this.method) {
      this.changelog = []
      this.method = method
      this.fetchData()
    }
  }

  ngOnInit(): void {
    this.sharedModule.setTitle('links.changelog', true)

    this.locale = this.localStorageService.language
    this.fetchData()
  }
}
