import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fanColorClass'
})
export class SetFanColorClassPipe implements PipeTransform {
  constructor() {}

  transform(fan: number, fontColor = false): string {
    let result: string
    let pattern = 'fan'
    if (fontColor === true) {
      pattern = 'fan-font'
    }
    if (! fan || fan > 150) {
      result = `${pattern}-0`
    } else if (fan < 50) {
      result = `${pattern}-1`
    } else if (fan < 60 + 10) {
      result = `${pattern}-2`
    } else if (fan < 70 + 15) {
      result = `${pattern}-3`
    } else if (fan < 80 + 20) {
      result = `${pattern}-4`
    } else {
      result = `${pattern}-5`
    }

    return result
  }
}
