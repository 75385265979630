import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WdLa} from '../../interfaces';

@Component({
  selector: 'app-edit-wd-la',
  templateUrl: './edit-wd-la.component.html'
})
export class EditWdLaComponent implements OnInit {
  @Input() data: WdLa
  @Output() formSubmit = new EventEmitter()
  section = 'wd_la'

  form: FormGroup
  simpleControls = [
    'max',
    'reboot'
  ]
  boolControls = [
    'enabled'
  ]
  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      enabled: [''],
      max: ['',
        [
          // Validators.required,
          Validators.min(1)
        ]
      ],
      reboot: ['',
        [
          Validators.min(60)
        ]
      ]
    })

    this.form.patchValue(this.data)
  }

  submit() {
    let data = {
      wd_la: this.form.value
    }
    this.formSubmit.emit(data)
  }
}
