<form [formGroup]="form" (ngSubmit)="submit()" autocomplete="off">
  <h2 mat-dialog-title>{{ 'login.restorePassword' | translate }}</h2>
  <mat-dialog-content>
    <div class="form-control">
      <label for="mail">{{ 'signUp.mail' | translate }}</label>
      <app-input-validation [component]="form.get('mail')"></app-input-validation>
      <input id="mail" formControlName="mail" type="email">
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="btn btn-default" mat-dialog-close>{{ 'cancel' | translate }}</button>
    <button [disabled]="!form.valid" type="submit" class="btn btn-success pinned-right mr-0">{{'login.resetPassword' | translate}}</button>
  </mat-dialog-actions>
</form>
