import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'cutText'
})
export class CutTextPipe implements PipeTransform {
  transform(rawWallet: string, length = 17): string {
    if (rawWallet) {
      let smallLength = (length - 3) / 2
      if (rawWallet && rawWallet.length >= length) { // cut https:// or stratum+...://
        rawWallet = rawWallet.replace(/[^.]+\/\//, '')
      }
      if (rawWallet && rawWallet.length >= length) { // cut port
        rawWallet = rawWallet.replace(/:+[0-9]+$/, '')
      }
      if (rawWallet && rawWallet.length >= length) {
        rawWallet = rawWallet.substr(0, smallLength) + '...' + rawWallet.substr(-smallLength)
      }
    }
    return rawWallet
  }
}
