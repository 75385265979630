import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TwoFaData} from '../../interfaces';
import {SharedModule} from '../../shared.module';
import {FormBuilder,  FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-twofa-setup',
  templateUrl: './twofa-setup.component.html',
  styleUrls: ['./twofa-setup.component.scss']
})
export class TwofaSetupComponent implements OnInit {
  form: FormGroup
  @Output() formSubmit = new EventEmitter()
  constructor(
    public sharedModule: SharedModule,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<TwofaSetupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Observable<TwoFaData>
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      twofa: ['', [
        Validators.required,
        Validators.pattern(environment.patterns.twofa.pattern),
        Validators.minLength(environment.patterns.twofa.minLength),
        Validators.maxLength(environment.patterns.twofa.maxLength)
      ]],
    })
  }

  submit() {
    this.dialogRef.close(this.form.value);
  }

  // showForm() {
  //   console.log(this.form, this.form.value)
  // }
}
