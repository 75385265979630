import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'displayHr'
})
export class DisplayHrPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService
  ) {}

  private order = [
    'hs',
    'khs',
    'mhs',
    'ghs'
  ]

  transform(rawHr: number, showMh = false, exact =  false): string {
    let hrString: string
    let i = 0
    let hr = 0
    let unit: string

    this.order.forEach(elem => {
      if (rawHr / 1000 ** i >= 1) {
        // hr = Math.round(rawHr) / 1000 ** i
        hr = rawHr / 1000 ** i
        unit = elem
      }
      i++
    })
    hr = Math.round(hr * 1000) / 1000

    let signsAfterDot
    const remainder = hr % 100
    if (exact) {
      signsAfterDot = 2
    } else if (hr >= 100) { // || remainder >= 100
      signsAfterDot = 0
    } else if (remainder >= 10) { // 100 > remainder >= 10
      signsAfterDot = 1
    } else { // remainder < 10
      signsAfterDot = 2
    }

    hrString = hr.toFixed(signsAfterDot)

    if (hr > 0 && showMh) {
      // console.log (unit)
      this.translateService.get([`units.${unit}`]).subscribe(translation => {
        hrString += ' ' + translation[`units.${unit}`]
        return hrString
        }
      )
    }

    return hrString
  }
}
