<div *ngIf="!noGraphData; else noGraphDataDiv">
  <div *ngIf="data$ | async as data; else appLoading" class="flex-container" style="min-height: 600px; max-height: 50%">
    <ngx-charts-area-chart-stacked
      [scheme]="colorScheme"
      [showGridLines]="true"
      [xAxis]="true"
      [xAxisTickFormatting]="transformTime"
      [xScaleMin]="xScaleMin"
      [xScaleMax]="xScaleMax"

      [yAxis]="true"
      [yScaleMax]="yScaleMax"

      [results]="data"
    >
      <!--      [colors]="localChartColours"-->

      <ng-template #tooltipTemplate let-model="model">
<!--        This is the single point tooltip template-->
        <div class="container">
          <div>{{model.name * 1000 | date:'dd MMMM HH:mm':translateService.currentLang}}</div>
          <div><b>{{model.series}}</b> {{model.value}}</div>
          <div></div>
        </div>
      </ng-template>

      <ng-template #seriesTooltipTemplate let-model="model">
<!--        This is vertical line tooltip template-->
        <div *ngFor="let el of model" class="container">
          <div>{{el.name * 1000 | date:'dd MMMM HH:mm':translateService.currentLang}}</div>
          <div><b>{{el.series}}</b> {{el.value}}</div>
          <div></div>
        </div>
      </ng-template>
    </ngx-charts-area-chart-stacked>
  </div>

  <!--  xAxisTickFormatting="calimbaDeLuna"-->
  <!--  <div echarts [options]="options" class="demo-chart"></div>-->


  <ng-template #appLoading>
    <app-loading></app-loading>
  </ng-template>
</div>

<ng-template #noGraphDataDiv>
  <div class="container center">
    <h4>{{'rig.noGraphData' | translate}}</h4>
    {{'rig.noGraphDataHelp' | translate}}
    <span class="note note-default">
      <b>newdog --host https://os.dog/api/v2/rigStats</b>
    </span>
    <fa-icon [icon]="this.sharedModule.faCopy" class="active-element ml-02"
      appCopyOnClick="newdog --host https://os.dog/api/v2/rigStats" title="{{ 'copyOnClick' | translate }}">
    </fa-icon>
  </div>
</ng-template>
