<div class="select-form">
  <ng-container *ngIf="data.ocArr | async as ocData; else appLoading">
    <ng-containter *ngIf="ocData.length > 0; else noProfiles">
      <app-oc-row *ngFor="let oc of ocData" [oc]="oc" [mat-dialog-close]="oc.id" [brand]="data.brand"></app-oc-row>
    </ng-containter>
    <ng-template #noProfiles>{{ 'oc.noProfiles' | translate }}</ng-template>
  </ng-container>
  <ng-template #appLoading>
    <app-loading></app-loading>
  </ng-template>
</div>
