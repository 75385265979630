<div class="container-wide flex-container" style="margin-top: 65px">
  <div class="   mt-0" >
    <div class="nav-panel flex-container vertical" style="justify-content: left; align-items: start">
      <span class="menu-link tab" [ngClass]="{active: method === 'changelog'}" (click)="onClick('changelog')">{{'changelog.webChangelog' | translate}}</span>
      <span class="menu-link tab" [ngClass]="{active: method === 'os_changelog'}" (click)="onClick('os_changelog')">{{'changelog.osChangelog' | translate}}</span>
    </div>
  </div>
  <div class="container mt-0">
    <ng-container *ngIf="changelog; else appLoading">
      <div *ngFor="let data of changelog" class="card mt-0" style="min-width: 1100px">
        <div class="flex-container w-100 card-label">
          <div><b>{{data.name}}</b></div>
          <div class="pinned-right">{{data.date}}</div>
        </div>
        <div class="card-content" [innerHTML]="data[locale]"></div>
      </div>
      <div class="flex-container">
        <btn class="btn btn-default pinned-right mr-0" (click)="fetchData(changelog.length)">{{'changelog.loadMore' | translate}}</btn>
      </div>
    </ng-container>
    <ng-template #appLoading>
      <app-loading></app-loading>
    </ng-template>
  </div>

</div>
