<div class="rig-row" [ngClass]="{offline: rig.nonActive}">
  <div class="rig-name-online flex-container no-wrap">
    <div class="rig-name">
      <a [routerLink]="['/rig', rig.id]">
        <strong>{{rig.name}}</strong>
      </a>
      <br>
      <span *ngFor="let msgCont of rig.msg" class="note ml-0 mr" [ngClass]="msgCont.type">
        {{msgCont.count}}
      </span>
    </div>

    <div class="online" *ngIf="!rig.nonActive">
                    <span class="note note-success" *ngIf="!rig.offline">
                      {{ rig.online | dateAgo:currentTime }}
                    </span>
      <span *ngIf="rig.offline" class="note note-danger">
                      {{ rig.refresh | dateAgo:currentTime }}
                    </span>
    </div>
  </div>
  <div class="miners" *ngIf="!rig.offline">
    <ng-container *ngFor="let miner of rig.minerStats">
      <div *ngIf="miner" class="miner-row">
        <div class="miner-total-hr">
          <div class="flex-container">
            <div *ngIf="miner.miner" class="note note-default">{{getMinerDescription(miner.miner)}}</div>
            <div *ngIf="miner.total_hr" class="miner-hr note note-default">{{miner.total_hr | displayHr:true}}</div>
            <ng-container *ngIf="miner.dual && sharedModule.isArray(miner.dual)">
              <ng-container *ngFor="let dualStats of miner.dual">
                <div *ngIf="dualStats.total_hr" class="miner-hr note note-default">{{dualStats.total_hr | displayHr:true}}</div>
              </ng-container>
            </ng-container>
          </div>
          <span *ngIf="miner.allBadShares" class="pinned-right" title="{{miner.allBadShares}} {{miner.allBadShares | conjugateShares: 'bad'}}">
            {{miner.allBadShares}}
            <fa-icon [icon]="this.sharedModule.faTimesCircle" class="ml-02 color-danger"></fa-icon>
          </span>
        </div>
        <div class="miner-cells">
          <ng-template [ngIf]="miner.hr">
            <div *ngFor="let hr of miner.hr; let i = index" class="col-hr
              {{ (miner.temp && miner.temp[i]) && +miner.temp[i] | tempColorClass: tempLimit }}"
            >
              <div *ngIf="miner.temp && miner.temp[i]">
                {{miner.temp[i]}}°
              </div>
              <div *ngIf="miner.fan && miner.fan[i] !== undefined" class="note {{ (miner.fan && miner.fan[i]) && miner.fan[i] | fanColorClass }}">
                {{ (miner.fan && miner.fan[i]) && miner.fan[i] | displayFan : false}}
              </div>
              <div>
                {{hr | displayHr:false:true}}
              </div>
            </div>
          </ng-template>

          <ng-template [ngIf]="miner.threads_hr">
            <div *ngFor="let hr of miner.threads_hr; let i = index" class="col-hr thread">
              {{hr | displayHr:false:true}}
            </div>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </div>
</div>
