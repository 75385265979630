import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './twofa-confirm-delete.component.html',
  styleUrls: ['twofa-confirm-delete.component.scss']
})
export class TwofaConfirmDeleteComponent implements OnInit {
  form: FormGroup
  @Output() formSubmit = new EventEmitter()
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<TwofaConfirmDeleteComponent>,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      twofa: ['', [
        Validators.required,
        Validators.pattern(environment.patterns.twofa.pattern),
        Validators.minLength(environment.patterns.twofa.minLength),
        Validators.maxLength(environment.patterns.twofa.maxLength)
      ]],
    })
  }

  submit() {
    this.dialogRef.close(this.form.value);
  }

}
