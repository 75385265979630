<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="flex-container" *ngIf="!newRig">
    <div>
      <mat-slide-toggle formControlName="active">
        <ng-container *ngIf="form.get('active').value; else disabled">
          {{ 'rig.settingsObj.active' | translate }}
        </ng-container>
        <ng-template #disabled>
          {{ 'rig.settingsObj.disabled'  | translate }}
        </ng-template>
      </mat-slide-toggle>
    </div>
  </div>

  <div class="flex-container">
    <div class="form-control w-50" [ngClass]="{invalid: form.get('name').touched && form.get('name').invalid}">
      <label for="name">
        {{ 'rig.settingsObj.name' | translate }}
        <app-input-validation [component]="form.get('name')"></app-input-validation>
      </label>
      <input id="name" formControlName="name" type="text">
    </div>
    <div *ngIf="rig.id" class="form-control w-50 pinned-right">
      <label for="id">
        {{ 'rig.settingsObj.id' | translate }}
      </label>
      <input id="id" formControlName="id" type="text">
    </div>
  </div>
  <div class="flex-container">
    <div class="form-control w-50 flex-container">
      <div>
        <label for="password">
          {{ 'rig.settingsObj.password' | translate }}
          <fa-icon [icon]="this.sharedModule.faCopy" class="active-element ml-02"
            [appCopyOnClick]="this.form.get('password').value" title="{{ 'copyOnClick' | translate }}">
          </fa-icon>

          <app-input-validation [component]="form.get('password')"></app-input-validation>
        </label>
      </div>
      <div class="pinned-right">
        <fa-icon [icon]="this.sharedModule.faRandom" class="active-element pinned-right"
                 (click)="generateRandomPassword()" title="{{ 'rig.settingsObj.generateRandomPassword' | translate }}">
        </fa-icon>
      </div>
      <input id="password" formControlName="password" type="text">
    </div>
  </div>

  <div class="flex-container">
    <label for="comment">{{ 'rig.settingsObj.comment' | translate }}</label>
    <div class="form-control w-100">
      <textarea id="comment" formControlName="comment" type="text"></textarea>
    </div>
  </div>
  <div class="flex-container">
    <div class="form-control w-100">
      <label for="power">{{ 'rig.settingsObj.power' | translate }}</label>
      <input id="power" formControlName="power" type="number" min=0 max=1200 style="max-width: 5rem">
    </div>
  </div>

  <div class="flex-container form-control w-100 vertical">
    <mat-slide-toggle formControlName="notify_on_boot">{{ 'rig.settingsObj.notify_on_boot' | translate }}</mat-slide-toggle>
    <mat-slide-toggle formControlName="wd_kern">{{ 'rig.settingsObj.wd_kern' | translate }}</mat-slide-toggle>
    <mat-slide-toggle formControlName="use_graphic">{{ 'rig.settingsObj.use_graphic' | translate }}</mat-slide-toggle>
    <div>
      <i>{{ 'rig.settingsObj.use_graphicDescr' | translate }}</i>
    </div>
    <mat-slide-toggle formControlName="force_p0">{{ 'rig.settingsObj.force_p0' | translate }}</mat-slide-toggle>
    <div>
      <i>{{ 'rig.settingsObj.force_p0Descr' | translate }}</i>
    </div>
    <div class="bordered">
      <input type="hidden" formControlName="service_mode">
      <div>
        <mat-slide-toggle [checked]="serviceMode" (change)="onToggleServiceMode()">{{ 'rig.settingsObj.service_mode.service_mode' | translate }}</mat-slide-toggle>
      </div>
      <ng-container *ngIf="serviceMode">
        <mat-slide-toggle [checked]="drivers" (change)="onToggleDrivers()">{{ 'rig.settingsObj.service_mode.useDrivers' | translate }}</mat-slide-toggle>
        <div>
          <i>{{ 'rig.settingsObj.service_mode.descr' | translate }}</i>
        </div>
      </ng-container>
    </div>

    <div class="bordered" formGroupName="rigonlineru">
      <div>
        <mat-slide-toggle formControlName="enabled">{{ 'rig.settingsObj.rigOnlineRu.rigOnlineRu' | translate }}</mat-slide-toggle>
      </div>
      <div *ngIf="form.get('rigonlineru').value.enabled === true">
        <div class="form-control w-50">
          <label for="ROrigid">{{ 'rig.settingsObj.rigOnlineRu.rigid' | translate }}</label>
          <input id="ROrigid" formControlName="rigid" type="text">
        </div>
        <div class="form-control w-50">
          <label for="ROemail">{{ 'rig.settingsObj.rigOnlineRu.email' | translate }}</label>
          <input id="ROemail" formControlName="email" type="text">
        </div>
        <div class="form-control w-50">
          <label for="ROsecret">{{ 'rig.settingsObj.rigOnlineRu.secret' | translate }}</label>
          <input id="ROsecret" formControlName="secret" type="text">
        </div>
        <div>
          <i>
            {{ 'rig.settingsObj.rigOnlineRu.descr' | translate }}
          </i>
        </div>
      </div>
    </div>

  </div>

  <div class="flex-container form-buttons">
    <button [disabled]="!form.valid" type="submit" class="btn btn-success pinned-right mr-0">{{'save' | translate}}</button>
  </div>
</form>
